import React, { useEffect, useState } from "react";
import css from "./VendorPhoneVerified.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "../../../../assets/logo.png";
import { themeOrange } from "../../../../utils/colorTheme";
import Input from "../../../../component/common/Input";
import { common_caption_strings } from "../../../../utils/language_controls/constant_strings";
import ButtonComp from "../../../../component/common/ButtonComp";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { TbReload } from "react-icons/tb";
import flower from "../../../../assets/flowers.png";

const VendorPhoneVerified = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(true);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const {
    user_login_status,
    forgot_password_status,
    forgot_password_data,
    forgot_password_error,
  } = useSelector((state) => state.userAuth);

  const isLoading = forgot_password_status === asyncStatus.LOADING;

  const validateForm = () => {
    let formErrors = {};

    // Email Validation
    if (!data.code) {
      formErrors.code = "Code is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Returns true if no errors
  };

  const _handleInputChange = (e) => {
    const { id, value } = e.target;
    setData({ ...data, [id]: value });

    // Perform validation for the specific field
    let updatedErrors = { ...errors };

    switch (id) {
      case "code":
        updatedErrors.code = value ? "" : "Code is required";
        break;

      default:
        break;
    }

    setErrors(updatedErrors);
  };

  const _handleSubmit = () => {
    // if (validateForm()) {
    //   //   dispatch(forgot_password(data));
    //   localStorage.setItem("code", data.code);
    // }
  };

  useEffect(() => {
    // if (forgot_password_status === asyncStatus.SUCCEEDED) {
    //   navigate("/check-email");
    //   dispatch(setIdleStatusForgot());
    // }
  }, [forgot_password_status]);
  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Container maxWidth={"xl"}>
            <Stack mt={2}>
              <Stack
                justifyContent={"flex-start"}
                sx={{
                  width: "100%",
                  padding: 5,
                }}
                textAlign={"left"}
                color={"black"}
              >
                <Stack
                  spacing={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack spacing={1}>
                    <Stack className="auth_Heading">
                      <Typography variant="caption" className={css.first_txt}>
                        Phone Number is{" "}
                        <Typography variant="caption" className={css.lst_txt}>
                          Verified
                        </Typography>
                      </Typography>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <Typography variant="caption" className={css.description}>
                        Your phone number has been successfully verified. You can now
                        proceed with the next steps.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          my={3}
                        >
                          <img
                            src={flower}
                            style={{
                              objectFit: "contain",
                              height: "286px",
                              margin: "auto",
                            }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
                <Stack>
                  <ButtonComp
                    onClick={_handleSubmit}
                    label={
                      isLoading ? (
                        <CircularProgress size={22} sx={{ color: "white" }} />
                      ) : (
                        "Next"
                      )
                    }
                    style={{
                      borderRadius: "10px",
                      fontfamily: "Raleway !important",
                    }}
                  />
                </Stack>
                {/* <Stack mt={1} direction={"row"} justifyContent={"center"}>
                  <Typography color={"#9F9F9F"}>
                    {common_caption_strings.dont_have_account}
                  </Typography>
                  <Typography
                    onClick={() => navigate("/Signup")}
                    sx={{ color: themeOrange, mx: "2px", cursor: "pointer" }}
                  >
                    {common_caption_strings.Create_one}
                  </Typography>
                </Stack> */}
              </Stack>
            </Stack>
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack alignItems={"center"} className="bgImageSignup" width={1}>
            <Stack
              mt={10}
              width={"70%"}
              className="transparent-bg"
              p={4}
              gap={5}
            >
              <Stack>
                <img width={"80px"} src={Logo} />
              </Stack>
              <Stack>
                <Typography
                  textAlign={"start"}
                  className="subHeading"
                  color={"white"}
                >
                 Welcome Done Right Away
                </Typography>
                <Typography className="mainPara" color={"white"}>
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
              {/* <Stack mt={20}>
            <Typography className="mainPara" color={"white"}>
              Already have an Account?
            </Typography>
            <Typography className="mainPara" color={themeOrange}>
              Submit
            </Typography>
          </Stack> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default VendorPhoneVerified;
