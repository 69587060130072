import { AppBar, Container, IconButton, Stack } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { UserTopAppBar } from "./UserTopBar";
import {
  userDashboardRoutes,
  userFromUserSideRoutes,
} from "../../utils/sideRoute";
import { useSelector } from "react-redux";
import CustomAlert from "../common/CustomAlert/CustomAlert";

export const UserContentView = (props) => {
  const {
    login_status,
    login_error,
    userAuth,
    userData,
    check_auth_status,
    userRole,
    user,
  } = useSelector((state) => state.userAuth);
  const { active, onClickHanlde } = props;

  // const get_user = localStorage.getItem("user_role");
  // console.log("user role", userRole);
  // console.log("get_user", get_user);

  //  ========================= //
  const storedUserRoleString = localStorage.getItem("user_role");

  let storedUserRoleNumber;

  try {
    // Convert to number and check if it is a valid number
    storedUserRoleNumber = parseInt(storedUserRoleString, 10);

    if (isNaN(storedUserRoleNumber)) {
      throw new Error("Invalid number");
    }
  } catch (error) {
    console.error("Error parsing user role:", error);
  }

  // Compare
  const isRoleMatch = storedUserRoleNumber === 2;
  const isRoleMatchTwo = storedUserRoleNumber === 3;

  // console.log("isRoleMatch", isRoleMatch);
  //  ========================= //

  return (
    <Stack className="content-view" bgcolor={"#FAFAFA"} flex={1}>
      <Stack>
        <UserTopAppBar onClickHanlde={onClickHanlde} />
        {+user.user_role === 2 && (
          <CustomAlert
            status={+user.status === 0 ? "pending" : "approved"}
            path={"/dashboard/settings"}
          />
        )}
        <Container maxWidth={false}>
          <Stack sx={{ paddingTop: "30px" }}>
            <Routes>
              {isRoleMatch
                ? userDashboardRoutes?.map((e) => (
                    <Route key={e.linkTo} path={e.linkTo} element={e.element} />
                  ))
                : isRoleMatchTwo
                ? userFromUserSideRoutes.map((e) => (
                    <Route key={e.linkTo} path={e.linkTo} element={e.element} />
                  ))
                : ""}
            </Routes>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};
