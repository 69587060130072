import React, { useEffect, useState } from "react";
import { Container, Stack } from "@mui/material";
import { themeGray } from "../../utils/colorTheme";
import CustomTabs from "../../component/common/CustomTabs/CustomTabs";
import VendorProfileTab from "./VendorSettings/VendorProfileTab/VendorProfileTab";
import VendorAccountTab from "./VendorSettings/VendorAccountTab/VendorAccountTab";
import VendorPaymentsTab from "./VendorSettings/VendorPaymentsTab/VendorPaymentsTab";

const DashboardSettings = () => {
  const tabs = [
    { label: "My Profile", content: <VendorProfileTab /> },
    { label: "My Account", content: <VendorAccountTab /> },
    // { label: "Payments", content: <VendorPaymentsTab /> },
  ];
  return (
    <Stack bgcolor={themeGray}>
      <Container
        sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" } }}
      >
        <CustomTabs tabs={tabs} />
      </Container>
    </Stack>
  );
};

export default DashboardSettings;
