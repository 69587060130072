

import React, { useEffect, useState } from "react";
import "./SearchBar.css";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { searchServiceAsync } from "../../../services/services";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { themeOrange } from "../../../utils/colorTheme";
import { RxCross2 } from "react-icons/rx";

const DoneServiceSearchBar = ({ placeholder, buttonText, initialQuery,onSearch  }) => {
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term
  const [selectedService, setSelectedService] = useState(null); // State for selected service
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if (searchTerm.trim() !== "") {
      dispatch(searchServiceAsync({ search: searchTerm }));
    }
  }, [searchTerm, dispatch]);

  useEffect(() => {
    // Set initial query if available
    if (initialQuery) {
      setSearchTerm(initialQuery);
    }
  }, [initialQuery]);

  useEffect(() => {
    onSearch(searchTerm); // Update the search term in parent component
  }, [searchTerm, onSearch]);


  const _handleSearch = () => {
    setSearchTerm(""); // Clear the input field
    setSelectedService(null); // Reset the selected value
    onSearch(""); 
  };

  return (
    <div style={{width:"100%"}}>
      <div className="search-container" >
        <div className="right-content">
          <div>
            <FaSearch className="search-icon" />
          </div>
          <div style={{width:"100%"}}>
            <input
              className="input-text"
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setSelectedService(null); // Clear selected service when typing
              }}
              type="text"
              value={selectedService ? selectedService.name : searchTerm}
              placeholder={placeholder || "Select services"}
              style={{width:"90%"}}
            />
          </div>
        </div>
        {searchTerm.trim() && (
          <div style={{ height: "100%",padding:"5px 0px" }}>
            <button
              className="hero-btn"
              onClick={_handleSearch}
              style={{
                cursor: "pointer",
                backgroundColor: themeOrange,
                padding: "10px 30px",
              }}
            >
              <RxCross2 size={30} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DoneServiceSearchBar;
