import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandle } from "../config/apiHandle/apiHandle";
import { type_constant } from "../utils/asyncStatus";

export const subscriptionAsync = createAsyncThunk(
  type_constant.SUBSCRIPTION_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/subscribe`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR SUBSCRIPTION_ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const cancelSubscriptionAsync = createAsyncThunk(
  type_constant.CANCEL_SUBSCRIPTION_ASYNC,
  async () => {
    try {
      const response = await apiHandle.get(`/cancel-subscribe`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR CANCEL SUBSCRIPTION_ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const vendorPersonalDetailAsync = createAsyncThunk(
  type_constant.VENDOR_PERSONAL_DETAIL_ASYNC,
  async () => {
    try {
      const response = await apiHandle.post(`/connect-stripe-create`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR VENDOR_PERSONAL_DETAIL_ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const vendorPaymentSetupAsync = createAsyncThunk(
  type_constant.VENDOR_PAYMENT_SETUP_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(
        `/connect-stripe-create-bank`,
        post_data
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR VENDOR_PAYMENT_SETUP_ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);


export const getVendorStatusAsync = createAsyncThunk(
  type_constant.GET_VENDOR_STATUS_ASYNC,
  async () => {
    try {
      const response = await apiHandle.get(`/stripe-account-status`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR GET VENDOR STATUS", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);