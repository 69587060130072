import React, { useEffect } from "react";
import css from "./TermsAndCondition.module.css";
import Navbar from "../../component/navbar/Navbar";
import { Container, Divider, Stack, Typography } from "@mui/material";
import { themeOrange } from "../../utils/colorTheme";
import Footer from "../../screens/Footer/Footer";

const TermsAndCondition = () => {
  const termsConditionsData = [
    {
      title: "Use of the Site and Services",
      subData: [
        {
          subTitle: "Eligibility",
          subDescription: (
            <>
              You must be at least 18 years old or the age of majority in your
              jurisdiction to use our Site and Services. By accessing the Site
              or using the Services, you represent and warrant that you meet
              this eligibility requirement.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Account Registration",
          subDescription: (
            <>
              To use certain features of our Services, you may be required to
              register for an account. You agree to provide accurate and
              complete information and to update your account information as
              necessary. You are responsible for maintaining the confidentiality
              of your account login credentials and all activities under your
              account.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Prohibited Activities",
          subDescription: <>You agree not to:</>,
          subPoints: [
            "Use the Site or Services for any unlawful purpose or violate any applicable laws or regulations.",
            "Engage in any activity that could harm, disable, overburden, or impair the Site or Services.",
            "Interfere with the security of the Site or Services or any system resources, accounts, servers, or networks connected to or accessible through the Site or Services.",
            `Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.`,
            `Use any robot, spider, scraper, or other automated means to access the Site or Services without our express written permission.`,
          ],
        },
      ],
      description: ``,
    },
    {
      title: "Services and Transactions",
      subData: [
        {
          subTitle: "Service Descriptions",
          subDescription: `We strive to ensure that the information on the Site is complete and accurate. However, we do not warrant that service descriptions, pricing, or other content on the Site are accurate, complete, reliable, current, or error-free. In the event of any errors, we reserve the right to correct them without prior notice.`,
          subPoints: [],
        },

        {
          subTitle: "Orders and Payment",
          subDescription: `By placing an order for our Services, you agree to pay the specified fees. We may use third-party payment processors to facilitate transactions. You agree to provide accurate payment information and to update it as necessary. We reserve the right to refuse or cancel any orders at our discretion.`,
          subPoints: [],
        },
        {
          subTitle: "Refunds and Cancellations",
          subDescription: `Refund and cancellation policies for our Services are outlined on the Site. We reserve the right to modify these policies at any time. Any changes will be effective upon posting to the Site.`,
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Intellectual Property",
      subData: [
        {
          subTitle: "Ownership",
          subDescription: (
            <>
              All content, features, and functionality on the Site and Services,
              including but not limited to text, graphics, logos, images, and
              software, are the exclusive property of Done or its licensors and
              are protected by applicable intellectual property laws.
            </>
          ),
          subPoints: [],
        },

        {
          subTitle: "License",
          subDescription: (
            <>
              Subject to your compliance with these Terms, we grant you a
              limited, non-exclusive, non-transferable, and revocable license to
              access and use the Site and Services for personal, non-commercial
              use. You may not reproduce, distribute, modify, create derivative
              works of, publicly display, publicly perform, republish, download,
              store or transmit any of the material on our Site, except as
              permitted by these Terms.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Disclaimers and Limitation of Liability",
      subData: [
        {
          subTitle: "Disclaimers",
          subDescription: (
            <>
              The Site and Services are provided on an “as-is” and “as
              available” basis. We make no representations or warranties of any
              kind, express or implied, regarding the Site's operation or the
              information, content, materials, or products included. To the
              fullest extent permissible by applicable law, we disclaim all
              warranties, express or implied, including but not limited to
              implied warranties of merchantability and fitness for a particular
              purpose.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Limitation of Liability",
          subDescription: (
            <>
              To the fullest extent permitted by applicable law, Done and its
              affiliates, licensors, service providers, and employees shall not
              be liable for any indirect, incidental, special, consequential, or
              punitive damages, including but not limited to lost profits, loss
              of data, or loss of goodwill, arising out of or in connection with
              your use of or inability to use the Site or Services, even if we
              have been advised of the possibility of such damages.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Indemnification",
      subData: [],
      description: (
        <>
          You agree to indemnify, defend, and hold harmless Done and its
          affiliates, licensors, service providers, and employees from and
          against any claims, liabilities, damages, losses, costs, expenses, or
          fees (including reasonable attorneys’ fees) arising out of or relating
          to your use of the Site or Services, your violation of these Terms, or
          your violation of any rights of another.
        </>
      ),
    },
    {
      title: "Governing Law and Dispute Resolution",
      subData: [
        {
          subTitle: "Governing Law",
          subDescription: (
            <>
              These Terms and your use of the Site and Services shall be
              governed by and construed in accordance with the laws of the state
              or country where Done is headquartered, without regard to its
              conflict of law principles.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Dispute Resolution",
          subDescription: (
            <>
              Any disputes arising out of or relating to these Terms or your use
              of the Site or Services shall be resolved through binding
              arbitration, conducted in accordance with the rules of
              [arbitration association] in the state or country where Done is
              headquartered. You agree to waive any right to a jury trial or to
              participate in a class action.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Changes to These Terms",
      subData: [],
      description: (
        <>
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting to the Site. Your continued
          use of the Site and Services after any changes signifies your
          acceptance of the updated Terms.
        </>
      ),
    },
    {
      title: "Contact Us",
      subData: [],
      description: (
        <>
          If you have any questions or concerns about these Terms or our
          Services, please contact us at:
        </>
      ),
    },
  ];

  const contractBWVendorDone = [
    {
      title: "Scope of Work",
      subData: [
        {
          subTitle: "Services",
          subDescription: (
            <>
              Vendor agrees to provide the following services (
              <span style={{ fontWeight: "600" }}>“Services”</span>) to Done:
              [Insert detailed description of the services provided by the
              Vendor]
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Deliverables",
          subDescription: (
            <>
              Deliverables: Vendor shall deliver the following products or
              results (<span style={{ fontWeight: "600" }}>“Deliverables”</span>
              ): [Insert detailed description of the deliverables, if
              applicable]
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Compensation",
      subData: [
        {
          subTitle: "Payment Terms",
          subDescription: (
            <>Done agrees to pay Vendor for the Services as follows:</>
          ),
          subPoints: [
            `Fees: [Specify the amount, rate, or payment structure]`,
            `Payment Schedule: [Insert payment schedule, e.g., upon completion, monthly, milestones]`,
            `Expenses: Done will reimburse pre-approved expenses related to the Services upon receipt of valid documentation.`,
          ],
        },

        {
          subTitle: "Invoicing",
          subDescription: (
            <>
              Vendor shall submit invoices to Done at [insert frequency, e.g.,
              monthly] with a detailed breakdown of services rendered and any
              expenses incurred. Invoices must be submitted to [Insert email or
              address for invoice submission].
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Payment",
          subDescription: (
            <>
              Done shall pay Vendor within [insert number] days of receipt of a
              correct and approved invoice.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Term and Termination",
      subData: [
        {
          subTitle: "Term",
          subDescription: (
            <>
              This Agreement shall commence on the Effective Date and continue
              until [insert end date] or until terminated by either Party as
              provided herein.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Termination",
          subDescription: (
            <>
              Either Party may terminate this Agreement at any time, with or
              without cause, by providing [insert number] days' written notice
              to the other Party.
            </>
          ),
          subPoints: [],
        },

        {
          subTitle: "Effect of Termination",
          subDescription: (
            <>
              Upon termination, Vendor shall cease all Services and provide Done
              with any completed Deliverables. Done shall pay Vendor for all
              Services rendered and approved expenses incurred up to the
              termination date.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Confidentiality",
      subData: [
        {
          subTitle: "Confidential Information",
          subDescription: (
            <>
              “Confidential Information” means any non-public information
              disclosed by one Party (
              <span style={{ fontWeight: "600" }}>“Disclosing Party”</span>) to
              the other Party (
              <span style={{ fontWeight: "600" }}>“Receiving Party”</span>) that
              is designated as confidential or that should reasonably be
              understood to be confidential given the nature of the information
              and the circumstances of disclosure.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Obligations",
          subDescription: (
            <>
              The Receiving Party agrees to use the Confidential Information
              solely for this Agreement and to protect it from unauthorised use
              or disclosure with at least the same degree of care it uses to
              protect its confidential information, but in no event less than
              reasonable care.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Exclusions",
          subDescription: (
            <>
              Confidential Information does not include information that is: (a)
              publicly available; (b) already known to the Receiving Party; (c)
              independently developed by the Receiving Party without the use of
              or reference to the Disclosing Party’s Confidential Information;
              or (d) rightfully obtained from a third party.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Intellectual Property",
      subData: [
        {
          subTitle: "Ownership",
          subDescription: (
            <>
              All intellectual property rights in the Deliverables created by
              Vendor for Done under this Agreement shall belong to Done. The
              vendor now assigns all such rights to Done upon receipt of full
              payment.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "License",
          subDescription: (
            <>
              The vendor retains all intellectual property rights in any
              preexisting materials and tools used in creating the Deliverables
              but grants Done a perpetual, royalty-free, non-exclusive license
              to use such materials and tools as part of the Deliverables.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Warranties and Indemnities",
      subData: [
        {
          subTitle: "Warranties",
          subDescription: <>Vendor represents and warrants that:</>,
          subPoints: [
            `The Services will be performed in a professional and workmanlike manner under industry standards.`,
            `The Deliverables will not infringe upon any intellectual property rights or other rights of any third party.`,
          ],
        },
        {
          subTitle: "Indemnity",
          subDescription: (
            <>
              Vendor agrees to indemnify, defend, and hold harmless Done and its
              affiliates, officers, directors, employees, and agents from and
              against any claims, liabilities, damages, losses, costs, expenses,
              or fees (including reasonable attorneys’ fees) arising out of or
              relating to:
            </>
          ),
          subPoints: [
            `Vendor’s breach of any representations or warranties in this Agreement; `,
            `Vendor’s negligent or wrongful acts or omissions in the performance of the Services.`,
          ],
        },
      ],
      description: ``,
    },
    {
      title: "Limitation of Liability",
      subData: [],
      description: (
        <>
          To the fullest extent permitted by applicable law, Done's total
          liability to Vendor for any damages, losses, or causes of action
          arising out of or relating to this Agreement, whether in contract,
          tort, or otherwise, shall not exceed the total amount paid by Done to
          Vendor under this Agreement.
        </>
      ),
    },
    {
      title: "Independent Contractor",
      subData: [],
      description: (
        <>
          The Parties acknowledge and agree that the Vendor is an independent
          contractor, not an employee, agent, or partner of Done. The vendor has
          no authority to bind Done in any manner or enter into any agreements
          on behalf of Done.
        </>
      ),
    },
    {
      title: "Miscellaneous",
      subData: [
        {
          subTitle: "Governing Law",
          subDescription: (
            <>
              This Agreement shall be governed by and construed in accordance
              with the laws of [insert state or country], without regard to its
              conflict of law principles.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Entire Agreement",
          subDescription: (
            <>
              This Agreement constitutes the entire agreement between the
              Parties and supersedes all prior agreements or understandings
              relating to the subject matter, whether written or oral.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Amendments",
          subDescription: (
            <>
              Any modifications or amendments to this Agreement must be in
              writing and signed by both Parties.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Notices",
          subDescription: (
            <>
              All notices required or permitted under this Agreement shall be in
              writing and delivered to the respective addresses of the Parties
              set forth above by hand delivery, certified mail, or email with
              confirmed receipt.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Severability",
          subDescription: (
            <>
              If any provision of this Agreement is invalid or unenforceable,
              the remaining provisions shall continue in full force and effect.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Waiver",
          subDescription: (
            <>
              No waiver of any term or condition of this Agreement shall be
              deemed a waiver of any other term or condition. Any waiver must be
              in writing and signed by the Party making the waiver.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Assignment",
          subDescription: (
            <>
              Vendor may not assign this Agreement or any rights or obligations
              hereunder without Done’s written consent. Done may assign this
              Agreement to an affiliate or in connection with a merger,
              acquisition, or sale of all or substantially all of its assets.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
  ];

  const contractBWCustomerDone = [
    {
      title: "Scope of Services",
      subData: [
        {
          subTitle: "Services",
          subDescription: (
            <>
              Done agrees to provide the following services (“
              <span style={{ fontWeight: "600" }}>“Services”</span>) to the
              Customer: [Insert detailed description of the services to be
              provided by Done]
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Deliverables",
          subDescription: (
            <>
              Done shall deliver the following products or results (
              <span style={{ fontWeight: "600" }}>“Deliverables”</span>):
              [Insert detailed description of the deliverables, if applicable]
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Payment Terms",
      subData: [
        {
          subTitle: "Fees",
          subDescription: (
            <>Customer agrees to pay Done for the Services as follows:</>
          ),
          subPoints: [
            `Total Fees: [Specify the total amount or rate]`,
            `Payment Schedule: [Insert payment schedule, e.g., upon completion, upfront, instalments]`,
            `Additional Costs: Any additional costs, such as materials or travel expenses, must be pre-approved by the Customer and will be invoiced separately.F`,
          ],
        },

        {
          subTitle: "Invoicing",
          subDescription: (
            <>
              Done shall submit invoices to Customer at [insert frequency, e.g.,
              monthly, upon milestones] with a detailed breakdown of services
              rendered and any additional costs incurred. Invoices must be
              submitted to [Insert email or address for invoice submission].
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Payment",
          subDescription: (
            <>
              Customer shall pay each invoice within [insert number] days of
              receipt. Late payments may be subject to a late fee of [insert
              percentage]% per month or the maximum allowed by law, whichever is
              less.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Term and Termination",
      subData: [
        {
          subTitle: "Term",
          subDescription: (
            <>
              This Agreement shall commence on the Effective Date and continue
              until [insert end date] or until terminated by either Party as
              provided herein.
            </>
          ),
          subPoints: [],
        },

        {
          subTitle: "Termination",
          subDescription: (
            <>
              Either Party may terminate this Agreement at any time, with or
              without cause, by providing [insert number] days' written notice
              to the other Party.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Effect of Termination",
          subDescription: (
            <>
              Done shall cease all Services and deliver any completed
              Deliverables upon termination. The customer shall pay Done for all
              Services rendered and approved additional costs incurred up to the
              termination date.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Responsibilities",
      subData: [
        {
          subTitle: "Done's Responsibilities",
          subDescription: "",
          subPoints: [
            ` Under industry standards, perform the Services with due care, skill, and diligence.`,
            `Provide Deliverables by the agreed-upon specifications.`,
            `Maintain communication with the Customer and report on progress as required.`,
          ],
        },
        {
          subTitle: "Customer's Responsibilities",
          subDescription: ``,
          subPoints: [
            `Provide Done with access to necessary information, resources, and cooperation.`,
            `Review and provide feedback on Deliverables on time.`,
            `Pay all fees and additional costs as agreed in this Agreement.`,
          ],
        },
      ],
      description: ``,
    },
    {
      title: "Confidentiality",
      subData: [
        {
          subTitle: "Confidential Information",
          subDescription: (
            <>
              <span style={{ fontWeight: "600" }}>
                “Confidential Information”
              </span>{" "}
              means any non-public information disclosed by one Party (
              <span style={{ fontWeight: "600" }}>“Disclosing Party”</span> ) to
              the other Party (
              <span style={{ fontWeight: "600" }}>“Receiving Party”</span> )
              that is designated as confidential or that should reasonably be
              understood to be confidential given the nature of the information
              and the circumstances of disclosure.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Obligations",
          subDescription: (
            <>
              The Receiving Party agrees to use the Confidential Information
              solely for this Agreement and to protect it from unauthorised use
              or disclosure with at least the same degree of care it uses to
              protect its confidential information, but in no event less than
              reasonable care.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Exclusions",
          subDescription: (
            <>
              Confidential Information does not include information that is: (a)
              publicly available; (b) already known to the Receiving Party; (c)
              independently developed by the Receiving Party without the use of
              or reference to the Disclosing Party’s Confidential Information;
              or (d) rightfully obtained from a third party.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Intellectual Property",
      subData: [
        {
          subTitle: "Ownership",
          subDescription: (
            <>
              All intellectual property rights in the Deliverables created by
              Done for Customer under this Agreement shall belong to Customer
              upon receipt of full payment. Done as a result of this assigns all
              such rights to Customer.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "License",
          subDescription: (
            <>
              Done retains all intellectual property rights in any pre-existing
              materials and tools used in creating the Deliverables but grants
              Customer a perpetual, royalty-free, non-exclusive license to use
              such materials and tools as part of the Deliverables.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Warranties and Indemnities",
      subData: [
        {
          subTitle: "Warranties",
          subDescription: <>Done represents and warrants that:</>,
          subPoints: [
            `The Services will be performed in a professional and workmanlike manner following industry standards. `,
            `The Deliverables will not infringe upon any intellectual property rights or other rights of any third party.`,
          ],
        },
        {
          subTitle: "Indemnity",
          subDescription: (
            <>
              Each Party agrees to indemnify, defend, and hold harmless the
              other Party and its affiliates, officers, directors, employees,
              and agents from and against all claims, liabilities, damages,
              losses, costs, expenses, or fees (including reasonable attorneys’
              fees) arising out of or relating to:
            </>
          ),
          subPoints: [
            `A breach of any representations or warranties in this Agreement;  `,
            `Negligent or wrongful acts or omissions in the performance of the Services.`,
          ],
        },
      ],
      description: ``,
    },
    {
      title: "Limitation of Liability",
      subData: [],
      description: `To the fullest extent permitted by applicable law, neither Party shall be liable to the other Party for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits, loss of data, or loss of goodwill, arising out of or in connection with this Agreement, even if advised of the possibility of such damages. Each Party’s total liability for any damages, losses, or causes of action arising out of or relating to this Agreement, whether in contract, tort, or otherwise, shall not exceed the total amount paid or payable by Customer to Done under this Agreement.`,
    },
    {
      title: "Force Majeure",
      subData: [],
      description: `Neither Party shall be liable for any failure or delay in the performance of its obligations under this Agreement due to circumstances beyond its reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, labour disputes, or governmental actions, provided that the affected Party promptly notifies the other Party of such circumstances and resumes performance as soon as possible.`,
    },
    {
      title: "Miscellaneous",
      subData: [
        {
          subTitle: "Governing Law",
          subDescription: (
            <>
              This Agreement shall be governed by and construed in accordance
              with the laws of [insert state or country], without regard to its
              conflict of law principles.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Entire Agreement",
          subDescription: (
            <>
              This Agreement constitutes the entire agreement between the
              Parties and supersedes all prior agreements or understandings
              relating to the subject matter, whether written or oral.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Amendments",
          subDescription: (
            <>
              Any modifications or amendments to this Agreement must be in
              writing and signed by both Parties.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Notices",
          subDescription: (
            <>
              All notices required or permitted under this Agreement shall be in
              writing and delivered to the respective addresses of the Parties
              set forth above by hand delivery, certified mail, or email with
              confirmed receipt.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Severability",
          subDescription: (
            <>
              If any provision of this Agreement is invalid or unenforceable,
              the remaining provisions shall continue in full force and effect.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Waiver",
          subDescription: (
            <>
              No waiver of any term or condition of this Agreement shall be
              deemed a waiver of any other term or condition. Any waiver must be
              in writing and signed by the Party making the waiver.
            </>
          ),
          subPoints: [],
        },
        {
          subTitle: "Assignment",
          subDescription: (
            <>
              Neither Party may assign this Agreement or any rights or
              obligations hereunder without the prior written consent of the
              other Party, except that Done may assign this Agreement to an
              affiliate or in connection with a merger, acquisition, or sale of
              all or substantially all of its assets.
            </>
          ),
          subPoints: [],
        },
      ],
      description: ``,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _handleOpenClick = () => {
    window.open("https://donerightaway.com" || "", "_blank"); // Opens in a new tab
  };
  return (
    <div>
      <Navbar />
      <div className={css.img_wrapper}>
        <Stack
          sx={{ height: "100%", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography className={css.main_title}>
            Terms And Conditions
          </Typography>
        </Stack>
      </div>

      <Container
        sx={{
          py: 5,
        }}
        maxWidth="xl"
      >
        {/* cards */}
        {/* <<<<<<<< TERMS AND CONDITIONS >>>>>>> */}
        <Stack px={{ xl: 10, lg: 10, md: 10, sm: 10, xs: 3 }}>
          <Typography
            sx={{ color: "black", fontSize: "25px", fontWeight: "500" }}
          >
            Terms And Conditions
          </Typography>
          <Stack my={2}>
            <Typography variant="caption" className={css.light_txt}>
              Welcome to Done (
              <Typography variant="caption" className={css.bold_txt}>
                "we"
              </Typography>
              ,{" "}
              <Typography variant="caption" className={css.bold_txt}>
                "our"
              </Typography>
              , or{" "}
              <Typography variant="caption" className={css.bold_txt}>
                "us"
              </Typography>
              )! By accessing or using our website
              [www.done.com](http://www.done.com) (the
              <Typography variant="caption" className={css.bold_txt}>
                “Site”
              </Typography>
              ) and our services (the
              <Typography variant="caption" className={css.bold_txt}>
                “Services”
              </Typography>
              ), you agree to comply with and be bound by the following terms
              and conditions (the{" "}
              <Typography variant="caption" className={css.bold_txt}>
                “Terms”
              </Typography>
              ). Please read these Terms carefully. If you disagree with these
              Terms, you must not access or use the Site or Services.
            </Typography>
          </Stack>

          <Stack mx={4}>
            <ol className={css.order_lst}>
              {termsConditionsData.map(({ title, description, subData }, i) => {
                return (
                  <li key={i}>
                    {title && (
                      <Typography className={css.main_heading}>
                        {title}
                      </Typography>
                    )}
                    {description && (
                      <Typography className={css.light_txt}>
                        {description}
                      </Typography>
                    )}
                    {subData.length > 0 && (
                      <ol
                        className={`${css.order_lst} ${css.nested_order_lst}`}
                      >
                        {subData.map(
                          ({ subTitle, subDescription, subPoints }, i) => {
                            return (
                              <li key={i} className={css.sub_data_list}>
                                {subTitle && (
                                  <Typography className={css.sub_heading}>
                                    {subTitle}
                                  </Typography>
                                )}

                                {subDescription && (
                                  <Typography className={css.light_txt}>
                                    {subDescription}
                                  </Typography>
                                )}

                                {subPoints.length > 0 && (
                                  <ol className={css.custom_ol}>
                                    {subPoints.map((points, i) => {
                                      return (
                                        <li key={i}>
                                          {points && (
                                            <Typography
                                              variant="caption"
                                              className={css.light_txt}
                                            >
                                              {points}
                                            </Typography>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ol>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ol>
                    )}
                  </li>
                );
              })}
            </ol>
          </Stack>
        </Stack>

        <Stack px={{ xl: 14, lg: 14, md: 14, sm: 14, xs: 7 }} py={3}>
          <Typography className={css.light_txt}>Done</Typography>
          <Typography className={css.light_txt}>Canada</Typography>
          <Typography variant="caption" className={css.light_txt}>
            Email:{" "}
            <Typography
              onClick={_handleOpenClick}
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeOrange} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              info@donerightaway.com
            </Typography>
          </Typography>
          <Typography variant="caption" className={css.light_txt}>
            Phone:{" "}
            <Typography
              onClick={_handleOpenClick}
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeOrange} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              +12894004962
            </Typography>
          </Typography>
        </Stack>

        {/* <<<<<<<< CONTRACT BETWEEN A VENDOR AND DONE >>>>>>> */}
        <Stack px={{ xl: 10, lg: 10, md: 10, sm: 10, xs: 3 }}>
          <Typography
            sx={{ color: "black", fontSize: "25px", fontWeight: "500" }}
          >
            Contract Between A Vendor And Done
          </Typography>
          <Stack my={2}>
            <Typography variant="caption" className={css.light_txt}>
              This Vendor Agreement (
              <Typography variant="caption" className={css.bold_txt}>
                “Agreement”
              </Typography>
              ) is entered into on the effective date specified above (
              <Typography variant="caption" className={css.bold_txt}>
                “Effective Date”
              </Typography>
              ) by and between:
            </Typography>
          </Stack>

          <Stack mx={4}>
            <ol className={css.order_lst}>
              {contractBWVendorDone.map(
                ({ title, description, subData }, i) => {
                  return (
                    <li key={i}>
                      {title && (
                        <Typography className={css.main_heading}>
                          {title}
                        </Typography>
                      )}
                      {description && (
                        <Typography className={css.light_txt}>
                          {description}
                        </Typography>
                      )}
                      {subData.length > 0 && (
                        <ol
                          className={`${css.order_lst} ${css.nested_order_lst}`}
                        >
                          {subData.map(
                            ({ subTitle, subDescription, subPoints }, i) => {
                              return (
                                <li key={i} className={css.sub_data_list}>
                                  {subTitle && (
                                    <Typography className={css.sub_heading}>
                                      {subTitle}
                                    </Typography>
                                  )}

                                  {subDescription && (
                                    <Typography className={css.light_txt}>
                                      {subDescription}
                                    </Typography>
                                  )}

                                  {subPoints.length > 0 && (
                                    <ol className={css.custom_ol}>
                                      {subPoints.map((points, i) => {
                                        return (
                                          <li key={i}>
                                            {points && (
                                              <Typography
                                                variant="caption"
                                                className={css.light_txt}
                                              >
                                                {points}
                                              </Typography>
                                            )}
                                          </li>
                                        );
                                      })}
                                    </ol>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ol>
                      )}
                    </li>
                  );
                }
              )}
            </ol>
          </Stack>
        </Stack>

        <Stack px={{ xl: 14, lg: 14, md: 14, sm: 14, xs: 7 }} py={3}>
          <Typography className={css.light_txt}>Done</Typography>
          <Typography className={css.light_txt}>Canada</Typography>
          <Typography variant="caption" className={css.light_txt}>
            Email:{" "}
            <Typography
              onClick={_handleOpenClick}
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeOrange} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              info@donerightaway.com
            </Typography>
          </Typography>
          <Typography variant="caption" className={css.light_txt}>
            Phone:{" "}
            <Typography
              onClick={_handleOpenClick}
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeOrange} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              +12894004962
            </Typography>
          </Typography>
        </Stack>

        {/* <<<<<<<< CONTRACT BETWEEN A CUSTOMER AND DONE >>>>>>> */}
        <Stack px={{ xl: 10, lg: 10, md: 10, sm: 10, xs: 3 }}>
          <Typography
            sx={{ color: "black", fontSize: "25px", fontWeight: "500" }}
          >
            Contract Between A Customer And Done
          </Typography>
          <Stack my={2}>
            <Typography variant="caption" className={css.light_txt}>
              This Service Agreement (
              <Typography variant="caption" className={css.bold_txt}>
                “Agreement”
              </Typography>
              ) is made and entered into on the effective date specified above (
              <Typography variant="caption" className={css.bold_txt}>
                “Effective Date”
              </Typography>
              ) by and between:
            </Typography>
          </Stack>

          <Stack mx={4}>
            <ol className={css.order_lst}>
              {contractBWCustomerDone.map(
                ({ title, description, subData }, i) => {
                  return (
                    <li key={i}>
                      {title && (
                        <Typography className={css.main_heading}>
                          {title}
                        </Typography>
                      )}
                      {description && (
                        <Typography className={css.light_txt}>
                          {description}
                        </Typography>
                      )}
                      {subData.length > 0 && (
                        <ol
                          className={`${css.order_lst} ${css.nested_order_lst}`}
                        >
                          {subData.map(
                            ({ subTitle, subDescription, subPoints }, i) => {
                              return (
                                <li key={i} className={css.sub_data_list}>
                                  {subTitle && (
                                    <Typography className={css.sub_heading}>
                                      {subTitle}
                                    </Typography>
                                  )}

                                  {subDescription && (
                                    <Typography className={css.light_txt}>
                                      {subDescription}
                                    </Typography>
                                  )}

                                  {subPoints.length > 0 && (
                                    <ol className={css.custom_ol}>
                                      {subPoints.map((points, i) => {
                                        return (
                                          <li key={i}>
                                            {points && (
                                              <Typography
                                                variant="caption"
                                                className={css.light_txt}
                                              >
                                                {points}
                                              </Typography>
                                            )}
                                          </li>
                                        );
                                      })}
                                    </ol>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ol>
                      )}
                    </li>
                  );
                }
              )}
            </ol>
          </Stack>
        </Stack>

        <Stack px={{ xl: 14, lg: 14, md: 14, sm: 14, xs: 7 }} py={3}>
          <Typography className={css.light_txt}>Done</Typography>
          <Typography className={css.light_txt}>Canada</Typography>
          <Typography variant="caption" className={css.light_txt}>
            Email:{" "}
            <Typography
              onClick={_handleOpenClick}
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeOrange} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              info@donerightaway.com
            </Typography>
          </Typography>
          <Typography variant="caption" className={css.light_txt}>
            Phone:{" "}
            <Typography
              onClick={_handleOpenClick}
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeOrange} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              +12894004962
            </Typography>
          </Typography>
        </Stack>
      </Container>

      <Footer />
    </div>
  );
};

export default TermsAndCondition;
