import axios from "axios";
import { session_expired } from "../../store/constants";
import { save_tokens_constant } from "../../utils/asyncStatus";

export const exit_session = () => {
  localStorage.removeItem(save_tokens_constant);
  localStorage.setItem(session_expired, true);
  localStorage.removeItem("user_role");
  window.location.reload();
  localStorage.clear();
};

export const baseURL = `https://donerightaway.com/jiff-done-backend/public/api`;

const email = "example@example.com";
const apiKey = "c850b1e3-b857-4504-9865-e98e5961fa93";

export const apiHandle = axios.create({
  baseURL: `${baseURL}`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

axios.defaults.timeout = 15000;

apiHandle.interceptors.request.use(async (req) => {
  const authTokens = localStorage.getItem(save_tokens_constant.AUTH) ?? null;
  console.log("authTokens", authTokens);
  if (authTokens) {
    req.headers.Authorization = `Bearer ${authTokens}`;

    // const user = jwt_decode(authTokens?.access_token)

    // const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1

    // const refreshTokenDecode = jwt_decode(authTokens?.refresh_token)
    // const isRefreshExpired = dayjs.unix(refreshTokenDecode.exp).diff(dayjs()) < 1

    //   if (!isExpired) {
    //   }
    //   else if (isRefreshExpired && isExpired) {
    //     exit_session()
    //   }
    //   else if (!isRefreshExpired && isExpired) {
    //     try {
    //       const response = await axios.post(`${baseURL}/renew-token`, { refresh_token: authTokens?.refresh_token }, {
    //         headers: { Authorization: `Bearer ${authTokens?.access_token}` }
    //       })
    //       if (response.data.success) {
    //         const { access_token, refresh_token } = response.data.tokens
    //         req.headers.Authorization = `Bearer ${access_token}`
    //         localStorage.setItem(save_tokens_constant, JSON.stringify({ access_token: access_token, refresh_token: refresh_token }))
    //       }
    //     } catch (error) {
    //       if (error.response.data) {
    //         if (api_error_messages_to_exit.includes(error.response.data.message)) {
    //           exit_session()
    //         }
    //       }
    //     }
    //   }
  }

  return req;
});
