import React, { useEffect } from "react";
import { CustomTable } from "../../component/common/customTable/CustomTable";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { JobGetByVendorAsync } from "../../services/services";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeOrange } from "../../utils/colorTheme";
import css from "./VendorDashboard.module.css";

const JobDone = () => {
  const dispatch = useDispatch();

  const {
    get_job_by_vendor_status,
    get_job_by_vendor_data,
    get_job_by_vendor_error,
  } = useSelector((state) => state.serviceManage);

  const jobDoneLoader = get_job_by_vendor_status === asyncStatus.LOADING;

  useEffect(() => {
    dispatch(JobGetByVendorAsync());
  }, []);

  return (
    <Container maxWidth="xl">
      <Stack gap={3}>
        <Stack>
          <Typography variant="caption" className={css.total}>
            Job{" "}
            <Typography variant="caption" className={css.main_heading}>
              Done
            </Typography>
          </Typography>
          <hr color={"#D1D1D1"} />
        </Stack>
        {jobDoneLoader ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "60vh", width: "100%" }}
          >
            <CircularProgress size={30} sx={{ color: themeOrange }} />
          </Stack>
        ) : !get_job_by_vendor_data?.length &&
          get_job_by_vendor_data?.length === 0 ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "60vh", width: "100%" }}
          >
            <Typography>No Jobs Found</Typography>
          </Stack>
        ) : (
          <CustomTable />
        )}
      </Stack>
    </Container>
  );
};

export default JobDone;
