// import { createTheme, Stack, ThemeProvider } from "@mui/material";
// import TextField from "@mui/material/TextField";
// import { useState } from "react";

// function Input(props) {
//   const {
//     value,
//     placeholder,
//     type,
//     id,
//     onChange,
//     required,
//     disabled,
//     className,
//     width,
//     label,
//     style,
//     maxLength,
//     minLength,
//     defaultValue,
//     rest_stack_styles,
//     isError,
//     label_style,
//     input_label_style,
//     borderRadius,
//   } = props;

//   const [isFocused, setIsFocused] = useState(false);

//   const theme1 = createTheme({
//     palette: {
//       primary: {
//         main: "rgb(241, 90, 36)", // Color 1
//       },
//     },
//   });

//   return (
//     <Stack style={{ ...rest_stack_styles }}>
//       <ThemeProvider theme={theme1}>
//         <TextField
//           defaultValue={defaultValue}
//           type={type}
//           width={width}
//           id={id}
//           placeholder={placeholder}
//           className={className ? "inputStyle " + className : "inputStyle"}
//           value={value}
//           onChange={onChange}
//           required={required}
//           disabled={disabled}
//           maxLength={maxLength}
//           minLength={minLength}
//           sx={{
//             fontSize: "18px",
//             color: "gray",
//             // outline: "none",
//             "& .MuiOutlinedInput-root": {
//               borderRadius: borderRadius || "10px", // Apply borderRadius here
//             },
//             ...style,
//           }}
//           label={label}
//           InputLabelProps={{
//             shrink: type === "date" || isFocused || Boolean(value), // Keep the label above for date input
//             style: { ...label_style },
//             sx: (theme) => ({
//               "&.MuiInputLabel-shrink": { ...input_label_style },
//             }),
//           }}
//           error={isError}
//           onFocus={() => setIsFocused(true)} // Set focused to true on focus
//           onBlur={() => setIsFocused(false)} // Set focused to false on blur
//         />
//       </ThemeProvider>
//     </Stack>
//   );
// }

// export default Input;

import { createTheme, Stack, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";

function Input(props) {
  const {
    value,
    placeholder,
    type,
    id,
    onChange,
    required,
    disabled,
    className,
    width,
    label,
    style,
    maxLength,
    minLength,
    defaultValue,
    rest_stack_styles,
    isError,
    label_style,
    input_label_style,
    borderRadius,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const theme1 = createTheme({
    palette: {
      primary: {
        main: "rgb(241, 90, 36)", // Color 1
      },
    },
  });

  const shouldShrink =
    type === "date" ||
    type === "password" ||
    "text" ||
    "url" ||
    isFocused ||
    Boolean(!value);

  return (
    <Stack style={{ ...rest_stack_styles }}>
      <ThemeProvider theme={theme1}>
        <TextField
          defaultValue={defaultValue}
          type={type}
          width={width}
          id={id}
          placeholder={placeholder}
          className={className ? "inputStyle " + className : "inputStyle"}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          sx={{
            fontSize: "18px",
            color: "gray",
            "& .MuiOutlinedInput-root": {
              borderRadius: borderRadius || "10px", // Apply borderRadius here
            },
            ...style,
          }}
          label={label}
          InputLabelProps={{
            shrink: shouldShrink, // Keep the label above for date input, when focused, or when there is a value
            style: { ...label_style },
            sx: (theme) => ({
              "&.MuiInputLabel-shrink": { ...input_label_style },
            }),
          }}
          error={isError}
          onFocus={() => setIsFocused(true)} // Set focused to true on focus
          onBlur={() => setIsFocused(false)} // Set focused to false on blur
        />
      </ThemeProvider>
    </Stack>
  );
}

export default Input;
