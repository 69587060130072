/**
 * Parses a date string in various formats to a Date object.
 * @param {string} dateString - The date string to parse
 * @returns {Date} - The parsed Date object
 */

function parseDateString(dateString) {
  // Try to parse the date string directly
  const dateObj = new Date(dateString);

  // Check if the date is valid
  // if (!isNaN(dateObj)) {
  //   return dateObj;
  // }

  // Handle custom parsing if necessary (e.g., "Month DD, YYYY")
  const monthDayYearFormat = /^[A-Za-z]+\s\d{1,2},\s\d{4}$/;
  if (monthDayYearFormat.test(dateString)) {
    return new Date(dateString);
  }

  // Throw an error for invalid date formats
  throw new Error("Invalid date format");
}

/**
 * Formats a date string or Date object to 'ddd, MMM DD, YYYY'.
 * @param {Date|string} date - The date to format
 * @returns {string} - The formatted date string
 */

export function formatDate(date) {
  let dateObj = parseDateString(date);

  // if (typeof date === 'string') {
  //   dateObj = parseDateString(date);
  // } else {
  //   dateObj = new Date(date);
  // }

  // Check if the date is valid
  // if (isNaN(dateObj)) {
  //   throw new Error('Invalid date');
  // }

  // Options for formatting the date
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  return dateObj.toLocaleDateString("en-US", options);
}

function formatCustomDate(date) {
  const formateDate = new Date(date);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = days[formateDate.getDay()];
  const month = months[formateDate.getMonth()];
  const day = formateDate.getDate();
  const year = formateDate.getFullYear();

  // Determine morning or evening
  const hours = formateDate.getHours();
  const period = hours < 12 ? "Mor" : "Eve";

  return `${dayOfWeek}, ${month} ${day}, ${year} (${period})`;
}

export const inputDateFormate = (dateString) => {
  const date = new Date(dateString);

  // Get the day of the week (short format)
  const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

  // Get the month (short format)
  const month = date.toLocaleDateString("en-US", { month: "short" });

  // Get the day of the month
  const day = date.getDate();

  // Get the full year
  const year = date.getFullYear();

  // Define your custom part (e.g., "Mor")
  const customPart = "Mor";

  // Combine all parts into the desired format
  return `${month} ${day}, ${year} (${weekday})`;
};
