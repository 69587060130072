import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import css from "./VendorProfileVerification.module.css";
import VendorPersonalDetails from "../VendorPersonalDetails/VendorPersonalDetails";
import VendorSubmit from "../VendorSubmit/VendorSubmit";
import VendorVerified from "../VendorVerified/VendorVerified";
import VendorPaymentSetup from "../VendorPaymentSetup/VendorPaymentSetup";
import { useSelector } from "react-redux";

const VendorProfileVerification = () => {
  // Load the current step from localStorage, or default to step 1 if not found
  const [currentStep, setCurrentStep] = useState(
    Number(localStorage.getItem("current_step")) || 1
  );

  const {
    vendor_personal_detail_status,
    vendor_payment_setup_status,
    vendor_personal_detail_data,
  } = useSelector((state) => state.subscriptionSlice);

  // Save the current step to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("current_step", currentStep);
  }, [currentStep]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("current_step");
    };
  }, []);

  // Function to go to the next step
  const handleNextStep = () => {
    if (currentStep < 3) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  // Function to go back to the previous step
  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  // Function to start over (reset to step 1)
  const _handleStartOver = () => {
    setCurrentStep(1);
    localStorage.setItem("current_step", 1);
  };

  return (
    <Container maxWidth={"100%"} sx={{ backgroundColor: "#FAFAFA" }}>
      <Container maxWidth={"xl"} sx={{ backgroundColor: "#FAFAFA",py:5 }}>
        <Typography variant="caption" className={css.become_pro}>
          Become a{" "}
          <Typography
            variant="caption"
            className={`grediant_txt ${css.done_pro}`}
          >
            Done Pro
          </Typography>
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography
          variant="caption"
          className={`grediant_txt ${css.personal}`}
        >
          {currentStep === 1
            ? "Personal"
            : currentStep === 2
            ? "Payment"
            : "Submit"}{" "}
          <Typography variant="caption" className={css.details}>
            Details
          </Typography>
        </Typography>

        <Stack
          direction={"row"}
          alignItems={"center"}
          my={2}
          gap={1}
          sx={{ height: "fit-content" }}
        >
          <Typography variant="caption" className={css.steps}>
            {currentStep} of 3 steps
          </Typography>
          <Box className={css.vertical_line} />
          <Typography variant="caption" className={css.next_step}>
            {currentStep === 1 ? (
              "Next: Setup Payments"
            ) : currentStep === 2 ? (
              "Next: Submit Verification"
            ) : (
              <Typography
                onClick={_handleStartOver}
                variant="caption"
                className={`grediant_txt ${css.start_over}`}
              >
                Start Over
              </Typography>
            )}
          </Typography>
        </Stack>
        {/* <!------- STEPPER -------!> */}
        <Stack className={css.parent_stepper} mb={3}>
          <Stack
            className={css.child_stepper}
            sx={{ width: `${(currentStep / 3) * 100}%` }}
          />
        </Stack>

        {/* <!------- Stepper Pages -------!> */}
        {currentStep === 1 && (
          <VendorPersonalDetails nextTab={handleNextStep} />
        )}
        {currentStep === 2 && <VendorPaymentSetup nextTab={handleNextStep} />}
        {currentStep === 3 && <VendorSubmit nextTab={handleNextStep} />}

        {/* <Stack direction="row" justifyContent="space-between" mt={4}>
          {currentStep > 1 && (
            <Button onClick={handlePrevStep} variant="contained">
              Previous
            </Button>
          )}

          {currentStep < 3 && (
            <Button onClick={handleNextStep} variant="contained">
              Next
            </Button>
          )}
        </Stack> */}
      </Container>
    </Container>
  );
};

export default VendorProfileVerification;
