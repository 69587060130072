import MainDashboard from "../component/UserDashboard/MainDashboard";
import { UserDashboard } from "../component/UserDashboard/UserDashboard";
import AboutUs from "../pages/AboutUs/AboutUs";
import HelpCenter from "../pages/HelpCenter/HelpCenter";
import Home from "../pages/Home";
import MemberShipPage from "../pages/MemberShip/MemberShip";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import DoneServices from "../pages/Services/DoneServices";
import TermsAndCondition from "../pages/TermsAndCondition/TermsAndCondition";
import CheckYourEmail from "../pages/auth/CheckYourEmail";
import CreatePassword from "../pages/auth/CreatePassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import UserSignUp from "../pages/auth/UserSignup";
import VendorCheckEmail from "../pages/auth/VendorVerification/VendorCheckEmail/VendorCheckEmail";
import VendorCheckPhone from "../pages/auth/VendorVerification/VendorCheckPhone/VendorCheckPhone";
import VendorEmailVerified from "../pages/auth/VendorVerification/VendorEmailVerified/VendorEmailVerified";
import VendorPhoneVerified from "../pages/auth/VendorVerification/VendorPhoneVerified/VendorPhoneVerified";
import VerifyEmail from "../pages/auth/VendorVerification/VerifyEmail/VerifyEmail";
import VerifyPhone from "../pages/auth/VendorVerification/VerifyPhone/VerifyPhone";
import ComingSoonApp from "../screens/ComingSoonApp/ComingSoonApp";
import ServiceDetailsPage from "../screens/Dashboard/ServiceDetailPage";
import VendorProfileVerification from "../screens/Dashboard/VendorProfileComplete/VendorProfileVerification/VendorProfileVerification";
import VendorVerified from "../screens/Dashboard/VendorProfileComplete/VendorVerified/VendorVerified";

export const mainRoutes = [
  {
    caption: "Home",
    linkTo: "/",
    icon: "",
    element: <Home />,
    authRequired: false,
    both: true,
  },
  // {
  //   caption: 'MainDashboard',
  //   linkTo: '/dashboard-home',
  //   element: <MainDashboard />,
  //   authRequired: true,
  //   // both:false

  // },
  {
    caption: "UserDashboard",
    linkTo: "/dashboard/*",
    element: <UserDashboard />,
    authRequired: true,
    // both:false
  },
  {
    caption: "Login",
    linkTo: "/login",
    icon: "",
    element: <Login />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Signup",
    linkTo: "/signup",
    icon: "",
    element: <SignUp />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Signup",
    linkTo: "/signup-user",
    icon: "",
    element: <UserSignUp />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Forgot Password",
    linkTo: "/forgot-password",
    icon: "",
    element: <ForgotPassword />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Check Email",
    linkTo: "/check-email",
    icon: "",
    element: <CheckYourEmail />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Create password",
    linkTo: "/create-password",
    icon: "",
    element: <CreatePassword />,
    authRequired: false,
    // both:true
  },

  {
    caption: "Verify Email",
    linkTo: "/verify-email",
    icon: "",
    element: <VerifyEmail />,
    authRequired: true,
    // both:true
  },

  {
    caption: "Vendor Profile Verification",
    linkTo: "/vendor-profile-verification",
    icon: "",
    element: <VendorProfileVerification />,
    authRequired: true,
  },
  {
    caption: "Vendor Verified",
    linkTo: "/vendor-verified",
    icon: "",
    element: <VendorVerified />,
    authRequired: true,
  },

  {
    caption: "Vendor Check Email",
    linkTo: "/vendor-check-email",
    icon: "",
    element: <VendorCheckEmail />,
    authRequired: true,
    otp_verify: true,
    // both:true
  },
  {
    caption: "Vendor Email Verified",
    linkTo: "/vendor-email-verified",
    icon: "",
    element: <VendorEmailVerified />,
    authRequired: true,
    after_otp_verify: true,
    // both:true
  },
  {
    caption: "Vendor Phone Verified",
    linkTo: "/vendor-phone-verified",
    icon: "",
    element: <VendorPhoneVerified />,
    authRequired: true,
    // both:true
  },
  {
    caption: "Verify Phone",
    linkTo: "/verify-phone",
    icon: "",
    element: <VerifyPhone />,
    authRequired: true,
    // both:true
  },
  {
    caption: "Vendor Check Phone",
    linkTo: "/vendor-check-phone",
    icon: "",
    element: <VendorCheckPhone />,
    authRequired: true,
    // both:true
  },
];

export const topBarRoutes = [
  {
    caption: "Home",
    linkTo: "/",
    icon: "",
    element: <Home />,
    authRequired: false,
    isInTopBar: true,
  },
  {
    caption: "Services",
    linkTo: "/services",
    icon: "",
    element: <DoneServices />,
    authRequired: false,
    isInTopBar: true,
  },
  {
    caption: "About Us",
    linkTo: "/about_us",
    icon: "",
    element: <AboutUs />,
    authRequired: false,
    isInTopBar: true,
  },
  // {
  //   caption: "Contact Us",
  //   linkTo: "/contact_us",
  //   icon: "",
  //   element: <ContactUs />,
  //   authRequired: false,
  //   isInTopBar: true,
  // },
  // {
  //   caption: "Support",
  //   linkTo: "/support",
  //   icon: "",
  //   element: <Support />,
  //   authRequired: false,
  //   isInTopBar: true,
  // },
  {
    caption: "Help Center",
    linkTo: "/help-center",
    icon: "",
    element: <HelpCenter />,
    authRequired: false,
    isInTopBar: true,
  },
  {
    caption: "Member Ship",
    linkTo: "/member_ship",
    icon: "",
    element: <MemberShipPage />,
    authRequired: false,
    isInTopBar: false,
  },

  {
    caption: "ComingSoonApp",
    linkTo: "/coming-soon-app",
    icon: "",
    element: <ComingSoonApp />,
    authRequired: false,
    isInTopBar: false,
  },
  {
    caption: "Privacy Policy",
    linkTo: "/privacy-policy",
    icon: "",
    element: <PrivacyPolicy />,
    // authRequired: true,
    isInTopBar: false,
  },
  {
    caption: "Terms and Conditions",
    linkTo: "/terms-and-conditions",
    icon: "",
    element: <TermsAndCondition />,
    authRequired: false,
    isInTopBar: false,
  },
];
