import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { themeOrange, themeText } from "../../utils/colorTheme";
import lapTopFrame from "../../assets/frame.png";
import sampleVideo from "../../assets/sample-video.mp4"; // Make sure to import your video file
import { useNavigate } from "react-router-dom";

const FiftSection = () => {
  const navigate = useNavigate();
  return (
    <Stack sx={{ padding: "24px 0px", backgroundColor: "#F5F5F5" }}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            py={{ xl: 10, lg: 10, md: 10, sm: 5, xs: 5 }}
          >
            <Stack>
              <Typography
                mt={2}
                color={themeText}
                sx={{
                  fontSize: {
                    xs: "25px",
                    sm: "30px",
                    md: "30px",
                    lg: "36px",
                    xl: "36px",
                  },
                  lineHeight: {
                    xl: "normal",
                    lg: "normal",
                    md: "40px",
                    sm: "40px",
                    xs: "40px",
                  },
                }}
                fontWeight={"700"}
              >
                Boost your productivity <br /> with our{" "}
                <span style={{ color: "#F15A24" }}>to-do app</span>
              </Typography>
              <Typography color={"#999"} mt={2}>
                By creating a visual guide along the way, the designer <br /> or
                developer can get input from the other people <br /> involved in
                the website such as the customer, their <br /> manager, and
                other members of the team.
              </Typography>
              <Typography mt={2} color={"#999"}>
                The visual guide will provide a view to the customer of <br />{" "}
                what their website or project will end up looking like.
              </Typography>
              <Box onClick={() => navigate("/coming-soon-app")}>
                <Typography
                  color={themeOrange}
                  mt={5}
                  sx={{ cursor: "pointer" }}
                >
                  Learn more
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12} position="relative">
            <img
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "contain" }}
              src={
                "https://png.pngtree.com/png-vector/20220913/ourmid/pngtree-realistic-laptop-screen-frame-png-image_6173481.png"
              }
              alt="Laptop Frame"
            />
            <video
              style={{
                position: "absolute",
                top: "-7%",
                right: "13.5%",
                width: "75%",
                height: "100%",
              }}
              autoPlay
              loop
              muted
            >
              <source src={sampleVideo} type="video/mp4" />
            </video>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
};

export default FiftSection;
