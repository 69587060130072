import React from "react";
import not_found_page from "../../assets/not_found_page.jpg";
import { Stack } from "@mui/material";

const NotFoundPage = () => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"}>
      <img
        src={not_found_page}
        style={{ height: "45vh", objectFit: "contain", margin: "90px" }}
      />
    </Stack>
  );
};

export default NotFoundPage;
