import React, { useState } from "react";
import css from "./VendorSubmit.module.css";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import submit_verification from "../../../../assets/submit_verification.png";
import { themeOrange } from "../../../../utils/colorTheme";
import check_icon from "../../../../assets/check_icon.png";
import ButtonComp from "../../../../component/common/ButtonComp";
import { useNavigate } from "react-router-dom";

const VendorSubmit = ({ nextTab }) => {
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const navigate = useNavigate();

  const _handleSubmit = () => {
    setIsLoading(true); // Start loading
    setTimeout(() => {
      setIsLoading(false); // Stop loading
      navigate("/vendor-verified"); // Navigate after timeout
    }, 2000); // 2 second delay
  };
  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={5}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Stack gap={5}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={3}
            >
              <Typography variant="caption" className={css.label}>
                Personal Details
              </Typography>
              <Box
                sx={{ borderBottom: `2px dashed ${themeOrange}`, flex: 1 }}
              />
              <img
                src={check_icon}
                style={{ objectFit: "contain", width: "25px", height: "25px" }}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={3}
            >
              <Typography variant="caption" className={css.label}>
                Setup Payments
              </Typography>
              <Box
                sx={{ borderBottom: `2px dashed ${themeOrange}`, flex: 1 }}
              />
              <img
                src={check_icon}
                style={{ objectFit: "contain", width: "25px", height: "25px" }}
              />
            </Stack>

            <ButtonComp
              onClick={_handleSubmit}
              label={
                isLoading ? (
                  <CircularProgress size={22} sx={{ color: "white" }} />
                ) : (
                  <>Submit your listing</>
                )
              }
              style={{
                borderRadius: "10px",
                fontfamily: "Raleway !important",
                background:
                  "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                width: "100%",
              }}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <img
            src={submit_verification}
            style={{ objectFit: "contain", width: "100%", height: "50vh" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default VendorSubmit;
