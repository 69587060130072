export const type_constant = {
  PROVIDER: "provider",
  APPLICANT: "applicant",
};

// export const url = ''

export const api_error_messages_to_exit = [
  "Token is not valid",
  "Refresh or Access Token is not valid",
  "Token is Expired",
  "Invalid token",
];

export const role_constant = {
  ADMIN: 1,
  VENDOR: 2,
  USER: 3,
};

export const uiControllers = {
  STARTED: "STARTED",
  NEXT_STEP: "NEXT_STEP",
  BACK_STEP: "BACK_STEP",
};

export const session_expired = "@session_expired";

export const save_tokens_constant = "@usertokens";

export const service_booking_keys = {
  LOCATION: "location",
  SERVICE: "service",
  DATE: "date",
  DESCRIPTION: "description",
  IMAGES: "images",
  TIME: "time",
  STRIPETOKEN: "stripeToken",
  PRICE: "price",
};

export const SERVICE_DETAIL_PAGE_CONSTANT = {
  from: "@from_service_detail",
  service_id: "@service_id",
};

export const steps_constants = {
  JOBS_STEP: "JOBS_STEPS",
};

export const offer_status = {
  EXPIRED: "Expired",
  // OFFER:"offer",
};

export const signup_methods_constants = {
  EMAIL_PASSWORD: "email_password",
  GOOGLE: "google",
  FACEBOOK: "facebook",
  APPLE: "apple",
};

export const EMAIL_CONSTANTS = "user_email";
