import Slider from "react-slick";
import React, { useRef } from "react";
import SliderCard from "../Card/SecondCard/SliderCard";
import "./index.css";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

const SliderData = () => {
  const sliderRef = useRef(null);
  const { get_all_services_data } = useSelector((state) => state.serviceManage);

  const reviews = get_all_services_data?.reviews || [];

  const settings = {
    dots: true,
    infinite: reviews.length > 3, // Disable infinite scroll if reviews are 3 or fewer
    speed: 500,
    slidesToShow: Math.min(reviews.length, 3), // Show slides based on the number of reviews
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(reviews.length, 3),
          slidesToScroll: 1,
          infinite: reviews.length > 3, // Keep the settings consistent
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(reviews.length, 2),
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: reviews.length > 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: reviews.length > 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <Stack mb={20}>
      {reviews.length > 0 && (
        <Slider ref={sliderRef} {...settings}>
          {reviews.map((review, index) => (
            <div key={index}>
              <SliderCard review={review} />
            </div>
          ))}
        </Slider>
      )}
    </Stack>
  );
};

export default SliderData;
