import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import logo_2 from "../../assets/logo_2.png";
import dollar from "../../assets/dollar.png";
import jack from "../../assets/jack.png";
import calender_board from "../../assets/calender_board.png";
import useWindowDimensions from "../../utils/hooks/windowDimensions";
import { useNavigate } from "react-router-dom";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { imgUrl } from "../../utils/imageUrl";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus } from "../../utils/asyncStatus";
import { get_services } from "../../services/services";
import { VscCalendar } from "react-icons/vsc";
import { themeOrange } from "../../utils/colorTheme";
import { FiTool } from "react-icons/fi";
import tools from "../../assets/tools.png";
import dollar_hand from "../../assets/dollar_hand.png";
import calendar from "../../assets/calendar.png";
import CustomModal from "../../component/common/CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import {
  cancelSubscriptionAsync,
  subscriptionAsync,
} from "../../services/SubscriptionService";
import {
  setCancelSubscriptionSatus,
  setSubscriptionSatus,
} from "../../store/slices/subscription_slice";

const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const swiperRef = useRef(null);

  const { user } = useSelector((state) => state.userAuth);
  const { get_all_services_status, get_all_services_data } = useSelector(
    (state) => state.serviceManage
  );
  const { cancel_subscription_status } = useSelector(
    (state) => state.subscriptionSlice
  );

  const cancelSubscribeLoader =
    cancel_subscription_status === asyncStatus.LOADING;

  const getServiceLoader = get_all_services_status === asyncStatus.LOADING;

  const _goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const _goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    dispatch(get_services());
  }, []);

  const _handleClick = (item) => {
    navigate(`/ServiceDetails/${item}`);
  };

  const _handleCancel = () => {
    setIsOpenModal(!isOpenModal);
  };
  const _handleNavigateMemberShip = () => {
    navigate("/member_ship");
  };

  const _handleCancelSubscribe = () => {
    dispatch(cancelSubscriptionAsync());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (cancel_subscription_status === asyncStatus.SUCCEEDED) {
      dispatch(setCancelSubscriptionSatus());
      setIsOpenModal(false);
      window.location.reload();
    }
  }, [cancel_subscription_status]);

  const memberShipCardData = [
    {
      icon: dollar_hand,
      title: "Save $25 on Every Completed Job",
      description: `Enjoy exclusive savings as a member! Get $25 off every job we complete for you, whether it's a small repair or a full remodel. Start saving on each project and make your home improvements more affordable.`,
    },
    {
      icon: tools,
      title: "Extended Warranty from 30 to 60 Days",
      description: `As a member, enjoy peace of mind with an extended warranty on all work performed. We double our standard warranty from 30 to 60 days, ensuring that any issues are covered and giving you confidence in the quality of our services.`,
    },
    {
      icon: calendar,
      title: "Cancel Membership Anytime (No Fees or Penalties)",
      description: `Our membership is flexible and hassle-free. If you ever need to cancel, you can do so at any time with no cancellation fees or penalties. We’re committed to providing value on your terms.`,
    },
  ];

  return (
    <Container maxWidth={"100%"} sx={{ backgroundColor: "#FAFAFA" }}>
      <Stack>
        <Typography className="mainHeading">Member Ship</Typography>
        <hr color={"#D1D1D1"} />
      </Stack>

      {/* MEMBERSHIP -----!> */}
      <Stack my={5} gap={3}>
        <Stack
          alignItems={"center"}
          sx={{
            width: "100%",
            borderRadius: "10px",
            overflow: "hidden",
          }}
          className="globleGradientBlue"
        >
          {/*   MEMBERSHIP DETAILS -----!> */}

          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} py={2}>
              <Stack
                alignItems={"center"}
                justifyContent={"space-around"}
                gap={4}
                px={1}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  flexWrap={"nowrap"}
                  p={2}
                >
                  <img
                    src={logo_2}
                    style={{
                      height:
                        width < 600 ? "40px" : width < 1120 ? "40px" : "62px",
                      width:
                        width < 600 ? "80px" : width < 1120 ? "80px" : "145px",
                      objectFit: "contain",
                    }}
                    alt="Logo"
                  />
                  <Typography
                    className="user_dashboard_member_txt"
                    sx={{
                      fontSize: {
                        xl: "45px !important",
                        lg: "35px !important",
                        md: "30px !important",
                        sm: "30px !important",
                        xs: "25px !important",
                      },
                      flexShrink: 0,
                    }}
                  >
                    Member
                  </Typography>
                </Stack>

                <Stack
                  className="user_dashboard_discount_border"
                  sx={{
                    width: {
                      xl: "80% !important",
                      lg: "100% !important",
                      md: "100% !important",
                      sm: "65% !important",
                      xs: "100% !important",
                    },
                  }}
                  px={2}
                  py={1}
                  color={"white"}
                >
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={{
                      xl: "space-between",
                      lg: "space-between",
                      md: "space-between",
                      sm: "space-between",
                      xs: "center",
                    }}
                    sx={{
                      width: "100%",
                      gap: 1,
                    }}
                    flexWrap={"wrap"}
                  >
                    <Stack alignItems={"center"}>
                      <Typography
                        className="user_dashboard_save_25"
                        sx={{
                          fontSize: {
                            xl: "20px !important",
                            lg: "20px !important",
                            md: "17px !important",
                            sm: "14px !important",
                            xs: "14px !important",
                          },
                        }}
                      >
                        Save $25
                      </Typography>
                      <Typography
                        className="user_dashboard_on_every_done_txt"
                        sx={{
                          fontSize: {
                            xl: "15px !important",
                            lg: "15px !important",
                            md: "12px !important",
                            sm: "12px !important",
                            xs: "12px !important",
                          },
                        }}
                      >
                        on every Done job
                      </Typography>
                    </Stack>
                    <Typography className="user_dashboard_for_10_month">
                      just for $10/month
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Stack
                justifyContent={"center"}
                gap={2}
                px={2}
                sx={{ height: "100%" }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  sx={{ width: "100%" }}
                >
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "5px",
                      backgroundColor: "#F15A24",
                      flexShrink: "0",
                    }}
                  >
                    {" "}
                    <img
                      src={dollar}
                      style={{ height: "25px", width: "25px" }}
                    />{" "}
                  </Stack>
                  <Stack alignItems={"center"}>
                    <Typography className="mainPara" sx={{ color: "white" }}>
                      Save $25 on every Done Job
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  sx={{ width: "100%" }}
                >
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "5px",
                      backgroundColor: "#F15A24",
                      flexShrink: "0",
                    }}
                  >
                    {" "}
                    <img
                      src={jack}
                      style={{ height: "25px", width: "25px" }}
                    />{" "}
                  </Stack>
                  <Stack alignItems={"center"}>
                    <Typography className="mainPara" sx={{ color: "white" }}>
                      Warranty Extension from 30 to 60 days
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  sx={{ width: "100%" }}
                >
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "5px",
                      backgroundColor: "#F15A24",
                      flexShrink: "0",
                    }}
                  >
                    {" "}
                    <img
                      src={calender_board}
                      style={{ height: "25px", width: "25px" }}
                    />{" "}
                  </Stack>
                  <Stack alignItems={"center"}>
                    <Typography className="mainPara" sx={{ color: "white" }}>
                      Cancel membership anytime (no fee or panalty)
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Stack
                sx={{ height: "100%" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <ButtonComp
                  onClick={() =>
                    user?.subscription_id
                      ? _handleCancel()
                      : _handleNavigateMemberShip()
                  }
                  label={
                    user?.subscription_id
                      ? "Cancel your subscription"
                      : "Subscribe Now"
                  }
                  style={{
                    borderRadius: "10px",
                    fontfamily: "Raleway !important",
                    background:
                      "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                    width: "80%",
                  }}
                />

                {/* >>>>>>>>> CANCEL SUBSCRIPTION MODAL */}
                <CustomModal
                  isOpen={isOpenModal}
                  setIsOpen={() => setIsOpenModal(!isOpenModal)}
                >
                  <Stack
                    sx={{
                      overflow: "auto",
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                    alignItems={"center"}
                    p={1}
                  >
                    <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
                      <IconButton onClick={() => setIsOpenModal(!isOpenModal)}>
                        <RxCross2 size={20} sx={{ color: "black" }} />
                      </IconButton>
                    </Stack>

                    <Stack gap={2} px={1} sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        Are you sure you want to cancel this subscription?
                      </Typography>

                      <Stack
                        gap={2}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                      >
                        <ButtonComp
                          onClick={_handleCancelSubscribe}
                          label={
                            cancelSubscribeLoader ? (
                              <CircularProgress
                                size={22}
                                sx={{ color: "white" }}
                              />
                            ) : (
                              "Yes"
                            )
                          }
                          style={{
                            width: "auto",
                            height: "auto",
                            borderRadius: "15px",
                          }}
                        />
                        <ButtonComp
                          onClick={() => setIsOpenModal(!isOpenModal)}
                          label={"No"}
                          style={{
                            width: "auto",
                            height: "auto",
                            borderRadius: "15px",
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </CustomModal>
              </Stack>
            </Grid>
          </Grid>

          {/*   TERMS AND CONDITIONS -----!> */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            flexWrap={"wrap"}
            sx={{ width: "100%" }}
            px={1}
            py={2}
          >
            <Typography
              variant="caption"
              className="subpara"
              sx={{
                color: "white",
                fontSize: {
                  xl: "17px !important",
                  lg: "17px !important",
                  md: "15px !important",
                  sm: "15px !important",
                  xs: "13px !important",
                },
                fontWeight: "400 !important",
              }}
            >
              The $25 discount applies once per job. Done Member continue for
              $10/Month untill cancelled. See &nbsp;
              <a
                style={{
                  color: "white",
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: "17px !important",
                  fontWeight: "400 !important",
                }}
                onClick={() => navigate("/support")}
              >
                Done Terms & Conditions.
              </a>
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* why membership -----!> */}
      <Stack my={2}>
        <Typography className="mainHeading">Why Membership?</Typography>
      </Stack>

      <Grid container alignItems={"stretch"} spacing={3} mb={5}>
        {memberShipCardData.map(({ title, icon, description }) => {
          return (
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Stack
                justifyContent={"space-between"}
                className="member_ship_card_wrapper"
              >
                <img
                  src={icon}
                  className="member_ship_card_icon"
                  alt="member_ship_icon"
                />
                <Stack alignItems={"stretch"} gap={3} flex={1}>
                  <Typography className="member_ship_card_title">
                    {title}
                  </Typography>
                  <Typography className="member_ship_card_description">
                    {description}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>

      {/* POPULAR SERVICES -----!> */}
      <Stack my={5}>
        <Stack
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Typography className="mainHeading">All Services</Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
          >
            {/* --------!> BACKWARD BUTTON */}
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                height: "30px",
                width: "30px",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0px 0px 2px 0px grey",
                cursor: "pointer",
              }}
              onClick={_goToPrevSlide}
            >
              <MdOutlineKeyboardArrowLeft
                style={{ color: "black", fontSize: "25px" }}
              />
            </Stack>
            {/* --------!> FORWARD BUTTON */}
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                height: "30px",
                width: "30px",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0px 0px 2px 0px grey",
                cursor: "pointer",
              }}
              onClick={_goToNextSlide}
            >
              <MdOutlineKeyboardArrowRight
                style={{ color: "black", fontSize: "25px" }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "white",
            boxShadow: "0px 0px 2px 0px grey",
            px: 1,
            py: 1,
            overflow: "auto",
            mt: 1,
          }}
          gap={1}
          className="slider_wrapper"
        >
          {/* CAROUSEL SLIDER -----!> */}
          <Swiper
            ref={swiperRef}
            slidesPerView={
              width >= 1100
                ? 5
                : width < 1100 && width > 900
                ? 3.8
                : width < 900 && width > 530
                ? 3
                : width < 530 && width > 460
                ? 2.5
                : width < 460 && width > 350
                ? 1.8
                : width < 350
                ? 1
                : 5
            }
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            className="mySwiper"
          >
            {get_all_services_data?.services?.map(
              ({ image, name, id, mobile_image }) => {
                return (
                  <SwiperSlide key={id}>
                    <Stack
                      onClick={() => _handleClick(id)}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      sx={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "10px",
                        height: "130px",
                        width: "150px",
                        resize: "none",
                        flexShrink: "0",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      gap={1.5}
                      className="slider_item"
                    >
                      <Stack
                        sx={{
                          height: "70px",
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={`${imgUrl}${mobile_image || ""}`}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Stack>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "black",
                        }}
                      >
                        {name || ""}
                      </Typography>
                    </Stack>
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Subscription;
