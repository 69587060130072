import React, { useEffect, useState } from "react";
import DashboardNavbar from "../../component/DashboardNavbar/DashboardNavbar";
import css from "./About.module.css";
import Footer from "../../screens/Footer/Footer";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Navbar from "../../component/navbar/Navbar";
import { themeOrange } from "../../utils/colorTheme";
import circular_lines from "../../assets/circle_lines.png";
import service_employee from "../../assets/service_employee.png";
import qualified_worker from "../../assets/qualified_wrokers.png";
import service_circle from "../../assets/service_circle.png";
import laptop_mobile from "../../assets/laptop_mobile.png";
import count_service_img from "../../assets/count_service_img.png";
import app_store from "../../assets/app_store.png";
import play_store from "../../assets/play_store.png";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComp from "../../component/common/ButtonComp";
import CustomModal from "../../component/common/CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import CustomQRCode from "../../component/common/CustomQRCode/CustomQRCode";

const AboutUs = () => {
  const location = useLocation();
  const [isOpenQRModal, setIsOpenQRModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const _handleOpenQRCodeModal = () => {
    setIsOpenQRModal(!isOpenQRModal);
  };

  useEffect(() => {
    const _handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", _handleResize);
    return () => window.addEventListener("resize", _handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <div className={css.img_wrapper}>
        <Stack
          sx={{ backgroundColor: "#362d2db3", height: "100%", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            className={css.main_heading}
            sx={{
              fontSize: {
                xl: "72px",
                lg: "72px",
                md: "72px",
                sm: "42px",
                xs: "42px",
              },
              textAlign: "center",
            }}
          >
            About Us
          </Typography>
        </Stack>
      </div>
      <Container maxWidth="xl">
        <Stack py={8} alignItems={"center"} justifyContent={"center"}>
          <Stack
            gap={3}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              width: { xl: "70%", lg: "70%", md: "80%", sm: "90%", xs: "100%" },
            }}
          >
            <Typography
              className={css.intro_title}
              sx={{
                fontSize: {
                  xl: "48px",
                  lg: "48px",
                  md: "48px",
                  sm: "30px",
                  xs: "30px",
                },
              }}
            >
              Your Trusted Partner in Home Care and Maintenance
            </Typography>
            <Typography
              className={css.intro_description}
              sx={{
                fontSize: {
                  xl: "18px",
                  lg: "18px",
                  md: "18px",
                  sm: "15px",
                  xs: "15px",
                },
              }}
            >
              At Done, we believe that your home is more than just a place to
              live—it's a sanctuary, a reflection of who you are, and a place
              where memories are made. That’s why we’re committed to providing
              top-notch home maintenance services that keep your home in perfect
              condition, so you can focus on enjoying life without the stress of
              repairs and upkeep.
            </Typography>
          </Stack>

          <Grid container spacing={4} my={5} alignItems={"stretch"}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} my={1}>
              <Stack
                gap={4}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ width: "100%", height: "100%" }}
              >
                <Stack gap={4} justifyContent={"center"} sx={{ width: "100%" }}>
                  <Typography
                    className={css.best_home_ment_txt}
                    sx={{
                      fontSize: {
                        xl: "20px",
                        lg: "18px",
                        md: "16px",
                        sm: "14px",
                        xs: "14px",
                      },
                    }}
                  >
                    Best Home Maintenance Service
                  </Typography>

                  <Typography
                    className={css.done_right_away_txt}
                    sx={{
                      fontSize: {
                        xl: "60px",
                        lg: "45px",
                        md: "40px",
                        sm: "30px",
                        xs: "30px",
                      },
                    }}
                  >
                    Done Right Away at your Doorstep
                  </Typography>

                  <Typography
                    className={css.done_right_away_description}
                    sx={{
                      fontSize: {
                        xl: "30px",
                        lg: "25px",
                        md: "18px",
                        sm: "15px",
                        xs: "15px",
                      },
                    }}
                  >
                    Keep your home in pristine condition with our all-inclusive
                    maintenance services. We understand that maintaining a home
                    can be overwhelming, so we're here to help.
                  </Typography>
                </Stack>

                <Box className={css.qualified_worker}>
                  <Box className={css.qualified_worker_wrapper}>
                    <Typography
                      className={css.qualified_worker_txt}
                      sx={{
                        fontSize: {
                          xl: "48px",
                          lg: "48px",
                          md: "48px",
                          sm: "30px",
                          xs: "30px",
                        },
                      }}
                    >
                      Qualified Workers
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box sx={{ height: "100%" }}>
                <img src={count_service_img} className={css.service_man} />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Container>

      <Stack
        sx={{ width: "100%", backgroundColor: "rgba(241, 90, 36, 0.10)" }}
        py={10}
      >
        <Container maxWidth="xl" alignItems={"center"}>
          <Grid container alignItems={"stretch"} spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Stack
                justifyContent={"space-evenly"}
                sx={{
                  width: "100%",
                  height: "100%",
                  gap: {
                    xl: 0,
                    lg: 0,
                    md: 0,
                    sm: 1,
                    xs: 1,
                  },
                }}
              >
                <Typography
                  className={css.best_home_ment_txt}
                  sx={{
                    fontSize: {
                      xl: "20px",
                      lg: "18px",
                      md: "16px",
                      sm: "14px",
                      xs: "14px",
                    },
                  }}
                >
                  Mobile app
                </Typography>

                <Typography
                  className={css.done_right_away_txt}
                  sx={{
                    fontSize: {
                      xl: "55px",
                      lg: "40px",
                      md: "30px",
                      sm: "30px",
                      xs: "30px",
                    },
                  }}
                >
                  Introducing Done Right Away App
                </Typography>

                <Typography
                  className={css.done_right_away_description}
                  sx={{
                    fontSize: {
                      xl: "25px",
                      lg: "20px",
                      md: "15px",
                      sm: "15px",
                      xs: "15px",
                    },
                  }}
                >
                  We're excited to introduce our brand-new Home Maintenance App,
                  designed to make managing your home care needs easier and more
                  convenient than ever before. Whether you need to schedule a
                  service, track ongoing repairs, or access expert tips, our app
                  puts everything you need right at your fingertips.
                </Typography>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  columnGap={4}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/coming-soon-app")}
                  >
                    <img src={app_store} className={css.app_store_styl} />
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/coming-soon-app")}
                  >
                    <img src={play_store} className={css.google_store_styl} />
                  </Box>
                </Stack>

                <Stack my={2} alignItems={"flex-start"}>
                  <ButtonComp
                    label="Scan QR"
                    onClick={_handleOpenQRCodeModal}
                    style={{
                      padding: "10px 50px",
                      borderRadius: "10px",
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                      boxShadow:
                        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
                    }}
                  />
                </Stack>

                <CustomModal
                  isOpen={isOpenQRModal}
                  setIsOpen={_handleOpenQRCodeModal}
                >
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      position: "relative",
                      backgroundColor: "#CB5C0A",
                      px: 3,
                      py: 5,
                      borderRadius: "30px",
                      width: {
                        xl: "80%",
                        lg: "80%",
                        md: "80%",
                        sm: "90%",
                        xs: "100%",
                      },
                      margin: "auto",
                    }}
                    gap={3}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "15px",
                        right: "15px",
                      }}
                      onClick={() => {
                        setIsOpenQRModal(false);
                      }}
                    >
                      <RxCross2 style={{ fontSize: "20px", color: "white" }} />
                    </IconButton>

                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        border: "0.5px solid white",
                        borderRadius: "10px",
                        px: 4.5,
                        py: 4.5,
                        cursor: "pointer",
                        mt: 1,
                      }}
                      gap={2}
                      // onClick={_handleUploadClick}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: {
                            xl: "16px",
                            lg: "16px",
                            md: "15px",
                            sm: "14px",
                            xs: "13px",
                          },
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        Scan QR to download the app now
                      </Typography>

                      <Stack sx={{ width: "100%", height: "100%" }}>
                        <CustomQRCode
                          QR_size={250}
                          QR_link={"https://donerightaway.com/coming-soon-app"}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </CustomModal>
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box sx={{ height: "100%" }}>
                <img src={laptop_mobile} className={css.laptop_mobile_styl} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Stack>
      <Footer />
    </div>
  );
};

export default AboutUs;
