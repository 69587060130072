import React from "react";
import "./index.css";
import { FaStar } from "react-icons/fa6";
import { Typography } from "@mui/material";

const SliderCard = ({ review }) => {
  const { rating, msg, user } = review;
  return (
    <div className="slider-card-container">
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            flexWrap: "wrap",
          }}
        >
          {[...Array(5)].map((_, index) => (
            <FaStar
              key={index}
              color={index < rating ? "#F15A24" : "#D3D3D3"}
              className="star_icon"
            />
          ))}
        </div>
        <div>
          <Typography className="card-discription">“{msg || ""}”</Typography>
          <Typography className="card-discription-head">
            {user?.first_name || ""} {user?.last_name || ""}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
