import React, { useEffect, useState } from "react";
import css from "./VendorPersonalDetails.module.css";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import personal_details from "../../../../assets/personal_details.png";
import Input from "../../../../component/common/Input";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../../../component/common/ButtonComp";
import { themeOrange } from "../../../../utils/colorTheme";
import useWindowDimensions from "../../../../utils/hooks/windowDimensions";
import MultiSelectComp from "../../../../component/common/selectComp/MultiSelectComp";
import { get_signup_services } from "../../../../services/authentication";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { vendorPersonalDetailAsync } from "../../../../services/SubscriptionService";
import { setVendorPaymentSetupSatus } from "../../../../store/slices/subscription_slice";
import { Form, Formik } from "formik";
import { getValidationSchema } from "../../../../pages/auth/ValidationsFormikYup/ValidationsSchema";

export const inputStyles = {
  borderRadius: "10px",
  boxShadow: "0px 10.534px 31.601px 0px rgb(178 178 178 / 24%)",
  border: "0.5px solid #E4E4E4 !important",
  fontFamily: "Raleway, sans-serif !important",
};

const VendorPersonalDetails = ({ nextTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user_register_status,
    get_services_for_signup_data,
    get_services_for_signup_status,
    vendor_register_status,
    user,
  } = useSelector((state) => state.userAuth);
  const { vendor_personal_detail_status, vendor_personal_detail_data } =
    useSelector((state) => state.subscriptionSlice);

  const isLoading = vendor_personal_detail_status === asyncStatus.LOADING;

  const personalDetailsFields = [
    {
      id: "first_name",
      label: "First Name",
      type: "text",
      optional_txt: "",
    },
    {
      id: "last_name",
      label: "Last Name",
      type: "text",
    },
    {
      id: "dob",
      label: "Date of Birth",
      type: "date",
    },
    {
      id: "country",
      label: "Country",
      type: "text",
      options: [
        {
          name: "Canada",
          id: "CA",
        },
      ],
    },
    {
      id: "street",
      label: "Street",
      type: "text",
    },
    {
      id: "city",
      label: "City",
      type: "text",
      options: get_services_for_signup_data?.cities || [],
    },
    {
      id: "state",
      label: "State",
      type: "text",
      options: get_services_for_signup_data?.states || [],
    },
    {
      id: "postal_code",
      label: "Postal Code",
      type: "text",
    },
    // {
    //   id: "sin",
    //   label: "Social Insurance Number",
    //   value: personalData.sin,
    //   onChange: (e) => _handleInputChange(e.target.id, e.target.value),
    //   type: "text",

    //   optional_txt: <>(Optional) But recommended for verification</>,
    // },
  ];

  // const businessProfileFields = [
  //   {
  //     id: "mcc",
  //     label: "Merchant Category Code",
  //     value: personalData.mcc,
  //     onChange: (e) => _handleInputChange(e.target.id, e.target.value),
  //     type: "text",
  //
  //     optional_txt: "(Optional)",
  //   },
  //   {
  //     id: "url",
  //     label: "URL",
  //     value: personalData.url,
  //     onChange: (e) => _handleInputChange(e.target.id, e.target.value),
  //     type: "url",
  //
  //     optional_txt: "(Optional) If the individual has a website",
  //   },
  // ];

  useEffect(() => {
    dispatch(get_signup_services({ city: "yes", states: "yes" }));
  }, []);

  const _handleSubmit = (values) => {
    const city_name = get_services_for_signup_data?.cities?.find(
      ({ id }) => id === values.city
    )?.name;
    const state_name = get_services_for_signup_data?.states?.find(
      ({ id }) => id === values.state
    )?.name;

    const obj = {
      day: values.dob.slice(8),
      month: values.dob.slice(5, 7),
      year: values.dob.slice(0, 4),
      line1: values.street,
      city: city_name,
      state: state_name,
      postal_code: values.postal_code,
      country: values.country,
    };
    dispatch(vendorPersonalDetailAsync(obj));
  };

  useEffect(() => {
    if (vendor_personal_detail_status === asyncStatus.SUCCEEDED) {
      dispatch(setVendorPaymentSetupSatus());
      nextTab();
    }
  }, [vendor_personal_detail_status]);

  const initialValues = {
    first_name: "",
    last_name: "",
    dob: "",
    country: "",
    street: "",
    city: "",
    state: "",
    postal_code: "",
  };

  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema("vendor_persona_details")}
            onSubmit={_handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Grid container spacing={5}>
                  {personalDetailsFields.map(
                    ({ id, label, type, options, optional_txt }) => {
                      const isSelect =
                        id === "country" || id === "state" || id === "city";
                      return isSelect ? (
                        <Grid
                          key={id}
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <MultiSelectComp
                            isMultiSelect={false}
                            label={label}
                            options={options}
                            selectedValues={values[id]}
                            onSelectionChange={(value) =>
                              setFieldValue(id, value)
                            }
                            isError={touched[id] && errors[id]}
                            style={inputStyles} // Apply input styles here
                            label_style={{
                              fontWeight: "600",
                              fontFamily: "Raleway, sans-serif !important",
                              color: "#646464",
                            }}
                          />
                          {touched[id] && errors[id] && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}
                            >
                              {errors[id]}
                            </Typography>
                          )}
                          {optional_txt && (
                            <Typography
                              sx={{ color: "grey", fontSize: "12px" }}
                            >
                              {optional_txt || ""}
                            </Typography>
                          )}
                        </Grid>
                      ) : (
                        <Grid
                          key={id}
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Input
                            id={id}
                            onChange={(e) => setFieldValue(id, e.target.value)}
                            value={values[id]}
                            style={{
                              borderRadius: "10px",
                              boxShadow:
                                "0px 10.534px 31.601px 0px rgb(178 178 178 / 24%)",
                              border: "0.5px solid #E4E4E4 !important",
                              fontfamily: "Raleway !important",
                            }}
                            type={type}
                            label={label}
                            isError={touched[id] && errors[id]}
                            label_style={{
                              fontWeight: "600",
                              fontfamily: "Raleway !important",
                            }}
                          />
                          {touched[id] && errors[id] && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}
                            >
                              {errors[id]}
                            </Typography>
                          )}
                          {optional_txt && (
                            <Typography
                              sx={{ color: "grey", fontSize: "12px" }}
                            >
                              {optional_txt || ""}
                            </Typography>
                          )}
                        </Grid>
                      );
                    }
                  )}

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} my={2}>
                    <ButtonComp
                      type="submit"
                      label={
                        isLoading ? (
                          <CircularProgress size={22} sx={{ color: "white" }} />
                        ) : (
                          <>Continue</>
                        )
                      }
                      style={{
                        borderRadius: "10px",
                        fontfamily: "Raleway !important",
                        background:
                          "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <img
            src={personal_details}
            style={{ objectFit: "contain", width: "100%", height: "50vh" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default VendorPersonalDetails;

// {businessProfileFields.map(
//   ({
//     id,
//     label,
//     value,
//     onChange,
//     type,
//     error,
//     options,
//     optional_txt,
//   }) => {
//     return (
//       <Grid key={id} item xl={12} lg={12} md={12} sm={12} xs={12}>
//         <Input
//           id={id}
//           onChange={onChange}
//           value={value}
//           style={{
//             borderRadius: "10px",
//             boxShadow:
//               "0px 10.534px 31.601px 0px rgb(178 178 178 / 24%)",
//             border: "0.5px solid #E4E4E4 !important",
//             fontfamily: "Raleway !important",
//           }}
//           type={type}
//           label={label}
//           isError={error}
//           label_style={{
//             fontWeight: "600",
//             fontfamily: "Raleway !important",
//           }}
//         />
//         {error && (
//           <Typography sx={{ color: "#d32f2f", fontSize: "12px" }}>
//             {error || ""}
//           </Typography>
//         )}
//         {optional_txt && (
//           <Typography sx={{ color: "grey", fontSize: "12px" }}>
//             {optional_txt || ""}
//           </Typography>
//         )}
//       </Grid>
//     );
//   }
// )}
