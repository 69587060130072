import React, { useEffect, useState } from "react";
import css from "./VendorCheckPhone.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "../../../../assets/logo.png";
import { themeOrange } from "../../../../utils/colorTheme";
import Input from "../../../../component/common/Input";
import { common_caption_strings } from "../../../../utils/language_controls/constant_strings";
import ButtonComp from "../../../../component/common/ButtonComp";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { TbReload } from "react-icons/tb";
import { HiArrowSmLeft } from "react-icons/hi";
import OTPInput from "react-otp-input";

const VendorCheckPhone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(true);
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const {
    user_login_status,
    forgot_password_status,
    forgot_password_data,
    forgot_password_error,
  } = useSelector((state) => state.userAuth);

  const isLoading = forgot_password_status === asyncStatus.LOADING;

  const validateForm = () => {
    let formErrors = {};

    // OTP Validation
    if (!otp) {
      formErrors.code = "Code is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const _handleInputChange = (e) => {
    console.log("value of the OTP", e);
    setOtp(e); // Set the OTP value directly

    // Perform validation for OTP
    let updatedErrors = { ...errors };
    if (e) {
      updatedErrors.code = "";
    } else {
      updatedErrors.code = "OTP is required";
    }
    setErrors(updatedErrors);
  };

  const _handleSubmit = () => {
    // if (validateForm()) {
    //   //   dispatch(forgot_password(data));
    //   localStorage.setItem("code", data.code);
    // }
    navigate("/vendor-phone-verified");
  };

  useEffect(() => {
    // if (forgot_password_status === asyncStatus.SUCCEEDED) {
    //   navigate("/check-email");
    //   dispatch(setIdleStatusForgot());
    // }
  }, [forgot_password_status]);
  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Container maxWidth={"xl"}>
            <Stack mt={2}>
              <Stack
                justifyContent={"flex-start"}
                sx={{
                  width: "100%",
                  padding: 5,
                }}
                textAlign={"left"}
                color={"black"}
              >
                <Stack
                  spacing={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack gap={1}>
                    <Stack className="auth_Heading">
                      <Typography variant="caption" className={css.first_txt}>
                        Check Your{" "}
                        <Typography
                          variant="caption"
                          className={`grediant_txt ${css.lst_txt}`}
                        >
                          Phone
                        </Typography>
                      </Typography>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <Typography variant="caption" className={css.description}>
                        Enter the{" "}
                        <Typography
                          variant="caption"
                          className={css.description}
                          sx={{ fontWeight: "700 !important" }}
                        >
                          verification code{" "}
                        </Typography>{" "}
                        we sent to your mobile number ending in{" "}
                        <Typography
                          variant="caption"
                          className={css.description}
                          sx={{ fontWeight: "700 !important" }}
                        >
                          **78
                        </Typography>{" "}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Grid mt={4} container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack mb={3}>
                          <OTPInput
                            value={otp}
                            onChange={_handleInputChange}
                            numInputs={4}
                            inputType="number"
                            renderInput={(props) => <input {...props} />}
                            containerStyle={{ gap: "40px" }}
                            inputStyle={{
                              backgroundColor: "white",
                              height: "70px",
                              width: "50px",
                              borderRadius: "9px",
                              border: "1.053px solid #E4E4E4",
                              boxShadow:
                                "0px 10.534px 31.601px 0px rgba(178, 178, 178, 0.20)",
                              color: "black",
                              fontSize: "25px",
                            }}
                          />

                          {errors.code && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}
                            >
                              {errors?.code || ""}
                            </Typography>
                          )}
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <HiArrowSmLeft
                            onClick={() => navigate("/verify-phone")}
                            style={{
                              fontWeight: "700 !important",
                              cursor: "pointer !important",
                              fontSize: "30px !important",
                            }}
                          />
                          <Typography
                            variant="caption"
                            className={css.description}
                            sx={{
                              fontWeight: "700 !important",
                              cursor: "pointer !important",
                            }}
                          >
                            Use a different mobile number
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
                <Stack>
                  <ButtonComp
                    onClick={_handleSubmit}
                    label={
                      isLoading ? (
                        <CircularProgress size={22} sx={{ color: "white" }} />
                      ) : (
                        "Continue"
                      )
                    }
                    style={{
                      borderRadius: "10px",
                      fontfamily: "Raleway !important",
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                    }}
                  />
                  <ButtonComp
                    onClick={() => navigate("/verify-phone")}
                    label={
                      isLoading ? (
                        <CircularProgress size={22} sx={{ color: "white" }} />
                      ) : (
                        <>
                          <TbReload
                            style={{ fontSize: "18px", color: themeOrange }}
                          />{" "}
                          &nbsp; Resend
                        </>
                      )
                    }
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "white",
                      color: themeOrange,
                      fontfamily: "Raleway !important",
                    }}
                  />
                </Stack>
                {/* <Stack mt={1} direction={"row"} justifyContent={"center"}>
                  <Typography color={"#9F9F9F"}>
                    {common_caption_strings.dont_have_account}
                  </Typography>
                  <Typography
                    onClick={() => navigate("/Signup")}
                    sx={{ color: themeOrange, mx: "2px", cursor: "pointer" }}
                  >
                    {common_caption_strings.Create_one}
                  </Typography>
                </Stack> */}
              </Stack>
            </Stack>
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack alignItems={"center"} className="bgImageSignup" width={1}>
            <Stack
              mt={10}
              width={"70%"}
              className="transparent-bg"
              p={4}
              gap={5}
            >
              <Stack>
                <img width={"80px"} src={Logo} />
              </Stack>
              <Stack>
                <Typography
                  textAlign={"start"}
                  className="subHeading"
                  color={"white"}
                >
                  Welcome Done Right Away
                </Typography>
                <Typography className="mainPara" color={"white"}>
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
              {/* <Stack mt={20}>
            <Typography className="mainPara" color={"white"}>
              Already have an Account?
            </Typography>
            <Typography className="mainPara" color={themeOrange}>
              Submit
            </Typography>
          </Stack> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default VendorCheckPhone;
