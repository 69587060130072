export const asyncStatus = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  ERROR: "error",
};


export const type_constant = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  CHECK_AUTH: "CHECK_AUTH",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  OTP_VERIFY: "OTP_VERIFY",
  EMAIL_VERIFY_BY_OTP: "EMAIL_VERIFY_BY_OTP",
  RESET_PASSWORD: "RESET_PASSWORD",
  EDIT_PROFILE: "EDIT_PROFILE",
  SIGNUP_VENDOR: "SIGNUP_VENDOR",
  USER_PROFILE: "USER_PROFILE",
  SIGNUP_SERVICES_GET: "SIGNUP_SERVICES_GET",
  GET_VENDOR: "GET_VENDOR",
  GET_SERVICES_HOME: "GET_SERVICES_HOME",
  GET_CHATS: "GET_CHATS",
  GET_SERVICE_BY_ID: "GET_SERVICE_BY_ID",
  JOB_POST: "JOB_POST",
  GET_JOBS: "GET_JOBS",
  ACCEPT_JOB: "ACCEPT_JOB",
  SEARCH_SERVICE: "SEARCH_SERVICE",
  UPDATE_STATUS: "UPDATE_STATUS",
  GET_JOBS_BY_VENDOR: "GET_JOBS_BY_VENDOR",
  GET_ALL_JOBS: "GET_ALL_JOBS",
  POST_REVIEW: "POST_REVIEW",
  GET_VENDOR_DATA:"GET_VENDOR_DATA",
  CONTACT_US_ASYNC:"CONTACT_US_ASYNC",
  VENDOR_STRIPE_STARTED_ASYNC:"VENDOR_STRIPE_STARTED_ASYNC",
  SUBSCRIPTION_ASYNC:"SUBSCRIPTION_ASYNC",
  CANCEL_SUBSCRIPTION_ASYNC:"CANCEL_SUBSCRIPTION_ASYNC",
  VERIFICATION_SUCCESS_SSYNC:"VERIFICATION_SUCCESS_SSYNC",
  VENDOR_PERSONAL_DETAIL_ASYNC:"VENDOR_PERSONAL_DETAIL_ASYNC",
  GET_VENDOR_STATUS_ASYNC:"GET_VENDOR_STATUS_ASYNC",
  GET_PAYMENT_REQUEST_DATA:"GET_PAYMENT_REQUEST_DATA",
}


export const save_tokens_constant = {
  AUTH:"auth",
}