import React, { useEffect, useState } from "react";
import css from "./VendorPaymentSetup.module.css";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import setup_payments from "../../../../assets/setup_payments.png";
import Input from "../../../../component/common/Input";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../../../component/common/ButtonComp";
import useWindowDimensions from "../../../../utils/hooks/windowDimensions";
import MultiSelectComp from "../../../../component/common/selectComp/MultiSelectComp";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { vendorPaymentSetupAsync } from "../../../../services/SubscriptionService";
import { setVendorPaymentSetupSatus } from "../../../../store/slices/subscription_slice";
import { Form, Formik } from "formik";
import { getValidationSchema } from "../../../../pages/auth/ValidationsFormikYup/ValidationsSchema";

export const inputStyles = {
  borderRadius: "10px",
  boxShadow: "0px 10.534px 31.601px 0px rgb(178 178 178 / 24%)",
  border: "0.5px solid #E4E4E4 !important",
  fontFamily: "Raleway, sans-serif !important",
};

const VendorPaymentSetup = ({ nextTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setupPaymnetData, setSetupPaymnetData] = useState({
    // currency: [],
    account_holder_name: "",
    routing_number: "",
    account_number: "",
    // account_holder_type: "individual",
  });

  const { vendor_payment_setup_status, account_id } = useSelector(
    (state) => state.subscriptionSlice
  );

  const isLoading = vendor_payment_setup_status === asyncStatus.LOADING;

  const personalDetailsFields = [
    // {
    //   id: "currency",
    //   label: "Currency",
    //   value: setupPaymnetData.currency,
    //   onChange: (value) => _handleInputChange("currency", value),
    //   type: "text",
    //   error: errors.currency,
    //   options: [
    //     {
    //       name: "CAD",
    //       id: "cad",
    //     },
    //   ],
    //   disabled: false,
    // },
    {
      id: "account_holder_name",
      label: "Account Holder Name",
      type: "text",
      disabled: false,
    },
    {
      id: "routing_number",
      label: "Routing Number",
      type: "text",
      disabled: false,
    },
    {
      id: "account_number",
      label: "Account Number",
      type: "text",
      disabled: false,
    },
    // {
    //   id: "account_holder_type",
    //   label: "Account Holder Type",
    //   value: "individual",
    //   onChange: (e) => _handleInputChange(e.target.id, "individual"),
    //   type: "text",
    //   error: errors.account_holder_type,
    //   disabled: true,
    // },
  ];

  const _handleSubmit = (values) => {
    let obj = {
      ...values,
      account_id: account_id || "",
    };
    dispatch(vendorPaymentSetupAsync(obj));
  };

  useEffect(() => {
    if (vendor_payment_setup_status === asyncStatus.SUCCEEDED) {
      dispatch(setVendorPaymentSetupSatus());
      nextTab();
    }
  }, [vendor_payment_setup_status]);

  const initialValues = {
    account_holder_name: "",
    routing_number: "",
    account_number: "",
  };

  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema("vendor_payment_setup")}
            onSubmit={_handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Grid container spacing={5}>
                  {personalDetailsFields.map(
                    ({ id, label, type, options, disabled }) => {
                      const isSelect = id === "currency";
                      return isSelect ? (
                        <Grid
                          key={id}
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <MultiSelectComp
                            label={label}
                            options={options}
                            selectedValues={values[id]}
                            onSelectionChange={(value) =>
                              setFieldValue(id, value)
                            }
                            isError={touched[id] && errors[id]}
                            style={inputStyles} // Apply input styles here
                            label_style={{
                              fontWeight: "600",
                              fontFamily: "Raleway, sans-serif !important",
                              color: "#646464",
                            }}
                            disabled={disabled}
                          />
                          {touched[id] && errors[id] && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}
                            >
                              {errors[id]}
                            </Typography>
                          )}
                        </Grid>
                      ) : (
                        <Grid
                          key={id}
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Input
                            id={id}
                            onChange={(e) => setFieldValue(id, e.target.value)}
                            value={values[id]}
                            style={{
                              borderRadius: "10px",
                              boxShadow:
                                "0px 10.534px 31.601px 0px rgb(178 178 178 / 24%)",
                              border: "0.5px solid #E4E4E4 !important",
                              fontfamily: "Raleway !important",
                            }}
                            type={type}
                            label={label}
                            isError={touched[id] && errors[id]}
                            label_style={{
                              fontWeight: "600",
                              fontfamily: "Raleway !important",
                            }}
                            disabled={disabled}
                          />
                          {touched[id] && errors[id] && (
                            <Typography
                              sx={{ color: "#d32f2f", fontSize: "12px" }}
                            >
                              {errors[id] || ""}
                            </Typography>
                          )}
                        </Grid>
                      );
                    }
                  )}
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} my={2}>
                    <ButtonComp
                      type="submit"
                      label={
                        isLoading ? (
                          <CircularProgress size={22} sx={{ color: "white" }} />
                        ) : (
                          <>Continue</>
                        )
                      }
                      style={{
                        borderRadius: "10px",
                        fontfamily: "Raleway !important",
                        background:
                          "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <img
            src={setup_payments}
            style={{ objectFit: "contain", width: "100%", height: "50vh" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default VendorPaymentSetup;
