import { Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import logo from "../../assets/logo_2.png";
import logo_white from "../../assets/logo_2.png";
import { FaCheck } from "react-icons/fa6";
import { themeOrange } from "../../utils/colorTheme";
import { MdSendTimeExtension } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";

const DiscountCard = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const offerListData = [
    {
      name: "Extended warranty",
      icon_name: (
        <MdSendTimeExtension size={20} style={{ color: themeOrange }} />
      ),
    },
    {
      name: "Cancel membership anytime",
      icon_name: <LuCalendarClock size={20} style={{ color: themeOrange }} />,
    },
  ];

  return (
    <Stack className="discount-main">
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        mt={5}
        sx={{ height: "100%" }}
      >
        <Stack
          direction={"row"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          flexWrap={"nowrap"}
        >
          <img
            src={logo_white}
            style={{
              height: windowWidth < 600 ? "40px" : "62px",
              width: windowWidth < 600 ? "80px" : "145px",
              objectFit: "contain",
            }}
            alt="Logo"
          />
          <Typography
            className="member_txt"
            sx={{
              fontSize: {
                xl: "45px !important",
                lg: "35px !important",
                md: "38px !important",
                sm: "35px !important",
                xs: "25px !important",
              },
              flexShrink: 0,
            }}
          >
            + Member
          </Typography>
        </Stack>

        <Stack
          sx={{
            width: { xl: "80%", lg: "80%", md: "80%", sm: "90%", xs: "90%" },
            height: "100%",
          }}
          alignItems={"center"}
          justifyContent={"center"}
          gap={3}
          my={2}
        >
          <Stack
            className="discount-border"
            sx={{
              width: {
                xl: "80% !important",
                lg: "100% !important",
                md: "100% !important",
                sm: "65% !important",
                xs: "100% !important",
              },
            }}
            px={3}
            py={1}
            color={"white"}
          >
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "100%", gap: { xl: 5, lg: 5, md: 1, sm: 5, xs: 2 } }}
            >
              <Stack>
                <Typography
                  className="save_25"
                  sx={{
                    fontSize: {
                      xl: "30px !important",
                      lg: "30px !important",
                      md: "20px !important",
                      sm: "15px !important",
                      xs: "15px !important",
                    },
                  }}
                >
                  Save $25
                </Typography>
                <Typography
                  className="on_every_done_txt"
                  sx={{
                    fontSize: {
                      xl: "15px !important",
                      lg: "15px !important",
                      md: "12px !important",
                      sm: "12px !important",
                      xs: "12px !important",
                    },
                  }}
                >
                  on every Done job
                </Typography>
              </Stack>
              <Typography className="for_10_month">
                just for $10/month
              </Typography>
            </Stack>
          </Stack>

          <Stack
            justifyContent={"flex-start"}
            gap={1}
            // mt={1}
            alignItems={"flex-start"}
            sx={{ width: "100%" }}
          >
            <Divider sx={{ bgcolor: "white", width: "100%" }} />
            <Stack my={1} gap={1}>
              {offerListData.map(({ name, icon_name }) => {
                return (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    columnGap={2}
                  >
                    <Stack mr={1}>{icon_name || ""}</Stack>
                    <Typography className="list_name">{name || ""}</Typography>
                  </Stack>
                );
              })}
            </Stack>
            <Divider sx={{ bgcolor: "white", width: "100%" }} />
          </Stack>

          <Stack alignItems={"center"} justifyContent={"center"} gap={2} px={1}>
            <Typography
              className="joining_easy_txt"
              sx={{ color: "#ffffff78" }}
            >
              Joining is easy!
            </Typography>
            <Typography
              className="joining_easy_description"
              sx={{ color: "#ffffff78" }}
            >
              Choose the Done+ as the price rate when you book your next job.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DiscountCard;
