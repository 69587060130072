import { CircularProgress, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardNavbar from "../../component/DashboardNavbar/DashboardNavbar";
import dashboardImage from "../../assets/dashboardImage.png";
import FirstCard from "../../component/ServiceDetailCards/FirstCard";
import SecondCard from "../../component/ServiceDetailCards/SecondCard";
import DiscountCard from "../../component/ServiceDetailCards/DiscountCard";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import FirstStep from "../../component/StepScreen/FirstStep";
import { BsTools } from "react-icons/bs";
import SecondStep from "../../component/StepScreen/SecondStep";
import LastStep from "../../component/StepScreen/LastStep ";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get_service_by_id } from "../../services/services";
import WrappedLastStep from "../../component/StepScreen/LastStep ";
import Navbar from "../../component/navbar/Navbar";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeOrange } from "../../utils/colorTheme";
import { imgUrl } from "../../utils/imageUrl";
import VendorReviews from "../../component/ServiceDetailCards/VendorReviews";
import {
  service_booking_keys,
  SERVICE_DETAIL_PAGE_CONSTANT,
  steps_constants,
} from "../../store/constants";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { setJobsImages } from "../../store/slices/services_slice";



const ServiceDetailsPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [jobsState, setJobsState] = useState(null);
  const [serviceId, setServiceId] = useState("");
  const {
    get_service_by_id_status,
    get_service_by_id_data,
    get_service_by_id_error,
    post_job_service_status,
    post_job_service_data,
  } = useSelector((state) => state.serviceManage);
  const { job_step } = useSelector((state) => state.uiControle);

  const loader = get_service_by_id_status === asyncStatus.LOADING;

  useEffect(() => {
    const service_id = localStorage.getItem(
      SERVICE_DETAIL_PAGE_CONSTANT?.service_id
    );
    setServiceId(service_id);
  }, []);

  useEffect(() => {
    dispatch(get_service_by_id(serviceId || id));
    window.scrollTo(0, 0);
    return () => {
      dispatch(setJobsSteps({ value: null }));
      localStorage.removeItem(steps_constants.JOBS_STEP);
      dispatch(setJobsImages([]));
      localStorage.removeItem(service_booking_keys.DATE);
      localStorage.removeItem(service_booking_keys.TIME);
      localStorage.removeItem(service_booking_keys.DESCRIPTION);
      localStorage.removeItem(service_booking_keys.LOCATION);
      localStorage.removeItem(service_booking_keys.PRICE);
    };
  }, []);

  useEffect(() => {
    const jobs_steps = localStorage.getItem(steps_constants.JOBS_STEP);
    setJobsState(jobs_steps);
  }, [, jobsState, job_step, dispatch]);

  return (
    <div>
      <Stack>
        <Navbar />
      </Stack>
      <Stack>
        <Stack>
          <img
            src={`${imgUrl}${get_service_by_id_data?.service?.mobile_image}`}
            style={{ objectFit: "cover", width: "100%", height: "66vh" }}
          />
        </Stack>

        <Container maxWidth={"xl"} sx={{ mt: -20 }}>
          <Grid container spacing={3}>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              {jobsState === null ? (
                <Stack
                  sx={{
                    paddingX: {
                      xl: 5,
                      lg: 5,
                      md: 5,
                      sm: 5,
                      xs: 1,
                    },
                    paddingY: 5,
                    borderRadius: "10px",
                    backgroundColor: "white",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  }}
                >
                  <FirstCard />
                </Stack>
              ) : (
                <Stack
                  mb={2}
                  sx={{
                    padding: 5,
                    borderRadius: "10px",
                    backgroundColor: "white",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  }}
                >
                  {jobsState === "3" ? (
                    <WrappedLastStep />
                  ) : (
                    <Stack>
                      {jobsState === "1" ? <FirstStep /> : <SecondStep />}
                    </Stack>
                  )}
                </Stack>
              )}
            </Grid>

            {jobsState !== "3" && (
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                mb={jobsState === null ? 0 : 5}
              >
                <Stack
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "white",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    display: {
                      xl: "block",
                      lg: "block",
                      md: "block",
                      sm: "none",
                      xs: "none",
                    },
                  }}
                >
                  <SecondCard />
                </Stack>
                {jobsState === null && (
                  <Stack mt={2} mb={5}>
                    <DiscountCard />
                  </Stack>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
            {jobsState === null && (
              <Stack
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  my: 5,
                  mb: 10,
                  // mt: { xl: -40, lg: -30, md: -25, sm: 0, xs: 0 },
                }}
              >
                <VendorReviews />
              </Stack>
            )}
          </Grid>
        </Container>

        <Stack>
          <Footer />
        </Stack>
      </Stack>
    </div>
  );
};

export default ServiceDetailsPage;
