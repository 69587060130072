import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import step_1 from "../../assets/how_works/step_1_b.png";
import step_2 from "../../assets/how_works/step_2_b.png";
import step_3 from "../../assets/how_works/step_3_b.png";
import step_4 from "../../assets/how_works/step_4_b.png";
import ButtonComp from "../../component/common/ButtonComp";
import { useNavigate } from "react-router-dom";
import css from "./HowWorksSection.module.css";

const HowWorksSection = () => {
  const navigate = useNavigate();

  const howWorksArr = [
    {
      img: step_1,
      guience_txt:
        "Choose a date and time that works for you, and tell us what you need.",
    },
    {
      img: step_2,
      guience_txt: "Done will find the perfect pro in minute.",
    },
    {
      img: step_3,
      guience_txt: "Your Done Pro gets the job done while you relax.",
    },
    {
      img: step_4,
      guience_txt:
        "Done syncs your job status, messages, and receipt all in one place.",
    },
  ];

  return (
    <Container maxWidth="xl">
      <Stack mt={10} alignItems={"center"} justifyContent={"center"}>
        {" "}
        <Typography className={css.second_section_heading}>
          How <span style={{ color: "#F15A24" }}>it works </span>
        </Typography>
      </Stack>
      <Grid
        container
        direction={"row"}
        alignItems={"stretch"}
        justifyContent={"space-between"}
        spacing={1}
        my={2}
      >
        {howWorksArr.map(({ guience_txt, img }, i) => {
          return (
            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
              <Stack
                key={i}
                alignItems={"center"}
                gap={2}
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  padding: 3,
                  height: "100%",
                }}
              >
                <Box>
                  <img
                    src={img}
                    style={{ height: "130px", objectFit: "contain" }}
                  />
                </Box>
                <Typography
                  className="how-its-works-discription"
                  mt={1}
                  sx={{
                    textAlign: "center !important",
                    fontSize: "20px !important",
                    lineHeight: "normal !important",
                  }}
                >
                  {guience_txt}
                </Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>

      <Stack my={10} alignItems={"center"} justifyContent={"center"}>
        <ButtonComp
          label="Start Booking My Service"
          onClick={() => navigate("/services")}
          style={{
            padding: "10px 50px",
            borderRadius: "10px",
            boxShadow:
              "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
            background:
              "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
          }}
        />
      </Stack>
    </Container>
  );
};

export default HowWorksSection;
