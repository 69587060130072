

import React, { useEffect, useRef, useState } from "react";
import { Avatar, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import css from "./EarningMenu.module.css";
import { useNavigate } from "react-router-dom";
import { success_toast_message } from "../../../utils/toast_message";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const EarningMenu = ({ selectedOption, setSelectedOption }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("Weekly"); // Store selected option
  const menuRef = useRef(null); // Reference to the menu

  const handleToggle = () => {
    setOpen(!open);
  };

  const { user, get_user_profile_data } = useSelector(
    (state) => state.userAuth
  );

  // Close menu when clicking outside of it
  useEffect(() => {
    const _handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", _handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
    };
  }, []);

  // // Handle option selection
  // const handleOptionSelect = (option) => {
  //   setSelectedOption(option); // Update selected option
  //   setOpen(false); // Close the dropdown menu after selection
  // };

    // Handle option selection
    const handleOptionSelect = (option) => {
      setSelectedOption(option); // Update selected option via props
      setOpen(false); // Close the dropdown menu after selection
    };

  return (
    <div className={css.custom_menu} ref={menuRef}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={2}
        onClick={handleToggle}
        style={{ cursor: "pointer" }}
      >
        <Typography className={css.selected_option}>
          {selectedOption}
        </Typography>{" "}
        {/* Display selected option */}
        <MdOutlineKeyboardArrowDown
          style={{ fontSize: "30px", color: "#949494" }}
        />
      </Stack>

      {open && (
        <div className={css.menu_items}>
          <button
            className={css.menu_item}
            onClick={() => handleOptionSelect("weekly")} // Select "Weekly"
          >
            Weekly
          </button>
          <button
            className={css.menu_item}
            onClick={() => handleOptionSelect("monthly")} // Select "Monthly"
          >
            Monthly
          </button>
        </div>
      )}
    </div>
  );
};

export default EarningMenu;
