import React, { useEffect } from "react";
import css from "./PaymentRequest.module.css";
import CustomTableComp from "../../../component/common/CustomTableComp/CustomTableComp";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import { themeOrange } from "../../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPaymentRequestDataAsync,
  JobGetByVendorAsync,
} from "../../../services/services";
import { asyncStatus } from "../../../utils/asyncStatus";

const columns = [
  { id: "name", label: "Service" },
  { id: "description", label: "Description" },
  { id: "total_amount", label: "Amount" },
  { id: "date", label: "Date" },
  {
    id: "payments_status",
    label: "Status",
    render: (value) => {
      const isRequested = value === "Requested";
      const isReleased = value === "Released";
      const isDeclined = value === "Declined";
      const statusStyle = {
        color: isReleased ? "#52C24A" : isRequested ? "#F15A24" : "#5A5A5A",
        fontFamily: "Raleway !important",
        fontWeight: "600",
        // backgroundColor: "red",
      };
      return <span style={statusStyle}>{value}</span>;
    },
  },
];

const data = [
  {
    username: "johnnc001",
    name: "John Cooper",
    service: "BBQ Cleaning and Repairing",
    payments_status: "Successful",
  },
  {
    username: "kristin.watson02",
    name: "Kristin Watson",
    service: "Lorem Ipsum dolor sit amet",
    payments_status: "Pending",
  },
  {
    username: "jones.jacob23",
    name: "Jacob Jones",
    service: "Lorem Ipsum dolor sit amet",
    payments_status: "Unsuccessful",
  },
  // Add more rows as needed
];

const paymentActions = (row) => [
  {
    label: "Payment Request",
    onClick: () => console.log(`Payment Request for ${row.username}`),
    disabled: row.payments_status !== "Successful",
  },
];

const PaymentRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    get_vendor_all_jobs_status,
    get_job_by_vendor_data,
    get_payment_request_data,
    get_payment_request_status,
  } = useSelector((state) => state.serviceManage);

  const getVendorJobsLoader =
    get_vendor_all_jobs_status === asyncStatus.LOADING;
  const updatePaymentRequestStatusLoader =
    get_payment_request_status === asyncStatus.LOADING;

  const _handleDescriptionLength = (desc) => {
    return desc.length > 20 ? `${desc.slice(0, 20)}...` : desc;
  };

  // Map your original data to match the new columns structure
  const formattedData = get_payment_request_data?.map((item) => {
    return {
      name: item?.service.name,
      description: _handleDescriptionLength(item?.service.description),
      total_amount: item?.total_amount,
      date: item?.date,
      payments_status: item?.payments_status,
    };
  });

  useEffect(() => {
    dispatch(JobGetByVendorAsync());
    dispatch(getPaymentRequestDataAsync({ payments_status: "Requested" }));
  }, []);

  return (
    <Container maxWidth="xl">
      <Stack gap={3}>
        <Stack>
          <Typography variant="caption" className={css.total}>
            Payment{" "}
            <Typography variant="caption" className={css.main_heading}>
              Requests
            </Typography>
          </Typography>
          <hr color={"#D1D1D1"} />
        </Stack>
        {updatePaymentRequestStatusLoader ? (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "60vh", width: "100%" }}
          >
            <CircularProgress size={30} sx={{ color: themeOrange }} />
          </Stack>
        ) : get_payment_request_data?.length > 0 ? (
          <CustomTableComp
            columns={columns}
            data={formattedData}
            actions={paymentActions}
          />
        ) : (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "30vh", width: "100%" }}
          >
            <Typography className="secondSubHeading">No Jobs found</Typography>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default PaymentRequest;
