import { Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import { themeBlue, themeOrange } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { common_caption_strings } from "../../utils/language_controls/constant_strings";
import authImage from "../../assets/auth_image.png";
import Logo from "../../assets/logo.png";
import "./auth.css";
import { signup_service_auth } from "../../services/authentication";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { asyncStatus } from "../../utils/asyncStatus";
import { setIdleRegisterStatus } from "../../store/slices/user_auth_slice";
import { LoadingButton } from "@mui/lab";
import { error_toast_message } from "../../utils/toast_message";
import CustomPhoneInput from "../../component/common/CustomPhoneInput/CustomPhoneInput";
import { EMAIL_CONSTANTS } from "../../store/constants";
import { Form, Formik } from "formik";
import { getValidationSchema } from "./ValidationsFormikYup/ValidationsSchema";

const UserSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_register_status, user } = useSelector((state) => state.userAuth);

  const isLoading = user_register_status === asyncStatus.LOADING;

  // Single state to handle visibility for password and confirm password fields
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [field]: !prevState[field], // Toggle the visibility for the specified field
    }));
  };

  const _handleSubmit = (values) => {
    const { ...rest } = values;
    const payload = {
      ...rest,
      user_role: 3,
    };
    console.log("Submitted payload:", payload);
    dispatch(signup_service_auth(payload));
  };

  useEffect(() => {
    if (
      user_register_status === asyncStatus.SUCCEEDED &&
      !user?.email_verified_at
    ) {
      navigate("/vendor-check-email");
      dispatch(setIdleRegisterStatus());
    }
  }, [user_register_status]);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Container maxWidth={"xl"}>
            <Stack mt={{ xl: 8, lg: 8, md: 8, sm: 5, xs: 5 }}>
              <Stack
                justifyContent={"flex-start"}
                sx={{
                  padding: 5,
                }}
                textAlign={"left"}
                color={"black"}
              >
                <Stack spacing={3} mb={2}>
                  <Stack spacing={1}>
                    <Stack className="auth_Heading">
                      <span className="auth_Heading">
                        Welcome To{" "}
                        <span style={{ color: themeOrange }}>Done</span>
                      </span>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <span>Create a free account with Done.</span>
                    </Stack>
                  </Stack>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={getValidationSchema("user_signup")}
                    onSubmit={_handleSubmit}
                  >
                    {({ errors, touched, setFieldValue, values }) => (
                      <Form>
                        <Stack gap={3} mb={2}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Input
                                id={"first_name"}
                                onChange={(e) =>
                                  setFieldValue(
                                    "first_name",
                                    e.target.value.trim()
                                  )
                                }
                                style={{
                                  borderRadius: "6px",
                                  outlined: "none",
                                  border: "none",
                                }}
                                placeholder={common_caption_strings.first_name}
                                label="First Name"
                                isError={
                                  touched.first_name && errors.first_name
                                }
                              />
                              {touched.first_name && errors.first_name && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.first_name}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Input
                                id={"last_name"}
                                type={"text"}
                                onChange={(e) =>
                                  setFieldValue(
                                    "last_name",
                                    e.target.value.trim()
                                  )
                                }
                                style={{ borderRadius: "6px" }}
                                placeholder={common_caption_strings.last_name}
                                label="Last Name"
                                isError={touched.last_name && errors.last_name}
                              />
                              {touched.last_name && errors.last_name && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.last_name}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Input
                                id={"email"}
                                type={"text"}
                                onChange={(e) =>
                                  setFieldValue("email", e.target.value.trim())
                                }
                                style={{ borderRadius: "86x" }}
                                placeholder={
                                  common_caption_strings.example_email
                                }
                                label="Email"
                                isError={touched.email && errors.email}
                              />
                              {touched.email && errors.email && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.email}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <CustomPhoneInput
                                value={values.phone}
                                onChange={(e) => {
                                  setFieldValue("phone", e);
                                  console.log("phone number", e);
                                }}
                                error={
                                  touched.phone && errors.phone
                                    ? errors.phone
                                    : ""
                                }
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Stack position={"relative"}>
                                <Input
                                  type={
                                    showPasswords.password ? "text" : "password"
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "password",
                                      e.target.value.trim()
                                    );
                                  }}
                                  style={{ borderRadius: "6px" }}
                                  placeholder={common_caption_strings.password}
                                  label="Password"
                                  value={values.password}
                                  isError={touched.password && errors.password}
                                />
                                {showPasswords.password ? (
                                  <BsFillEyeSlashFill
                                    onClick={() =>
                                      togglePasswordVisibility("password")
                                    }
                                    style={{
                                      position: "absolute",
                                      top: 20,
                                      right: 10,
                                      cursor: "pointer",
                                    }}
                                    size={18}
                                  />
                                ) : (
                                  <BsFillEyeFill
                                    onClick={() =>
                                      togglePasswordVisibility("password")
                                    }
                                    style={{
                                      position: "absolute",
                                      top: 20,
                                      right: 10,
                                      cursor: "pointer",
                                    }}
                                    size={18}
                                  />
                                )}
                              </Stack>
                              {touched.password && errors.password && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.password}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Stack position={"relative"}>
                                <Input
                                  type={
                                    showPasswords.confirmPassword
                                      ? "text"
                                      : "password"
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "confirmPassword",
                                      e.target.value.trim()
                                    );
                                  }}
                                  style={{ borderRadius: "6px" }}
                                  placeholder={
                                    common_caption_strings.confirm_Password
                                  }
                                  label="Confirm Password"
                                  isError={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                />

                                {showPasswords.confirmPassword ? (
                                  <BsFillEyeSlashFill
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        "confirmPassword"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: 20,
                                      right: 10,
                                      cursor: "pointer",
                                    }}
                                    size={18}
                                  />
                                ) : (
                                  <BsFillEyeFill
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        "confirmPassword"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: 20,
                                      right: 10,
                                      cursor: "pointer",
                                    }}
                                    size={18}
                                  />
                                )}
                              </Stack>
                              {touched.confirmPassword &&
                                errors.confirmPassword && (
                                  <Typography
                                    sx={{ color: "#d32f2f", fontSize: "12px" }}
                                  >
                                    {errors.confirmPassword}
                                  </Typography>
                                )}
                            </Grid>
                          </Grid>
                          <ButtonComp
                            type="submit"
                            label={
                              isLoading ? (
                                <CircularProgress
                                  size={22}
                                  sx={{ color: "white" }}
                                />
                              ) : (
                                common_caption_strings.Signup
                              )
                            }
                            style={{
                              borderRadius: "10px",
                              background:
                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                              boxShadow:
                                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
                            }}
                            disabled={isLoading}
                          />
                        </Stack>
                      </Form>
                    )}
                  </Formik>

                  <Stack></Stack>
                  <Stack mt={1} direction={"row"} justifyContent={"center"}>
                    <Typography color={"#9F9F9F"}>
                      {common_caption_strings.already_have_account}
                    </Typography>
                    <Typography
                      onClick={() => navigate("/login")}
                      sx={{ color: themeOrange, mx: "2px", cursor: "pointer" }}
                    >
                      {common_caption_strings.Login}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            className="bgImageSignup"
            width={1}
          >
            {/* <img src={authImage} style={{  width: '80%', }} /> */}
            <Stack
              // mt={10}
              width={"60%"}
              className="transparent-bg"
              p={4}
              gap={5}
            >
              <Stack>
                <img width={"100px"} src={Logo} />
              </Stack>
              <Stack>
                <Typography
                  textAlign={"start"}
                  className="subHeading"
                  color={"white"}
                >
                  Sign Up for Done Right Away
                </Typography>
                <Typography className="mainPara" color={"white"}>
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
              {/* <Stack mt={20}>
                <Typography className="mainPara" color={"white"}>
                  Already have an Account?
                </Typography>
                <Typography className="mainPara" color={themeOrange}>
                  Sign In
                </Typography>
              </Stack> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
export default UserSignUp;
