// slices/uiSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { steps_constants, uiControllers } from "../constants";

const initialState = {
  isStarted: false,
  isNextFirstStep: false,
  isNextSecondStep: false,

  job_step: "0",
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setJobsSteps: (state, { payload }) => {
      state.job_step = payload.value;
      localStorage.setItem(steps_constants.JOBS_STEP, payload.value);
    },
  },
});

export const {
  setJobsSteps,
} = uiSlice.actions;

export default uiSlice.reducer;
