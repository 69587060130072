import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
} from "@mui/material";
import css from "./CustomTableComp.module.css";
import CustomButton from "./../Button/Button";
import ButtonComp from "../ButtonComp";

const TableComponent = ({
  columns,
  data,
  actions,
  rowsPerPageOptions = [5, 10, 25],
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer
        sx={{ maxHeight: 700, borderRadius: "10px 10px 0px 0px" }}
      >
        <Table>
          <TableHead
            className="globleGradientBlue"
            sx={{
              boxShadow: "none",
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className={css.header_cell}
                  align={column.align || "left"}
                  sx={{ color: "white" }}
                >
                  {column.label}
                </TableCell>
              ))}
              {actions && (
                <TableCell
                  className={css.header_cell}
                  align="center"
                  sx={{ color: "white" }}
                >
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align || "left"}>
                      {column.render
                        ? column.render(row[column.id], row)
                        : row[column.id]}
                    </TableCell>
                  ))}
                  {actions && (
                    <TableCell align="center">
                      {actions(row).map((action, actionIndex) => (
                        <ButtonComp
                          key={actionIndex}
                          disabled={action.disabled}
                          onClick={() => action.onClick(row)}
                          label={action.label}
                          style={{
                            borderRadius: "13px",
                            fontSize: "11px",
                            background:action.disabled ? "#E2E2E2" :
                              "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                          }}
                        />
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableComponent;
