import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandle, baseURL } from "../config/apiHandle/apiHandle";
import { type_constant } from "../utils/asyncStatus";

export const getChatUsersListAsync = createAsyncThunk(
  type_constant.GET_CHATS,
  async () => {
    try {
      const response = await apiHandle.get(`/chat-users`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR FROM GET CHAT USERS LIST =======>>>>>", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
