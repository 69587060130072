import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import profile_img from "../../../assets/profile.png";
import { themeOrange } from "../../../utils/colorTheme";

function OrderHistoryTable({ data = [] }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography
                sx={{ fontWeight: "600", color: themeOrange, fontSize: 17 }}
              >
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={{ fontWeight: "600", color: themeOrange, fontSize: 17 }}
              >
                Service Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={{ fontWeight: "600", color: themeOrange, fontSize: 17 }}
              >
                Status
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => {
            const isCompleted = row.status === "Completed";
            return (
              <TableRow key={index}>
                <TableCell>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <img
                      width={"36px"}
                      src={row?.img || "defaultImagePath"}
                      alt={row?.name}
                      style={{ borderRadius: "7px" }}
                    />
                    <Typography color={"#5A5A5A"} className="subPara">
                      {row?.name}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography color={"#5A5A5A"} className="subPara">
                    {row?.service_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="caption"
                    sx={{
                      backgroundColor: isCompleted ? "lightgreen" : "#f300002e",
                      color: isCompleted ? "green" : "red",
                      borderRadius: "10px",
                      py: "4px",
                      px: "10px",
                    }}
                  >
                    {row.status}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OrderHistoryTable;
