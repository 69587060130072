import React, { useEffect, useRef, useState } from "react";
import css from "./BarGraph.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Stack, Typography } from "@mui/material";
import EarningMenu from "../../common/EarningMenu/EarningMenu";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const chartRef = useRef(null);
  const { get_job_by_vendor_data, get_vendor_status, get_vendor_data } =
    useSelector((state) => state.serviceManage);

  const [viewMode, setViewMode] = useState("monthly");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const generateGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    gradient.addColorStop(0, "#F15A24");
    gradient.addColorStop(1, "#982800");
    return gradient;
  };

  // Fixed array for month labels in correct order
  const monthLabelsInOrder = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Mock API data (replace with actual data from get_vendor_data)
  const apiMonthlyData = get_vendor_data?.monthly_earning || {};

  const apiWeeklyData = get_vendor_data?.weekly || {};

  // Map API data to the fixed month labels array
  const mappedMonthlyData = monthLabelsInOrder.map((month) => {
    return apiMonthlyData[month] !== undefined ? apiMonthlyData[month] : 0;
  });

  // Extract weekly labels and data
  const weeklyLabels = Object.keys(apiWeeklyData); // ["Week1", "Week2", "Week3", "Week4"]
  const weeklyData = Object.values(apiWeeklyData); // [190, 0, 0, 0]

  // Update the chart data whenever viewMode changes
  useEffect(() => {
    const newChartData = {
      labels: viewMode === "weekly" ? weeklyLabels : monthLabelsInOrder,
      datasets: [
        {
          label: viewMode === "weekly" ? "Weekly Sales" : "Monthly Sales",
          data: viewMode === "weekly" ? weeklyData : mappedMonthlyData,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) return null; // Prevent error on first render
            return generateGradient(ctx, chartArea); // Use gradient for bars
          },
          borderColor: "#982800",
          borderWidth: 0,
          borderRadius: 6,
          barPercentage: 0.5,
          categoryPercentage: 0.8,
        },
      ],
    };
    setChartData(newChartData);
  }, [viewMode, mappedMonthlyData, weeklyData]); // Re-run effect when viewMode or data changes

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `$${value}`; // Format with $ symbol
          },
        },
        suggestedMin: 0,
        suggestedMax: 5000,
      },
    },
    animation: {
      duration: 100, // Disable animations for better performance
    },
    hover: {
      animationDuration: 0, // Disable hover animations
      mode: "nearest", // Only activate nearest item on hover
      intersect: true, // Only trigger hover when directly over the bar
    },
    // interaction: {
    //   mode: "index",
    //   intersect: false, // Improve interaction performance by limiting triggers
    // },
  };

  return (
    <div className={css.total_revenue_wrapper}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          className={css.total_rev}
          sx={{
            fontSize: {
              xl: "32px !important",
              lg: "28px !important",
              md: "25px !important",
              sm: "23px !important",
              xs: "21px !important",
            },
          }}
        >
          Amount Earned
        </Typography>

        <EarningMenu
          selectedOption={viewMode}
          setSelectedOption={setViewMode}
        />
      </Stack>
      <Stack alignItems={"center"} sx={{ width: "100%", height: "400px" }}>
        <Bar ref={chartRef} data={chartData} options={options} />
      </Stack>
    </div>
  );
};

export default BarChart;
