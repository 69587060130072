import {
  Container,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  createTheme,
  Box,
  ThemeProvider,
  Divider,
} from "@mui/material";
import React, { useEffect } from "react";
import { themeBlue, themeGray, themeOrange } from "../../../utils/colorTheme";
import profile from "../../../assets/profile.png";
import ReactStars from "react-rating-stars-component";
import { FaCalendarDays } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { MdDescription } from "react-icons/md";
import ButtonComp from "../../../component/common/ButtonComp";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { imgUrl } from "../../../utils/imageUrl";
import { inputDateFormate } from "../../../utils/CustomFormator";
import {
  getVendorDataAsync,
  JobGetByVendorAsync,
} from "../../../services/services";
import Chart from "../../../component/Chart.jsx/Chart";
import OrderHistoryTable from "../../../component/common/customTable/OrderHistoryTable";
import css from "./VendorDashboard.module.css";
import blue_wave from "../../../assets/blue_wave.png";
import orange_wave from "../../../assets/orange_wave.png";
import white_wave from "../../../assets/white_wave.png";
import BarChart from "../../../component/Graphs/BarGraph/BarGraph";
import { useNavigate } from "react-router-dom";

const DashboardHome = () => {
  const { user, get_user_profile_data } = useSelector(
    (state) => state.userAuth
  );
  const { get_job_by_vendor_data, get_vendor_status, get_vendor_data } =
    useSelector((state) => state.serviceManage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const theme1 = createTheme({
    palette: {
      primary: {
        main: "#F39473", // Color 1
      },
    },
  });

  useEffect(() => {
    const isEmailVerified = user?.email_verified_at;
    if (!isEmailVerified) {
      navigate("/vendor-check-email");
    }
    dispatch(JobGetByVendorAsync());
    dispatch(getVendorDataAsync());
    window.scrollTo(0, 0);
  }, []);

  const data = [
    {
      count: 1786,
      status: "Completed Projects",
      bgColor: themeOrange,
    },
    {
      count: 650,
      status: "Declined Projects",
      bgColor: themeBlue,
    },
  ];

  const statusCounts = Array.isArray(get_job_by_vendor_data)
    ? get_job_by_vendor_data?.reduce(
        (acc, item) => {
          if (item?.status === "Completed") {
            acc.completed += 1;
          } else if (item?.status === "Cancelled") {
            acc.cancelled += 1;
          }
          return acc;
        },
        { completed: 0, cancelled: 0 }
      )
    : [];

  const chartData = [
    {
      label: "Completed Projects",
      value: statusCounts.completed,
      color: themeOrange,
    },
    {
      label: "Cancelled Projects",
      value: statusCounts.cancelled,
      color: themeBlue,
    },
  ];

  const orderHistoryData = Array.isArray(get_job_by_vendor_data)
    ? get_job_by_vendor_data
        ?.filter(
          (item) => item?.status === "Completed" || item?.status === "Cancelled"
        )
        .slice(-5)
        .map((item) => ({
          name: `${item?.user?.first_name || ""} ${
            item?.user?.last_name || ""
          }`,
          service_name: item?.service?.name || "",
          status: item?.status || "",
          img: `${imgUrl}${item?.user?.avatar || ""}`,
        }))
    : [];

  const isStatusComplete =
    get_job_by_vendor_data?.filter(
      ({ status }) => status === "Completed" || status === "Cancelled"
    )?.length > 0;

  const jobsData = [
    {
      wave_img: blue_wave,
      count: get_vendor_data?.today_bookings?.toString() || "",
      title: "Today",
      second_txt: "Bookings",
      backgroundClr:
        "var(--Blue, linear-gradient(180deg, #071d5b 0%, #031444 100%))",
    },
    {
      wave_img: orange_wave,
      count: get_vendor_data?.total_bookings?.toString() || "",
      title: "Total",
      second_txt: "Bookings",
      backgroundClr: "linear-gradient(180deg, #F15A24 0%, #982800 100%)",
    },
    // {
    //   wave_img: white_wave,
    //   count: get_vendor_data?.total_bookings.toString() || "",
    //   title: "Total",
    //   second_txt: "Bookings",
    //   backgroundClr:
    //     "var(--White, linear-gradient(180deg, #FCFCFC 0%, #E8E8E8 100%))",
    // },
  ];
  const totalEarnedData = [
    {
      wave_img: white_wave,
      count: get_vendor_data?.total_amount?.toLocaleString(),
      title: "Total Amount",
    },
    {
      wave_img: white_wave,
      count: get_vendor_data?.pending_amount?.toLocaleString(),
      title: "Pending Amount",
    },
    // {
    //   wave_img: white_wave,
    //   count: get_vendor_data?.total_amount.toLocaleString(),
    //   title: "Year",
    // },
  ];

  return (
    <Stack bgcolor={"#FAFAFA"}>
      <Container
        sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Stack gap={3}>
          <Stack gap={3}>
            <Typography variant="caption" className={css.vendor_top_label_bl}>
              Hi{" "}
              <Typography
                variant="caption"
                className={`grediant_txt ${css.vendor_top_label_or}`}
              >
                {user?.first_name || ""}{" "}
                <Typography
                  variant="caption"
                  className={css.vendor_top_label_bl}
                >
                  , Welcome to your{" "}
                  <Typography
                    variant="caption"
                    className={`grediant_txt ${css.vendor_top_label_or}`}
                  >
                    Dashboard
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
            <hr color={"#D1D1D1"} />
          </Stack>

          <Typography className={css.main_heading}>Jobs</Typography>
          <Grid container spacing={3} alignItems={"stretch"}>
            {jobsData?.map(
              ({ wave_img, title, count, backgroundClr, second_txt }) => {
                const isOrange = "Total Bookings" === `${title} ${second_txt}`;
                return (
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Stack
                      className={css.avail_jobs}
                      justifyContent={"space-between"}
                      sx={{ background: backgroundClr, height: "100%" }}
                    >
                      <Stack
                        sx={{ padding: "24px 24px 24px 24px", zIndex: 1 }}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          className={css.avail_count}
                          sx={{
                            color: "white !important",
                          }}
                          my={1}
                        >
                          {count || ""}
                        </Typography>
                        <Typography
                          variant="caption"
                          className={css.avail_txt}
                          my={1}
                          sx={{
                            color: "white !important",
                          }}
                        >
                          {title}{" "}
                          <Typography
                            variant="caption"
                            className={css.avail_job_txt}
                            my={1}
                            sx={{ color: isOrange ? "white" : "#f15a24" }}
                          >
                            {second_txt || ""}
                          </Typography>
                        </Typography>
                      </Stack>
                      <img
                        src={wave_img}
                        style={{
                          position: "absolute",
                          bottom: 0,
                          width: "100%",
                          objectFit: "contain",
                          zIndex: 0,
                        }}
                      />
                    </Stack>
                  </Grid>
                );
              }
            )}
          </Grid>

          <Typography variant="caption" className={css.total}>
            Total{" "}
            <Typography variant="caption" className={css.main_heading}>
              Earned
            </Typography>
          </Typography>

          <Grid container spacing={3} alignItems={"stretch"}>
            {totalEarnedData.map(({ wave_img, title, count }) => {
              return (
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Stack
                    className={css.avail_jobs}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{
                      background:
                        "var(--White, linear-gradient(180deg, #FCFCFC 0%, #E8E8E8 100%))",
                      height: "100%",
                    }}
                  >
                    <Stack
                      sx={{ padding: "24px 24px 24px 24px", zIndex: 1 }}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        className={css.earned_count}
                        sx={{ color: themeBlue }}
                        my={1}
                      >
                        <sup className={css.dollar}>$</sup> {count}
                      </Typography>
                      <Typography
                        variant="caption"
                        className={css.avail_job_txt}
                        my={1}
                        sx={{ color: "black !important" }}
                      >
                        {title?.split(" ")[0]}{" "}
                        <Typography
                          variant="caption"
                          className={css.earned_title}
                          my={1}
                        >
                          {title?.split(" ")[1]}{" "}
                        </Typography>
                      </Typography>
                    </Stack>
                    <img
                      src={wave_img}
                      style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        objectFit: "contain",
                        zIndex: 0,
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>

          <Typography variant="caption" className={css.total}>
            Jobs and{" "}
            <Typography variant="caption" className={css.main_heading}>
              Earnings
            </Typography>
          </Typography>

          <BarChart />
        </Stack>
      </Container>
    </Stack>
  );
};

export default DashboardHome;
