import React, { useEffect, useState } from "react";
import {
  Container,
  Stack,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import { common_caption_strings } from "../../utils/language_controls/constant_strings";
import { otp_verify } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { setIdleStatusOTP } from "../../store/slices/user_auth_slice"; // Your dynamic validation schema
import { themeOrange } from "../../utils/colorTheme";
import Logo from "../../assets/logo.png";
import { getValidationSchema } from "./ValidationsFormikYup/ValidationsSchema";

const CheckYourEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { otp_verify_status, user } = useSelector((state) => state.userAuth);
  const isLoading = otp_verify_status === asyncStatus.LOADING;

  const handleSubmit = (values) => {
    const emailSave = localStorage.getItem("email");
    const obj = {
      otp: values.otp,
      email: emailSave,
    };
    dispatch(otp_verify(obj));
  };

  useEffect(() => {
    if (otp_verify_status === asyncStatus.SUCCEEDED) {
      navigate("/create-password");
      dispatch(setIdleStatusOTP());
    }
  }, [otp_verify_status, navigate, dispatch]);

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Container maxWidth="xl">
            <Stack mt={2}>
              <Stack
                justifyContent="flex-start"
                sx={{ width: "100%", padding: 5 }}
                textAlign="left"
                color="black"
              >
                <Stack
                  spacing={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack spacing={1}>
                    <Stack className="auth_Heading">
                      <span className="auth_Heading">
                        Check Your{" "}
                        <span style={{ color: themeOrange }}>Email</span>
                      </span>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <span>
                        We have sent a password recovery instruction to your
                        email.
                      </span>
                    </Stack>
                  </Stack>
                  <Formik
                    initialValues={{ otp: "" }}
                    validationSchema={getValidationSchema("otp")}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, handleChange }) => (
                      <Form>
                        <Stack gap={3} mt={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Input
                                id="otp"
                                onChange={handleChange}
                                placeholder="Enter Code"
                                label="Enter Your OTP"
                                isError={touched.otp && errors.otp}
                              />
                              {touched.otp && errors.otp && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.otp}
                                </Typography>
                              )}
                              <Stack mt={0.5}>
                                <span className="mainPara">
                                  Didn't receive the OTP? Check your spam filter
                                  or try <br />
                                  <span
                                    onClick={() => navigate("/forgot-password")}
                                    style={{
                                      color: themeOrange,
                                      textDecorationLine: "underline",
                                      cursor: "pointer",
                                      lineHeight: 2,
                                    }}
                                  >
                                    another OTP.
                                  </span>
                                </span>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Stack>
                        <Stack mt={2}>
                          <ButtonComp
                            type="submit"
                            label={
                              isLoading ? (
                                <CircularProgress
                                  size={22}
                                  sx={{ color: "white" }}
                                />
                              ) : (
                                common_caption_strings.confirm
                              )
                            }
                            style={{
                              borderRadius: "10px",
                              background:
                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                              boxShadow:
                                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
                            }}
                          />
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            className="bgImageSignup"
            width={1}
          >
            <Stack width="60%" className="transparent-bg" p={4} gap={5}>
              <Stack>
                <img width="100px" src={Logo} alt="Logo" />
              </Stack>
              <Stack>
                <Typography
                  textAlign="start"
                  className="subHeading"
                  color="white"
                >
                  Welcome Done Right Away
                </Typography>
                <Typography className="mainPara" color="white">
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CheckYourEmail;
