import React, { useEffect, useState } from "react";
import {
  Container,
  Stack,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import { themeOrange } from "../../utils/colorTheme";
import Logo from "../../assets/logo.png";
import { common_caption_strings } from "../../utils/language_controls/constant_strings";
import { reset_password } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { setIdleStatus } from "../../store/slices/user_auth_slice";
import { getValidationSchema } from "./ValidationsFormikYup/ValidationsSchema";
// Import your dynamic schema function

const CreatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State for showing/hiding password
  const [showPass, setShowPass] = useState(true);
  const [showConfirmPass, setShowConfirmPass] = useState(true); // State for showing/hiding confirm password
  const { reset_password_status } = useSelector((state) => state.userAuth);
  const isLoading = reset_password_status === asyncStatus.LOADING;

  const _handleSubmit = (values) => {
    const email = localStorage.getItem("email");
    const obj = {
      new_password: values.password,
      email: email,
    };
    dispatch(reset_password(obj));
  };

  useEffect(() => {
    if (reset_password_status === asyncStatus.SUCCEEDED) {
      navigate("/login");
      dispatch(setIdleStatus());
    }
  }, [reset_password_status, navigate, dispatch]);

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Container maxWidth="xl">
            <Stack mt={2}>
              <Stack
                justifyContent="flex-start"
                sx={{ width: "100%", padding: 5 }}
                textAlign="left"
                color="black"
              >
                <Stack
                  spacing={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack spacing={1}>
                    <Stack className="auth_Heading">
                      <span className="auth_Heading">
                        Create a New{" "}
                        <span style={{ color: themeOrange }}>Password</span>
                      </span>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <span>
                        Your new password will be different from the existing &
                        previous ones.
                      </span>
                    </Stack>
                  </Stack>
                  <Formik
                    initialValues={{ password: "", confirm_password: "" }}
                    validationSchema={getValidationSchema("reset_password")}
                    onSubmit={_handleSubmit}
                  >
                    {({ errors, touched, setFieldValue }) => (
                      <Form>
                        <Stack>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Stack position="relative">
                                <Input
                                  id="password"
                                  type={showPass ? "password" : "text"}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "password",
                                      e.target.value.trim()
                                    )
                                  }
                                  placeholder={common_caption_strings.password}
                                  label="Password"
                                  isError={touched.password && errors.password}
                                />
                                {touched.password && errors.password && (
                                  <Typography
                                    sx={{ color: "#d32f2f", fontSize: "12px" }}
                                  >
                                    {errors.password}
                                  </Typography>
                                )}
                                {showPass ? (
                                  <BsFillEyeFill
                                    onClick={() => setShowPass(false)}
                                    style={{
                                      position: "absolute",
                                      top: 20,
                                      right: 10,
                                      cursor: "pointer",
                                    }}
                                    size={18}
                                  />
                                ) : (
                                  <BsFillEyeSlashFill
                                    onClick={() => setShowPass(true)}
                                    style={{
                                      position: "absolute",
                                      top: 20,
                                      right: 10,
                                      cursor: "pointer",
                                    }}
                                    size={18}
                                  />
                                )}
                              </Stack>
                            </Grid>

                            <Grid item xs={12}>
                              <Stack position="relative">
                                <Input
                                  id="confirm_password"
                                  type={showConfirmPass ? "password" : "text"}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "confirm_password",
                                      e.target.value.trim()
                                    )
                                  }
                                  placeholder={
                                    common_caption_strings.confirm_Password
                                  }
                                  label="Confirm Password"
                                  isError={
                                    touched.confirm_password &&
                                    errors.confirm_password
                                  }
                                />
                                {touched.confirm_password &&
                                  errors.confirm_password && (
                                    <Typography
                                      sx={{
                                        color: "#d32f2f",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {errors.confirm_password}
                                    </Typography>
                                  )}
                                {showConfirmPass ? (
                                  <BsFillEyeFill
                                    onClick={() => setShowConfirmPass(false)}
                                    style={{
                                      position: "absolute",
                                      top: 20,
                                      right: 10,
                                      cursor: "pointer",
                                    }}
                                    size={18}
                                  />
                                ) : (
                                  <BsFillEyeSlashFill
                                    onClick={() => setShowConfirmPass(true)}
                                    style={{
                                      position: "absolute",
                                      top: 20,
                                      right: 10,
                                      cursor: "pointer",
                                    }}
                                    size={18}
                                  />
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Stack>
                        <Stack mt={2}>
                          <ButtonComp
                            type="submit"
                            label={
                              isLoading ? (
                                <CircularProgress
                                  size={22}
                                  sx={{ color: "white" }}
                                />
                              ) : (
                                common_caption_strings.confirm
                              )
                            }
                            style={{
                              borderRadius: "10px",
                              background:
                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                              boxShadow:
                                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
                            }}
                          />
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            className="bgImageSignup"
            width={1}
          >
            <Stack width="60%" className="transparent-bg" p={4} gap={5}>
              <Stack>
                <img width="100px" src={Logo} alt="Logo" />
              </Stack>
              <Stack>
                <Typography
                  textAlign="start"
                  className="subHeading"
                  color="white"
                >
                 Welcome Done Right Away
                </Typography>
                <Typography className="mainPara" color="white">
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CreatePassword;
