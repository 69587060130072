// import React, { useEffect, useRef, useState } from "react";
// import { Avatar, IconButton, Stack, Tooltip, Typography } from "@mui/material";
// import "./ProfileMenu.css"; // Import your custom CSS for styling if needed
// import { useNavigate } from "react-router-dom";
// import { success_toast_message } from "../../../utils/toast_message";
// import { useSelector } from "react-redux";
// import { imgUrl } from "../../../utils/imageUrl";
// import CustomModal from "../CustomModal/CustomModal";
// import { RxCross2 } from "react-icons/rx";
// import ButtonComp from "./../ButtonComp";

// const ProfileMenu = () => {
//   const navigate = useNavigate();
//   const [open, setOpen] = useState(false);
//   const [openModal, setOpenModal] = useState(false);
//   const menuRef = useRef(null); // Reference to the menu

//   const handleToggle = () => {
//     setOpen(!open);
//   };
//   const { user, get_user_profile_data } = useSelector(
//     (state) => state.userAuth
//   );

//   const isEmailVerified = user?.email_verified_at;

//   const _handleClose = () => {
//     setOpen(false);
//     navigate("/");
//     success_toast_message("Logout Successfully");
//     window.location.reload();
//     localStorage.removeItem("auth");
//   };

//   // Close menu when clicking outside of it
//   useEffect(() => {
//     const _handleClickOutside = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         setOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", _handleClickOutside);

//     return () => {
//       document.removeEventListener("mousedown", _handleClickOutside);
//     };
//   }, []);

//   return (
//     <div className="custom-menu" ref={menuRef}>
//       <Tooltip title="Account settings">
//         <IconButton onClick={handleToggle}>
//           <Avatar src={`${imgUrl}${user?.avatar}`}>
//             {user?.first_name?.slice(0, 1)}
//           </Avatar>
//         </IconButton>
//       </Tooltip>

//       {open && (
//         <div className="menu-items">
//           <button
//             className="menu-item"
//             onClick={
//               () => {
//                 !isEmailVerified
//                   ? navigate("/vendor-check-email")
//                   : user?.user_role === 2 && !user?.stripe_id
//                   ? navigate("/vendor-profile-verification")
//                   : navigate("/dashboard/");

//                   setOpen(false)
//               }
//               // isEmailVerified && user?.user_role === 2 && !user?.stripe_id
//               // ? navigate("/vendor-profile-verification") : navigate("/dashboard/")
//               //   :  navigate("/dashboard/")
//               //   : navigate("/vendor-check-email")
//             }
//           >
//             Dashboard
//           </button>
//           <button
//             className="menu-item"
//             onClick={() => setOpenModal(!openModal)}
//           >
//             Sign Out
//           </button>
//         </div>
//       )}

//       {/* >>>>>>>>> Logout Warning Modal */}
//       <CustomModal
//         isOpen={openModal}
//         setIsOpen={() => setOpenModal(!openModal)}
//       >
//         <Stack
//           sx={{
//             overflow: "auto",
//             backgroundColor: "white",
//             borderRadius: "10px",
//           }}
//           alignItems={"center"}
//           p={1}
//         >
//           <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
//             <IconButton onClick={() => setOpenModal(!openModal)}>
//               <RxCross2 size={20} sx={{ color: "black" }} />
//             </IconButton>
//           </Stack>

//           <Stack gap={2} px={1} sx={{ width: "100%" }}>
//             <Typography
//               sx={{
//                 // textAlign: "",
//                 color: "black",
//                 fontSize: "16px",
//                 fontWeight: "500",
//                 lineHeight: "24px",
//               }}
//             >
//               Are you sure you want to logout?
//             </Typography>

//             <Stack
//               gap={2}
//               direction={"row"}
//               alignItems={"center"}
//               justifyContent={"flex-end"}
//             >
//               <ButtonComp
//                 onClick={_handleClose}
//                 label={"Yes"}
//                 style={{ width: "auto", height: "auto", borderRadius: "15px" }}
//               />
//               <ButtonComp
//                 onClick={() => setOpenModal(!openModal)}
//                 label={"No"}
//                 style={{ width: "auto", height: "auto", borderRadius: "15px" }}
//               />
//             </Stack>
//           </Stack>
//         </Stack>
//       </CustomModal>
//     </div>
//   );
// };

// export default ProfileMenu;

import React, { useEffect, useRef, useState } from "react";
import { Avatar, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import "./ProfileMenu.css"; // Import your custom CSS for styling if needed
import { useNavigate } from "react-router-dom";
import { success_toast_message } from "../../../utils/toast_message";
import { useSelector } from "react-redux";
import { imgUrl } from "../../../utils/imageUrl";
import CustomModal from "../CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import ButtonComp from "./../ButtonComp";

const ProfileMenu = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const menuRef = useRef(null); // Reference to the menu

  const { user } = useSelector((state) => state.userAuth);

  const isEmailVerified = user?.email_verified_at;

  const handleToggle = () => {
    setOpen(!open);
  };

  const _handleClose = () => {
    setOpen(false);
    navigate("/");
    success_toast_message("Logout Successfully");
    window.location.reload();
    localStorage.removeItem("auth");
    localStorage.clear();
  };

  // Close menu when clicking outside of it
  useEffect(() => {
    const _handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", _handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
    };
  }, []);

  // Close the menu on any navigation action
  const handleNavigation = (path) => {
    navigate(path);
    setOpen(false); // Close the profile menu after navigating
  };

  return (
    <div className="custom-menu" ref={menuRef}>
      {/* <Tooltip title="Account settings">
       
      </Tooltip> */}
      <IconButton onClick={handleToggle}>
        <Avatar src={`${imgUrl}${user?.avatar}`}>
          {user?.first_name?.slice(0, 1)}
        </Avatar>
      </IconButton>

      {open && (
        <div className="menu-items">
          <button
            className="menu-item"
            onClick={() => {
              if (!isEmailVerified) {
                handleNavigation("/vendor-check-email");
                // }
                //  else if (user?.user_role === 2 && !user?.stripe_id) {
                // handleNavigation("/vendor-profile-verification");
              } else {
                handleNavigation("/dashboard/");
              }
            }}
          >
            Dashboard
          </button>

          <button
            className="menu-item"
            onClick={() => setOpenModal(!openModal)}
          >
            Sign Out
          </button>
        </div>
      )}

      {/* >>>>>>>>> Logout Warning Modal */}
      <CustomModal
        isOpen={openModal}
        setIsOpen={() => setOpenModal(!openModal)}
      >
        <Stack
          sx={{
            overflow: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
          alignItems={"center"}
          p={1}
        >
          <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
            <IconButton onClick={() => setOpenModal(!openModal)}>
              <RxCross2 size={20} sx={{ color: "black" }} />
            </IconButton>
          </Stack>

          <Stack gap={2} px={1} sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              Are you sure you want to logout?
            </Typography>

            <Stack
              gap={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <ButtonComp
                onClick={_handleClose}
                label={"Yes"}
                style={{ width: "auto", height: "auto", borderRadius: "15px" }}
              />
              <ButtonComp
                onClick={() => setOpenModal(!openModal)}
                label={"No"}
                style={{ width: "auto", height: "auto", borderRadius: "15px" }}
              />
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>
    </div>
  );
};

export default ProfileMenu;
