import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderColor: "red",
    },
  },
};

const MultiSelectComp = ({
  label,
  options = [],
  selectedValues = [],
  onSelectionChange,
  isError,
  style,
  label_style,
  borderRadius,
  input_label_style,
  disabled = false,
  isMultiSelect = true,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // const selectedIds =
    //   typeof value === "string" ? value.split(",").map(Number) : value;
    const selectedIds = isMultiSelect
      ? typeof value === "string"
        ? value.split(",").map(Number)
        : value
      : value; // In single select, just set the value
    onSelectionChange(selectedIds);
  };

  const theme1 = createTheme({
    palette: {
      primary: {
        main: "rgb(241, 90, 36)", // Color 1
      },
    },
  });

  return (
    <ThemeProvider theme={theme1}>
      <FormControl error={isError} sx={{ width: 1 }} disabled={disabled}>
        <InputLabel
          id="multi-select-checkbox-label"
          sx={{
            color: disabled ? "gray" : "inherit",
            ...label_style,
            "&.MuiInputLabel-shrink": {
              ...input_label_style,
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="multi-select-checkbox-label"
          id="multi-select-checkbox"
          multiple={isMultiSelect}
          value={selectedValues}
          onChange={handleChange}
          input={
            <OutlinedInput
              label={label}
              sx={{
                fontSize: "18px",
                color: "gray",
                outline: "none",
                borderRadius: borderRadius || "10px", // Apply borderRadius here
                ...style,
              }}
            />
          }
          // renderValue={(selected) =>
          //   selected
          //     .map((id) => options.find((option) => option.id === id)?.name)
          //     .filter((name) => name) // This ensures we don't join undefined values
          //     .join(", ")
          // }
          renderValue={(selected) =>
            isMultiSelect
              ? selected
                  .map((id) => options.find((option) => option.id === id)?.name)
                  .filter((name) => name) // This ensures we don't join undefined values
                  .join(", ")
              : options.find((option) => option.id === selected)?.name || ""
          }
          MenuProps={MenuProps}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {isMultiSelect && (
                <Checkbox
                  disabled={disabled}
                  checked={selectedValues.indexOf(option.id) > -1}
                />
              )}
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default MultiSelectComp;
