import React, { useEffect, useState } from "react";
import {
  Container,
  Stack,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import CustomPhoneInput from "../../component/common/CustomPhoneInput/CustomPhoneInput";
import MultiSelectComp from "../../component/common/selectComp/MultiSelectComp";
import {
  get_signup_services,
  signup_vendor_auth,
} from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { setIdleRegisterStatus } from "../../store/slices/user_auth_slice";
import Logo from "../../assets/logo.png";
import "./auth.css";
import { common_caption_strings } from "../../utils/language_controls/constant_strings";
import { getValidationSchema } from "./ValidationsFormikYup/ValidationsSchema";
import { themeOrange } from "../../utils/colorTheme";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user_register_status,
    get_services_for_signup_data,
    vendor_register_status,
    user,
  } = useSelector((state) => state.userAuth);

  const [showPass, setShowPass] = useState(true);
  const [showPass2, setShowPass2] = useState(true);

  const isLoading = user_register_status === asyncStatus.LOADING;

  useEffect(() => {
    if (user_register_status === asyncStatus.SUCCEEDED) {
      navigate("/");
      dispatch(setIdleRegisterStatus());
    }
  }, [user_register_status, navigate, dispatch]);

  useEffect(() => {
    dispatch(get_signup_services({ services: "yes", city: "yes" }));
  }, [dispatch]);

  useEffect(() => {
    if (
      vendor_register_status === asyncStatus.SUCCEEDED &&
      !user?.email_verified_at
    ) {
      navigate("/vendor-check-email");
    }
  }, [vendor_register_status, user, navigate]);

  const _handleSubmit = (values) => {
    const { selectedServicesIds, selectedServicesAreaIds, ...rest } = values;
    const payload = {
      ...rest,
      services: JSON.stringify(selectedServicesIds),
      cities: JSON.stringify(selectedServicesAreaIds),
    };
    dispatch(signup_vendor_auth(payload));
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    selectedServicesIds: [],
    selectedServicesAreaIds: [],
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Container maxWidth="xl">
            <Stack mt={8}>
              <Stack
                justifyContent="flex-start"
                sx={{ padding: 5 }}
                textAlign="left"
                color="black"
                gap={3}
              >
                <Stack spacing={1}>
                  <Stack className="auth_Heading">
                    <span className="auth_Heading">
                      Welcome To{" "}
                      <span style={{ color: themeOrange }}>Done</span>
                    </span>
                  </Stack>
                  <Stack className="auth_sub_Heading">
                    <span>Create a free account with Done.</span>
                  </Stack>
                </Stack>


                <Formik
                  initialValues={initialValues}
                  validationSchema={getValidationSchema("signup")}
                  onSubmit={_handleSubmit}
                >
                  {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                      <Stack gap={3} mb={2}>
                        <Grid container spacing={4}>
                          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Input
                              id="first_name"
                              onChange={(e) =>
                                setFieldValue(
                                  "first_name",
                                  e.target.value.trim()
                                )
                              }
                              placeholder="First Name"
                              label="First Name"
                              isError={touched.first_name && errors.first_name}
                            />
                            {touched.first_name && errors.first_name && (
                              <Typography
                                sx={{ color: "#d32f2f", fontSize: "12px" }}
                              >
                                {errors.first_name}
                              </Typography>
                            )}
                          </Grid>

                          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Input
                              id="last_name"
                              onChange={(e) =>
                                setFieldValue(
                                  "last_name",
                                  e.target.value.trim()
                                )
                              }
                              placeholder="Last Name"
                              label="Last Name"
                              isError={touched.last_name && errors.last_name}
                            />
                            {touched.last_name && errors.last_name && (
                              <Typography
                                sx={{ color: "#d32f2f", fontSize: "12px" }}
                              >
                                {errors.last_name}
                              </Typography>
                            )}
                          </Grid>

                          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Input
                              id="email"
                              type="text"
                              onChange={(e) =>
                                setFieldValue("email", e.target.value.trim())
                              }
                              placeholder="Email"
                              label="Email"
                              isError={touched.email && errors.email}
                            />
                            {touched.email && errors.email && (
                              <Typography
                                sx={{ color: "#d32f2f", fontSize: "12px" }}
                              >
                                {errors.email}
                              </Typography>
                            )}
                          </Grid>

                          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <CustomPhoneInput
                              value={values.phone}
                              onChange={(e) => setFieldValue("phone", e)}
                              error={
                                touched.phone && errors.phone
                                  ? errors.phone
                                  : ""
                              }
                            />
                          </Grid>

                          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Stack position="relative">
                              <Input
                                id="password"
                                type={showPass ? "password" : "text"}
                                onChange={(e) =>
                                  setFieldValue(
                                    "password",
                                    e.target.value.trim()
                                  )
                                }
                                placeholder="Password"
                                label="Password"
                                isError={touched.password && errors.password}
                              />
                              {showPass ? (
                                <BsFillEyeFill
                                  onClick={() => setShowPass(false)}
                                  style={{
                                    position: "absolute",
                                    top: 20,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  size={18}
                                />
                              ) : (
                                <BsFillEyeSlashFill
                                  onClick={() => setShowPass(true)}
                                  style={{
                                    position: "absolute",
                                    top: 20,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  size={18}
                                />
                              )}
                              {touched.password && errors.password && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.password}
                                </Typography>
                              )}
                            </Stack>
                          </Grid>

                          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Stack position="relative">
                              <Input
                                id="confirmPassword"
                                type={showPass2 ? "password" : "text"}
                                onChange={(e) =>
                                  setFieldValue(
                                    "confirmPassword",
                                    e.target.value.trim()
                                  )
                                }
                                placeholder="Confirm Password"
                                label="Confirm Password"
                                isError={
                                  touched.confirmPassword &&
                                  errors.confirmPassword
                                }
                              />
                              {showPass2 ? (
                                <BsFillEyeFill
                                  onClick={() => setShowPass2(false)}
                                  style={{
                                    position: "absolute",
                                    top: 20,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  size={18}
                                />
                              ) : (
                                <BsFillEyeSlashFill
                                  onClick={() => setShowPass2(true)}
                                  style={{
                                    position: "absolute",
                                    top: 20,
                                    right: 10,
                                    cursor: "pointer",
                                  }}
                                  size={18}
                                />
                              )}
                              {touched.confirmPassword &&
                                errors.confirmPassword && (
                                  <Typography
                                    sx={{ color: "#d32f2f", fontSize: "12px" }}
                                  >
                                    {errors.confirmPassword}
                                  </Typography>
                                )}
                            </Stack>
                          </Grid>

                          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <MultiSelectComp
                              label="Services"
                              options={get_services_for_signup_data?.services}
                              selectedValues={values.selectedServicesIds}
                              onSelectionChange={(selected) =>
                                setFieldValue("selectedServicesIds", selected)
                              }
                              isError={
                                touched.selectedServicesIds &&
                                errors.selectedServicesIds
                              }
                            />
                            {touched.selectedServicesIds &&
                              errors.selectedServicesIds && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.selectedServicesIds}
                                </Typography>
                              )}
                          </Grid>

                          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <MultiSelectComp
                              label="Service Area"
                              options={get_services_for_signup_data?.cities}
                              selectedValues={values.selectedServicesAreaIds}
                              onSelectionChange={(selected) =>
                                setFieldValue(
                                  "selectedServicesAreaIds",
                                  selected
                                )
                              }
                              isError={
                                touched.selectedServicesAreaIds &&
                                errors.selectedServicesAreaIds
                              }
                            />
                            {touched.selectedServicesAreaIds &&
                              errors.selectedServicesAreaIds && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.selectedServicesAreaIds}
                                </Typography>
                              )}
                          </Grid>
                        </Grid>

                        <ButtonComp
                          type="submit"
                          label={
                            isLoading ? (
                              <CircularProgress
                                size={22}
                                sx={{ color: "white" }}
                              />
                            ) : (
                              common_caption_strings.Signup
                            )
                          }
                          style={{
                            borderRadius: "10px",
                            background:
                              "linear-gradient(180deg, #F15A24 0%, #C53F10 100%)",
                          }}
                          disabled={isLoading}
                        />
                      </Stack>
                    </Form>
                  )}
                </Formik>

                <Stack mt={1} direction="row" justifyContent="center">
                  <Typography color="#9F9F9F">
                    Already have an account?
                  </Typography>
                  <Typography
                    onClick={() => navigate("/login")}
                    sx={{ color: "#F15A24", mx: "2px", cursor: "pointer" }}
                  >
                    Login
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            className="bgImageSignup"
            width={1}
          >
            <Stack width="60%" className="transparent-bg" p={4} gap={5}>
              <img src={Logo} alt="Logo" width="100px" />
              <Typography color="white" variant="h5">
                Sign Up For Done Right Pro
              </Typography>
              <Typography color="white" className="mainPara">
                Welcome to our platform, where vendors and users connect
                effortlessly. Discover a wide range of services, communicate
                directly with providers, and get personalized solutions to meet
                your specific needs. Start exploring now!
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SignUp;
