import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import clock from "../../assets/clock.png";
import fixing from "../../assets/fixing.png";
import idea from "../../assets/idea.png";
import ticket from "../../assets/ticket.png";
import "./thirdSection.css";
import { themeGray, themeText } from "../../utils/colorTheme";

const ThirdSection = () => {
  const uniqueCardData = [
    {
      title: "Rapid response",
      description:
        "Swiftly addressing inquiries or issues with immediate and effective solutions. Our team is always ready to handle your concerns promptly and efficiently. Whether it's a technical issue or an urgent request, we provide quick resolutions to minimize downtime and ensure smooth operations with outstanding service.",
      img: clock,
    },
    {
      title: "Pre-set pay rates",
      description:
        "Established compensation amounts for specific roles or tasks determined in advance. Our transparent pay structure ensures fairness and clarity, allowing you to know your earnings upfront. This system promotes trust and satisfaction by providing consistent and predictable pay rates, ensuring everyone is compensated appropriately for their work.",
      img: fixing,
    },
    {
      title: "Qualified staff",
      description:
        "Employees possessing the necessary skills, qualifications, and experience for their roles. Our team is comprised of highly trained professionals who meet rigorous standards, ensuring top-notch service and expertise. We prioritize continuous development and training to maintain the highest level of competence and excellence in all our services.",
      img: idea,
    },
    {
      title: "Punctual delivery",
      description:
        "Ensuring products or services are delivered on schedule, meeting agreed-upon deadlines. We prioritize timely delivery to meet your expectations, maintaining reliability and trust. Our commitment to punctuality ensures smooth operations and customer satisfaction, with every project completed within the specified timeframe.",
      img: ticket,
    },
  ];

  return (
    <Container maxWidth="xl" sx={{mt:7,mb:7}}>
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Typography
          color={themeText}
          sx={{
            fontSize: {
              xs: "25px",
              sm: "36px",
              md: "36px",
              lg: "36px",
              xl: "36px",
            },
            textAlign: "center",
          }}
          fontWeight={"700"}
        >
          What Makes Us <span style={{ color: "#f15a24" }}>Unique ?</span>
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{
            width: {
              xs: "100%",
              sm: "90%",
              md: "70%",
              lg: "70%",
              xl: "70%",
            },
            fontSize: {
              xs: "15px",
              sm: "16px",
              md: "16px",
              lg: "16px",
              xl: "16px",
            },
            padding: "10px 10px 0px 10px",
          }}
          color={"#B5B5B5"}
        >
          At Done, we stand out by offering unparalleled quality and
          reliability. Our dedicated professionals ensure every job is completed
          with precision and care, delivering results that exceed expectations.
          We pride ourselves on our commitment to excellence and customer
          satisfaction, making us your go-to choice for all your needs.
        </Typography>
      </Stack>
      <Grid container spacing={4} alignItems={"stretch"} justifyContent={"center"}>
        {uniqueCardData?.map(({ title, description, img }) => {
          return (
            <Grid item xl={6} lg={6} md={5} sm={5.5} xs={12}>
              <Stack className="cardMain" p={3} mt={5} sx={{ height: "90%" }}>
                <Stack>
                  <img className="image-class-for-all" src={img ?? ""} />
                </Stack>
                <Stack>
                  <Typography
                    mt={5}
                    fontWeight={800}
                    className="bottom-card-textHeading"
                    sx={{
                      fontSize: {
                        xs: "17",
                        sm: "22px",
                        md: "22px",
                        lg: "22px",
                        xl: "22px",
                      },
                    }}
                  >
                    {title ?? ""}
                  </Typography>
                  <Typography
                    fontWeight={600}
                    mt={1}
                     className="bottom-card-textDisc"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        lg: "14px",
                        md: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    {description ?? ""}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default ThirdSection;
