import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { asyncStatus, save_tokens_constant } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {
  check_auth,
  contactUsAsync,
  edit_profile,
  email_verify_by_OTP_async,
  forgot_password,
  get_signup_services,
  get_user_profile,
  get_vendor,
  login_service_auth,
  otp_verify,
  resend_OTP_async,
  reset_password,
  signup_service_auth,
  signup_vendor_auth,
  vendorStripeStartedAsync,
  verificationSuccessAsync,
} from "../../services/authentication";
import {
  getVendorStatusAsync,
  subscriptionAsync,
} from "../../services/SubscriptionService";

const initialState = {
  // status
  check_auth_status: asyncStatus.IDLE,
  user_register_status: asyncStatus.IDLE,
  vendor_register_status: asyncStatus.IDLE,
  user_login_status: asyncStatus.IDLE,
  user_logout_status: asyncStatus.IDLE,
  user_profile_status: asyncStatus.IDLE,
  get_services_for_signup_status: asyncStatus.IDLE,
  get_user_profile_status: asyncStatus.IDLE,
  forgot_password_status: asyncStatus.IDLE,
  otp_verify_status: asyncStatus.IDLE,
  reset_password_status: asyncStatus.IDLE,
  edit_profile_status: asyncStatus.IDLE,
  get_vendor_status: asyncStatus.IDLE,

  // data
  userAuth: false,
  user: null,
  get_services_for_signup_data: null,
  get_user_profile_data: null,
  forgot_password_data: null,
  otp_verify_data: null,
  reset_password_data: null,
  edit_profile_data: null,
  get_vendor_data: null,

  vendor_rating: 0,

  authTokens: null,
  user_profile: null,
  profile_status: 0,
  role: "",

  // error
  check_auth_error: null,
  user_register_error: null,
  vendor_register_error: null,
  user_login_error: null,
  user_logout_error: null,
  user_profile_error: null,
  get_services_for_signup_error: null,
  forgot_password_error: null,
  otp_verify_error: null,
  reset_password_error: null,
  edit_profile_error: null,
  get_vendor_error: null,

  edit_profile_status: asyncStatus.IDLE,
  edit_user_profile: null,
  edit_user_profile_error: null,
  get_user_profile_error: null,

  // for only booking value change
  pageValue: "1",
  userRole: null,
  // for only booking value change

  contact_us_status: asyncStatus.IDLE,
  contact_us_data: null,
  contact_us_error: null,

  vendor_stripe_started_status: asyncStatus.IDLE,
  vendor_stripe_started_data: null,
  vendor_stripe_started_error: null,

  email_verify_by_otp_status: asyncStatus.IDLE,
  email_verify_by_otp_data: null,
  email_verify_by_otp_error: null,

  resend_otp_status: asyncStatus.IDLE,
  resend_otp_data: null,
  resend_otp_error: null,

  verification_success_status: asyncStatus.IDLE,
  verification_success_data: null,
  verification_success_error: null,
};

const user_auth_slice = createSlice({
  name: "userAuth",
  initialState,

  reducers: {
    setIdleLoginStatus(state) {
      state.login_status = asyncStatus.IDLE;
    },
    setIdleStatus(state) {
      state.user_logout_status = asyncStatus.IDLE;
    },
    setIdleStatusForgot(state) {
      state.forgot_password_status = asyncStatus.IDLE;
    },
    setIdleStatusOTP(state) {
      state.otp_verify_status = asyncStatus.IDLE;
    },
    setIdleStatusnemPassword(state) {
      state.reset_password_status = asyncStatus.IDLE;
    },
    setIdleRegisterStatus(state) {
      state.user_register_status = asyncStatus.IDLE;
    },
    setIdleVendorRegisterStatus(state) {
      state.vendor_register_status = asyncStatus.IDLE;
    },
    setUserProfileUpdateStatus(state) {
      state.edit_profile_status = asyncStatus.IDLE;
    },
    setUserRole(state, { payload }) {
      state.role = payload;
    },
    // for only booking value change
    setpageValue(state, { payload }) {
      state.pageValue = payload;
    },
    setAuthState(state, { payload }) {
      state.userAuth = payload;
      state.check_auth_status = asyncStatus.SUCCEEDED;
    },
    setVendorStripeStatus(state, { payload }) {
      state.vendor_stripe_started_status = asyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login_service_auth.pending, (state, action) => {
      state.user_login_status = asyncStatus.LOADING;
    });

    builder.addCase(login_service_auth.fulfilled, (state, { payload }) => {
      state.authTokens = payload?.data?.token;
      state.user_login_status = asyncStatus.SUCCEEDED;
      state.user = payload?.data?.user;
      console.log("payload", payload);
      state.userAuth = true;
      success_toast_message("Login Successfully");
      state.user_login_error = null;
      // localStorage.setItem(save_tokens_constant.AUTH, payload?.data?.token);
      localStorage.setItem(save_tokens_constant.AUTH, payload?.data?.token);
      const userRoleNumber = parseInt(payload?.data?.role, 10); // 10 is for base 10

      // console.log("userRoleNumber",userRoleNumber)
      // Save to localStorage as string
      localStorage.setItem("user_role", userRoleNumber?.toString());
    });

    builder.addCase(login_service_auth.rejected, (state, action) => {
      state.user_login_status = asyncStatus.ERROR;
      state.user_login_error = action.error;
      error_toast_message(action.error.message);
    });

    // CHECK_AUTH ===============>

    builder.addCase(check_auth.pending, (state, action) => {
      state.check_auth_status = asyncStatus.LOADING;
    });

    builder.addCase(check_auth.fulfilled, (state, { payload }) => {
      state.check_auth_status = asyncStatus.SUCCEEDED;
      // if (payload.user == false) {
      //   localStorage.removeItem(save_tokens_constant.AUTH);
      //   return false;
      // }
      state.user = payload.user;
      state.userRole = payload?.user?.user_role;
      const userRoleNumber = parseInt(payload?.user?.user_role, 10); // 10 is for base 10
      localStorage.setItem("user_role", userRoleNumber?.toString());
      state.userAuth = true;

      state.check_auth_error = null;
    });

    builder.addCase(check_auth.rejected, (state, action) => {
      state.check_auth_status = asyncStatus.ERROR;
      state.check_auth_error = action.error;
      error_toast_message(action.error.message);
    });

    //  signup =========>>>>>>>>>>>
    builder.addCase(signup_service_auth.pending, (state, action) => {
      state.user_register_status = asyncStatus.LOADING;
    });

    builder.addCase(signup_service_auth.fulfilled, (state, { payload }) => {
      state.authTokens = payload.data.token;
      state.user_register_status = asyncStatus.SUCCEEDED;
      state.user = payload?.data?.user;
      state.userAuth = true;
      success_toast_message("Signup Successfully");
      localStorage.setItem(save_tokens_constant.AUTH, payload.data.token);
      const userRoleNumber = parseInt(payload.data.role, 10);
      localStorage.setItem("user_role", userRoleNumber?.toString());
    });

    builder.addCase(signup_service_auth.rejected, (state, action) => {
      state.user_register_status = asyncStatus.ERROR;
      state.user_register_error = action.error;
      error_toast_message(action.error.message);
    });

    //  SIGNUP_VENDOR =========>>>>>>>>>>>
    builder.addCase(signup_vendor_auth.pending, (state, action) => {
      state.vendor_register_status = asyncStatus.LOADING;
    });

    builder.addCase(signup_vendor_auth.fulfilled, (state, { payload }) => {
      state.authTokens = payload.token;
      state.vendor_register_status = asyncStatus.SUCCEEDED;
      state.user = payload.user;
      state.userAuth = true;
      success_toast_message("Vendor Signup Successfully");
      state.vendor_register_error = null;
      localStorage.setItem(save_tokens_constant.AUTH, payload.token);
      const userRoleNumber = parseInt(payload?.user?.user_role, 10); // 10 is for base 10
      localStorage.setItem("user_role", userRoleNumber?.toString());
    });

    builder.addCase(signup_vendor_auth.rejected, (state, action) => {
      state.vendor_register_status = asyncStatus.ERROR;
      state.vendor_register_error = action.error;
      error_toast_message(action.error.message);
    });

    //  SIGNUP_SERVICES   =========>>>>>>>>>>>
    builder.addCase(get_signup_services.pending, (state, action) => {
      state.get_services_for_signup_status = asyncStatus.LOADING;
    });

    builder.addCase(get_signup_services.fulfilled, (state, { payload }) => {
      state.get_services_for_signup_status = asyncStatus.SUCCEEDED;
      state.get_services_for_signup_data = payload.data;
      state.get_services_for_signup_error = null;
    });

    builder.addCase(get_signup_services.rejected, (state, action) => {
      state.get_services_for_signup_status = asyncStatus.ERROR;
      state.get_services_for_signup_error = action.error;
      error_toast_message(action.error.message);
    });

    //  GET_USER_PROFILE =========>>>>>>>>>>>
    builder.addCase(get_user_profile.pending, (state, action) => {
      state.get_user_profile_status = asyncStatus.LOADING;
    });

    builder.addCase(get_user_profile.fulfilled, (state, { payload }) => {
      state.get_user_profile_status = asyncStatus.SUCCEEDED;
      state.get_user_profile_data = payload.user;
      state.user.phone = payload?.user.phone;
      state.vendor_rating = payload.ratings;
      state.get_user_profile_error = null;
      state.user = payload.user;
    });

    builder.addCase(get_user_profile.rejected, (state, action) => {
      state.get_user_profile_status = asyncStatus.ERROR;
      state.get_user_profile_error = action.error;
      error_toast_message(action.error.message);
    });

    //  FORGOT_PASSWORD =========>>>>>>>>>>>
    builder.addCase(forgot_password.pending, (state, action) => {
      state.forgot_password_status = asyncStatus.LOADING;
    });

    builder.addCase(forgot_password.fulfilled, (state, { payload }) => {
      state.forgot_password_status = asyncStatus.SUCCEEDED;
      state.forgot_password_data = payload.data;
      state.forgot_password_error = null;
      success_toast_message(payload.message);
    });

    builder.addCase(forgot_password.rejected, (state, action) => {
      state.forgot_password_status = asyncStatus.ERROR;
      state.forgot_password_error = action.error;
      error_toast_message(action.error.message);
    });

    //  OTP_VERIFY =========>>>>>>>>>>>
    builder.addCase(otp_verify.pending, (state, action) => {
      state.otp_verify_status = asyncStatus.LOADING;
    });

    builder.addCase(otp_verify.fulfilled, (state, { payload }) => {
      state.otp_verify_status = asyncStatus.SUCCEEDED;
      state.otp_verify_data = payload.data;
      state.otp_verify_error = null;
      success_toast_message(payload.message);
    });

    builder.addCase(otp_verify.rejected, (state, action) => {
      state.otp_verify_status = asyncStatus.ERROR;
      state.otp_verify_error = action.error;
      error_toast_message(action.error.message);
    });

    //  RESET_PASSWORD =========>>>>>>>>>>>
    builder.addCase(reset_password.pending, (state, action) => {
      state.reset_password_status = asyncStatus.LOADING;
    });

    builder.addCase(reset_password.fulfilled, (state, { payload }) => {
      state.reset_password_status = asyncStatus.SUCCEEDED;
      state.reset_password_data = payload.data;
      state.reset_password_error = null;
      success_toast_message(payload.message);
    });

    builder.addCase(reset_password.rejected, (state, action) => {
      state.reset_password_status = asyncStatus.ERROR;
      state.reset_password_error = action.error;
      error_toast_message(action.error.message);
    });

    //  EDIT_PROFILE =========>>>>>>>>>>>
    builder.addCase(edit_profile.pending, (state, action) => {
      state.edit_profile_status = asyncStatus.LOADING;
    });

    builder.addCase(edit_profile.fulfilled, (state, { payload }) => {
      state.edit_profile_status = asyncStatus.SUCCEEDED;
      state.edit_profile_data = payload.data;
      state.edit_profile_error = null;
      if (state.user && state.user.avatar) {
        state.user.avatar = payload.user.avatar;
      } else {
        state.user = { ...state.user, ...payload.user };
      }

      success_toast_message(payload.message);
    });

    builder.addCase(edit_profile.rejected, (state, action) => {
      state.edit_profile_status = asyncStatus.ERROR;
      state.edit_profile_error = action.error;
      error_toast_message(action.error.message);
    });

    //  GET_VENDOR =========>>>>>>>>>>>
    builder.addCase(get_vendor.pending, (state, action) => {
      state.get_vendor_status = asyncStatus.LOADING;
    });

    builder.addCase(get_vendor.fulfilled, (state, { payload }) => {
      state.get_vendor_status = asyncStatus.SUCCEEDED;
      state.get_vendor_data = payload.data;
      state.get_vendor_error = null;
      success_toast_message(payload.message);
    });

    builder.addCase(get_vendor.rejected, (state, action) => {
      state.get_vendor_status = asyncStatus.ERROR;
      state.get_vendor_error = action.error;
      error_toast_message(action.error.message);
    });

    //  CONTACT US ASYNC =========>>>>>>>>>>>
    builder.addCase(contactUsAsync.pending, (state, action) => {
      state.contact_us_status = asyncStatus.LOADING;
    });

    builder.addCase(contactUsAsync.fulfilled, (state, { payload }) => {
      state.contact_us_status = asyncStatus.SUCCEEDED;
      state.contact_us_data = payload;
      success_toast_message(payload.message);
    });

    builder.addCase(contactUsAsync.rejected, (state, action) => {
      state.contact_us_status = asyncStatus.ERROR;
      state.contact_us_error = action.error;
      error_toast_message(action.error.message);
    });

    //  VENDOR STRIPE ASYNC =========>>>>>>>>>>>
    builder.addCase(vendorStripeStartedAsync.pending, (state, action) => {
      state.vendor_stripe_started_status = asyncStatus.LOADING;
    });

    builder.addCase(
      vendorStripeStartedAsync.fulfilled,
      (state, { payload }) => {
        state.vendor_stripe_started_status = asyncStatus.SUCCEEDED;
        state.vendor_stripe_started_data = payload;
        state.user = payload.user;
        success_toast_message(payload.message);
      }
    );
    //  SUBSCRIPTION_ID_REPLACEMENT =========>>>>>>>>>>>
    builder.addCase(vendorStripeStartedAsync.rejected, (state, action) => {
      state.vendor_stripe_started_status = asyncStatus.ERROR;
      state.vendor_stripe_started_error = action.error;
      error_toast_message(action?.error?.message);
    });

    builder.addCase(subscriptionAsync.fulfilled, (state, { payload }) => {
      state.user.subscription_id = payload?.subscription?.id;
    });

    //  OTP_VERIFY =========>>>>>>>>>>>
    builder.addCase(email_verify_by_OTP_async.pending, (state, action) => {
      state.email_verify_by_otp_status = asyncStatus.LOADING;
    });

    builder.addCase(
      email_verify_by_OTP_async.fulfilled,
      (state, { payload }) => {
        state.email_verify_by_otp_status = asyncStatus.SUCCEEDED;
        state.email_verify_by_otp_data = payload.user;
        // state.userAuth = true;
        state.user.email_verified_at = payload?.user?.email_verified_at;
        success_toast_message(payload.message);
      }
    );

    builder.addCase(email_verify_by_OTP_async.rejected, (state, action) => {
      state.email_verify_by_otp_status = asyncStatus.ERROR;
      state.email_verify_by_otp_error = action.error;
      error_toast_message(action.error.message);
    });

    //  RESEND_OTP_VERIFY =========>>>>>>>>>>>
    builder.addCase(resend_OTP_async.pending, (state, action) => {
      state.resend_otp_status = asyncStatus.LOADING;
    });

    builder.addCase(resend_OTP_async.fulfilled, (state, { payload }) => {
      state.resend_otp_status = asyncStatus.SUCCEEDED;
      state.resend_otp_data = payload.user;
      state.user = payload.user;
      success_toast_message(payload.message);
    });

    builder.addCase(resend_OTP_async.rejected, (state, action) => {
      state.resend_otp_status = asyncStatus.ERROR;
      state.resend_otp_error = action.error;
      error_toast_message(action.error.message);
    });

    //  VERIFICATION_SUCCESS_ASYNC_VERIFY =========>>>>>>>>>>>
    builder.addCase(verificationSuccessAsync.pending, (state, action) => {
      state.verification_success_status = asyncStatus.LOADING;
    });

    builder.addCase(
      verificationSuccessAsync.fulfilled,
      (state, { payload }) => {
        state.verification_success_status = asyncStatus.SUCCEEDED;
        state.verification_success_data = payload.user;
        state.user = payload.user;
        success_toast_message(payload.message);
      }
    );

    builder.addCase(verificationSuccessAsync.rejected, (state, action) => {
      state.verification_success_status = asyncStatus.ERROR;
      state.verification_success_error = action.error;
      error_toast_message(action.error.message);
    });

    //  VERIFICATION_SUCCESS_ASYNC_VERIFY =========>>>>>>>>>>>
    builder.addCase(getVendorStatusAsync.fulfilled, (state, { payload }) => {
      state.user.stripe_account_status = payload?.user?.stripe_account_status;
      state.user.stripe_details = payload?.user?.stripe_details;
      state.user.stripe_bank = payload?.user?.stripe_bank;
      console.log("payload", payload);
    });
  },
});

export const {
  setUserRole,
  setIdleStatus,
  setpageValue,
  setIdleRegisterStatus,
  setAuthState,
  setIdleStatusForgot,
  setIdleStatusOTP,
  setIdleStatusnemPassword,
  setUserProfileUpdateStatus,
  setVendorStripeStatus,
  setIdleLoginStatus,
  setIdleVendorRegisterStatus,
} = user_auth_slice.actions;

export default user_auth_slice.reducer;
