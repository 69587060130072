import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {  getChatUsersListAsync } from "../../services/chatService";

const initialState = {
  //   =============>>>>>>>  get all chats
  get_all_chats_status: asyncStatus.IDLE,
  get_all_chats_data: null,
  get_all_chats_error: null,
};

const chat_manage_slice = createSlice({
  name: "chatManage",
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    //  Get Services By Id =========>>>>>>>>>>>
    builder.addCase(getChatUsersListAsync.pending, (state, action) => {
      state.get_all_chats_status = asyncStatus.LOADING;
    });

    builder.addCase(getChatUsersListAsync.fulfilled, (state, { payload }) => {
      state.get_all_chats_status = asyncStatus.SUCCEEDED;
      state.get_all_chats_data = payload.users;
      state.get_all_chats_error = null;
    });

    builder.addCase(getChatUsersListAsync.rejected, (state, action) => {
      state.get_all_chats_status = asyncStatus.ERROR;
      state.get_all_chats_error = action.error;
      error_toast_message(action.error.message);
    });
  },
});

export const {} = chat_manage_slice.actions;

export default chat_manage_slice.reducer;
