import React, { useEffect, useState } from "react";
import { themeOrange } from "../../utils/colorTheme";
import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";
import { Divider, Stack } from "@mui/material";
import "./index.css";
import { FaCalendarDays } from "react-icons/fa6";
import { FiPlus } from "react-icons/fi";
import IconInput from "../IconInput/IconInput";
import { useDispatch, useSelector } from "react-redux";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { service_booking_keys } from "../../store/constants";
import { ErrorMessage, Form, Formik } from "formik";
import ButtonComp from "../common/ButtonComp";
import { getValidationSchema } from "../../pages/auth/ValidationsFormikYup/ValidationsSchema";

const FirstStep = () => {
  const dispatch = useDispatch();
  // Set initial values from localStorage or fallback to empty strings
  const initialValues = {
    date: localStorage.getItem(service_booking_keys.DATE) || "",
    time: localStorage.getItem(service_booking_keys.TIME) || "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { get_service_by_id_data } = useSelector(
    (state) => state.serviceManage
  );

  const _handleNext = (values) => {
    console.log("values", values);
    dispatch(
      setJobsSteps({
        value: "2",
      })
    );
    // Store form data into localStorage
    localStorage.setItem(service_booking_keys.DATE, values.date.trim());
    localStorage.setItem(service_booking_keys.TIME, values.time.trim());
  };

  return (
    <div>
      <Stack sx={{ overflow: "hidden" }}>
        <Stack className="service-name">
          {get_service_by_id_data?.service?.name}
        </Stack>
        <Stack
          className="overflow_stepper"
          sx={{
            overflow: "auto",
          }}
        >
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            className="stepperClass"
            sx={{
              minWidth: "260px",
            }}
          >
            <Stepper
              color={themeOrange}
              fontSize="12px"
              fontColor="black"
              steps={[
                { label: "SCHEDULE" },
                { label: "JOB DETAILS" },
                { label: "CONFIRM" },
              ]}
              currentStep={1}
            />
          </Stack>
        </Stack>

        <Stack>
          <Divider sx={{ marginTop: "50px" }} />
          <Stack mt={3} className="service-sub-name">
            When should we send someone?
          </Stack>
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema("second_step")}
            onSubmit={_handleNext}
          >
            {({ errors, touched, setFieldValue, values, handleSubmit }) => (
              <Form>
                <Stack gap={3}>
                  <Stack gap={1}>
                    <Stack mt={1} className="availible-date">
                      Select available date and times
                    </Stack>
                    <Stack sx={{ width: "100%" }}>
                      <IconInput
                        id={"date"}
                        // disabled={true}
                        placeholder="Add Date"
                        first_icon={
                          <FaCalendarDays size={25} color={themeOrange} />
                        }
                        // second_icon={<FiPlus size={32} color={themeOrange} />}
                        style={{
                          width: "100%",
                          padding: "14px",
                          paddingLeft: "60px",
                          borderRadius: "10px",
                          margin: "0px",
                          borderColor:
                            touched.date && errors.date ? "red" : themeOrange,
                        }}
                        onChange={(e) => {
                          setFieldValue("date", e.target.value);
                        }}
                        value={values.date}
                        defaultValue={values.date}
                        // type={"date"}
                      />
                      <ErrorMessage
                        name="date"
                        component="div"
                        className="error-message"
                      />
                    </Stack>
                  </Stack>
                  <Stack gap={1}>
                    <Stack className="Timing-constraints">
                      Timing constraints
                    </Stack>
                    <Stack>
                      <textarea
                        defaultValue={values.time}
                        id={"time"}
                        rows="4"
                        cols="50"
                        placeholder="e.g.Baby is napping from 3-4pm. Please do not arrive during those times."
                        value={values.time}
                        onChange={(e) =>
                          setFieldValue("time", e.target.value.trim())
                        }
                        style={{
                          borderColor:
                            touched.time && errors.time ? "red" : themeOrange,
                          borderRadius: "10px",
                          padding: "10px",
                          resize: "none",
                        }}
                      ></textarea>
                      <ErrorMessage
                        name="time"
                        component="div"
                        className="error-message"
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Divider sx={{ marginTop: "50px" }} />

                <ButtonComp
                  onClick={handleSubmit}
                  type="submit"
                  label={"Next"}
                  style={{
                    width: "40%",
                    borderRadius: "15px",
                    background:
                      "linear-gradient(180deg, #F15A24 0%, #C53F10 100%)",
                    margin: "20px 0px",
                  }}
                />
              </Form>
            )}
          </Formik>
        </Stack>
      </Stack>
    </div>
  );
};

export default FirstStep;

{
  /* <Stack className="next-btn" onClick={_nextHandle} my={2}>
          Next
        </Stack> */
}

{
  /* <Stack
            onClick={data.time && data.date ? _nextHandle : () => {}}
            className={`next-btn ${data.time && data.date && "next_btn_hov"}`}
            sx={{
              backgroundColor: data.time && data.date ? themeOrange : "grey",
              mb: 2,
            }}
          >
            Next
          </Stack> */
}
