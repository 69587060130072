import React from "react";
import QRCode from "react-qr-code";

const CustomQRCode = ({ QR_link, QR_size = 200 }) => {
  return (
    <div
      style={{ height: "auto", margin: "0 auto", maxWidth: QR_size, width: "100%" }}
    >
      <QRCode
        size={QR_size}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={QR_link}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};

export default CustomQRCode;
