import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { themeOrange } from "../../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidCalendar } from "react-icons/bi";
import { inputDateFormate } from "../../../utils/CustomFormator";
import { MdLocationOn, MdWatchLater } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import Input from "../../common/Input";
import { asyncStatus } from "../../../utils/asyncStatus";
import { useNavigate } from "react-router-dom";
import { PostReviewAsync } from "../../../services/services";
import { imgUrl } from "../../../utils/imageUrl";

const CompletedJobsTab = ({ jobLoader }) => {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobReview, setjobReview] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { get_all_jobs_data, post_review_status } = useSelector(
    (state) => state.serviceManage
  );

  const postReviewLoader = post_review_status === asyncStatus.LOADING;
  const completedJobs = get_all_jobs_data?.filter(
    (job) => job.status === "Completed"
  );

  const handleViewDetails = (jobId) => {
    setSelectedJobId((prevId) => (prevId === jobId ? null : jobId));
  };

  const ratingChanged = (newRating) => {
    setjobReview({ ...jobReview, rating: newRating });
  };

  const handlePostReview = (elm) => {
    let obj = {
      msg: jobReview.msg,
      rating: jobReview.rating,
      oid: elm,
    };
    dispatch(PostReviewAsync(obj));

    setjobReview({
      msg: "",
      rating: "",
    });
  };

  //   if (jobLoader) {
  //     return (
  //       <Stack
  //         alignItems="center"
  //         justifyContent="center"
  //         sx={{ height: "60vh" }}
  //       >
  //         <CircularProgress size={30} sx={{ color: themeOrange }} />
  //       </Stack>
  //     );
  //   }

  if (!completedJobs?.length) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "60vh" }}
      >
        <Typography>No Completed Jobs Found</Typography>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "15px",
        height: "200px",
      }}
      gap={2}
    >
      {completedJobs?.map((e, i) => {
        const isSelected = selectedJobId === e?.id; // Assume `e.id` is the unique identifier for the job
        if (e?.status === "Completed") {
          return (
            <Stack
              key={e?.id}
              sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "15px",
              }}
            >
              <Stack
                className="globleGradientBlue"
                sx={{
                  borderRadius: "15px",
                  py: 1.5,
                  px: 2,
                  boxShadow: "none",
                }}
              >
                <Typography
                  className="secondSubHeading"
                  sx={{ color: "white" }}
                >
                  {e?.service?.name || ""}
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ px: 2, py: 2 }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  gap={2}
                  sx={{ width: "100%" }}
                >
                  <Stack
                    pb={1.5}
                    gap={1}
                    sx={{
                      borderRadius: "10px",
                      width: "150px",
                      backgroundColor: "#F5F5F5",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                  >
                    <Stack sx={{ height: "70px", width: "100%" }}>
                      <img
                        src={`${imgUrl}${e?.service?.mobile_image}`}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Stack>
                    <Typography
                      className="mainPara"
                      sx={{
                        color: "#333333",
                        mx: 1,
                        textAlign: "center",
                      }}
                    >
                      {e?.service?.name || ""}
                    </Typography>
                  </Stack>

                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                    flexWrap={{
                      xl: "nowrap",
                      lg: "nowrap",
                      md: "wrap",
                      sm: "wrap",
                      xs: "wrap",
                    }}
                  >
                    <Stack gap={0.5}>
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        gap={1}
                      >
                        <BiSolidCalendar
                          style={{
                            fontSize: "24px",
                            color: "#F15A24",
                            flexShrink: 0,
                          }}
                        />
                        <Typography
                          className="subpara"
                          sx={{ color: "#333333" }}
                        >
                          {inputDateFormate(e?.date || "")}
                        </Typography>
                      </Stack>

                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        gap={1}
                      >
                        <MdWatchLater
                          style={{
                            fontSize: "24px",
                            color: "#F15A24",
                            flexShrink: 0,
                          }}
                        />
                        <Typography
                          className="subpara"
                          sx={{ color: "#333333" }}
                        >
                          {e?.time?.length > 30
                            ? `${e?.time?.substring(0, 30)}...`
                            : e?.time}
                        </Typography>
                      </Stack>

                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        gap={1}
                      >
                        <MdLocationOn
                          style={{
                            fontSize: "24px",
                            color: "#F15A24",
                            flexShrink: 0,
                          }}
                        />
                        <Typography
                          className="subpara"
                          sx={{ color: "#333333" }}
                        >
                          {e?.location}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: "#F15A24",
                        color: "#F15A24",
                        borderRadius: "15px",
                        px: 3,
                        py: 1,
                        backgroundColor: "white",
                        ":hover": {
                          backgroundColor: "white",
                          borderColor: "#F15A24",
                        },
                        textTransform: "none",
                        mt: 1,
                      }}
                      onClick={() => handleViewDetails(e?.id)}
                    >
                      {isSelected ? "Hide Detail" : "View Detail"}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>

              {isSelected && (
                <Stack
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    px: 1,
                  }}
                >
                  {/* Job Description */}
                  <Stack gap={1} px={2} py={2}>
                    <Typography
                      className="mainHeading"
                      sx={{ color: "#333333", fontSize: "20px" }}
                    >
                      Job Description
                    </Typography>
                    <Stack
                      sx={{
                        borderRadius: "15px",
                        border: "1px solid #F1F1F1",
                      }}
                      px={2}
                      py={2}
                    >
                      <Typography
                        className="mainPara"
                        sx={{ color: "#868181" }}
                      >
                        {e?.description || ""}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* Timing Constraints >>>>>> */}
                  <Stack gap={1} px={2} py={2}>
                    <Typography
                      className="mainHeading"
                      sx={{ color: "#333333", fontSize: "20px" }}
                    >
                      Timing Constraints
                    </Typography>
                    <Stack
                      sx={{
                        borderRadius: "15px",
                        border: "1px solid #F1F1F1",
                      }}
                      px={2}
                      py={2}
                    >
                      <Typography
                        className="mainPara"
                        sx={{
                          color: "#868181",
                          textAlign: "start",
                        }}
                      >
                        {e?.time || ""}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* Vendor Details >>>>>>>> */}
                  {e?.vendor?.first_name && (
                    <Stack gap={1} px={2} py={2}>
                      <Typography
                        className="mainHeading"
                        sx={{ color: "#333333", fontSize: "20px" }}
                      >
                        Vendor Detail
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          borderRadius: "15px",
                          border: "1px solid #F1F1F1",
                        }}
                        px={2}
                        py={2}
                      >
                        <Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography
                              className="mainPara"
                              sx={{
                                color: "black",
                                fontWeight: "600 !important",
                                fontSize: "16px !important",
                              }}
                            >
                              Name :
                            </Typography>
                            <Typography
                              className="mainPara"
                              sx={{ color: "#868181" }}
                            >
                              {`${e?.vendor?.first_name || ""} ${
                                e?.vendor?.last_name || ""
                              }`}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography
                              className="mainPara"
                              sx={{
                                color: "black",
                                fontWeight: "600 !important",
                                fontSize: "16px !important",
                              }}
                            >
                              Email :
                            </Typography>
                            <Typography
                              // className="mainPara"
                              sx={{ color: "#868181" }}
                            >
                              {`${e?.vendor?.email || ""} `}
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* <Button
                  variant="contained"
                  startIcon={
                    <HiMiniChatBubbleLeftRight
                      size={17}
                      sx={{ color: "white" }}
                    />
                  }
                  sx={{
                    borderColor: "#F15A24",
                    borderRadius: "15px",
                    px: 3,
                    py: 1,
                    color: "white",
                    backgroundColor: "#F15A24",
                    ":hover": {
                      backgroundColor: "#F15A24",
                      borderColor: "#F15A24",
                    },
                    mx: 2,
                    my: 2,
                  }}
                  onClick={() =>
                    _handleVanderChat(
                      e?.vendor?.id || "",
                      e?.vendor?.first_name || "",
                      e?.vendor?.last_name || "",
                      e?.vendor?.avatar || ""
                    )
                  }
                >
                  Chat Now
                </Button> */}
                      </Stack>
                    </Stack>
                  )}

                  {/* Reviews */}
                  <Stack gap={1} px={2} py={2}>
                    <Typography
                      className="mainHeading"
                      sx={{ color: "#333333" }}
                    >
                      Post Review
                    </Typography>

                    <Stack alignItems={"center"}>
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={40}
                        value={jobReview?.rating}
                        // fontSize={200}
                        activeColor="#ffd700"
                      />
                    </Stack>
                    <Stack p={2}>
                      <Input
                        onChange={(e) =>
                          setjobReview({
                            ...jobReview,
                            msg: e?.target?.value,
                          })
                        }
                        value={jobReview?.msg}
                        label="Write a review"
                      />
                      <Button
                        variant="contained"
                        sx={{
                          borderColor: "#F15A24",
                          borderRadius: "15px",
                          px: 3,
                          py: 1,
                          color: "white",
                          background:
                            "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                          ":hover": {
                            background:
                              "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                            borderColor: "#F15A24",
                          },
                          my: 2,
                        }}
                        onClick={() => handlePostReview(e?.id)}
                      >
                        {postReviewLoader ? (
                          <CircularProgress size={24} sx={{ color: "white" }} />
                        ) : (
                          "Post"
                        )}
                      </Button>
                    </Stack>
                  </Stack>

                  {/* Multiple Images */}
                  {/* <Stack
      gap={1}
      className="jobs_images_scrollbar_wrapper"
      direction={"row"}
      alignItems={"center"}
      sx={{
        mx: 2,
      }}
    >
      {e.images.map((image, i) => (
        <img
          key={i}
          src={`${imgUrl}${image.image}`}
          style={{
            height: "105px",
            width: "105px",
            borderRadius: "15px",
          }}
        />
      ))}
    </Stack> */}

                  {/* <Button
        variant="contained"
        sx={{
          borderColor: "#F15A24",
          borderRadius: "15px",
          px: 3,
          py: 1,
          color: "white",
          backgroundColor: "#F15A24",
          ":hover": {
            backgroundColor: "#F15A24",
            borderColor: "#F15A24",
          },
          mx: 2,
          my: 2,
        }}
        onClick={() => handleCancel(e.id)}
      >
        CANCEL JOB
      </Button> */}
                </Stack>
              )}
            </Stack>
          );
        }
        return null;
      })}
    </Stack>
  );
};

export default CompletedJobsTab;
