import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {
  cancelSubscriptionAsync,
  getVendorStatusAsync,
  subscriptionAsync,
  vendorPaymentSetupAsync,
  vendorPersonalDetailAsync,
} from "../../services/SubscriptionService";

const initialState = {
  //   =============>>>>>>>  subscription
  subscription_status: asyncStatus.IDLE,
  subscription_data: null,
  subscription_error: null,

  //   =============>>>>>>>  subscription
  cancel_subscription_status: asyncStatus.IDLE,
  cancel_subscription_data: null,
  cancel_subscription_error: null,

  //   =============>>>>>>>  vendor_personal_detail
  vendor_personal_detail_status: asyncStatus.IDLE,
  vendor_personal_detail_data: null,
  vendor_personal_detail_error: null,

  //   =============>>>>>>>  vendor_payment_setup
  vendor_payment_setup_status: asyncStatus.IDLE,
  vendor_payment_setup_data: null,
  vendor_payment_setup_error: null,

  //   =============>>>>>>>  get_vendor_status
  get_vendor_status_status: asyncStatus.IDLE,
  get_vendor_status_data: null,
  get_vendor_status_error: null,

  account_id: null,
};

const subscriptionSlice = createSlice({
  name: "subscriptionSlice",
  initialState,

  reducers: {
    //   =============>>>>>>>  subscription
    setSubscriptionSatus: (state) => {
      state.subscription_status = asyncStatus.IDLE;
    },
    //   =============>>>>>>>  subscription
    setCancelSubscriptionSatus: (state) => {
      state.cancel_subscription_status = asyncStatus.IDLE;
    },
    //   =============>>>>>>>  subscription
    setVendorPaymentSetupSatus: (state) => {
      state.vendor_payment_setup_status = asyncStatus.IDLE;
    },
    //   =============>>>>>>>  subscription
    setVendorPersonalDetailSatus: (state) => {
      state.vendor_personal_detail_status = asyncStatus.IDLE;
    },
    //   =============>>>>>>>  GetVendorStausSatus
    setGetVendorStausSatus: (state) => {
      state.get_vendor_status_status = asyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    //  Subscription  =========>>>>>>>>>>>
    builder.addCase(subscriptionAsync.pending, (state, action) => {
      state.subscription_status = asyncStatus.LOADING;
    });

    builder.addCase(subscriptionAsync.fulfilled, (state, { payload }) => {
      state.subscription_status = asyncStatus.SUCCEEDED;
      state.subscription_data = payload.subscription;
      success_toast_message(payload.message);
    });

    builder.addCase(subscriptionAsync.rejected, (state, action) => {
      state.subscription_status = asyncStatus.ERROR;
      state.subscription_error = action.error;
      error_toast_message(action.error.message);
    });

    // Cancel Subscription  =========>>>>>>>>>>>
    builder.addCase(cancelSubscriptionAsync.pending, (state, action) => {
      state.cancel_subscription_status = asyncStatus.LOADING;
    });

    builder.addCase(cancelSubscriptionAsync.fulfilled, (state, { payload }) => {
      state.cancel_subscription_status = asyncStatus.SUCCEEDED;
      state.cancel_subscription_data = payload.users;
      success_toast_message(payload.message);
    });

    builder.addCase(cancelSubscriptionAsync.rejected, (state, action) => {
      state.cancel_subscription_status = asyncStatus.ERROR;
      state.cancel_subscription_error = action.error;
      error_toast_message(action.error.message);
    });

    // VENDOR PERSONAL DETAIL STATUS  =========>>>>>>>>>>>
    builder.addCase(vendorPersonalDetailAsync.pending, (state, action) => {
      state.vendor_personal_detail_status = asyncStatus.LOADING;
    });

    builder.addCase(
      vendorPersonalDetailAsync.fulfilled,
      (state, { payload }) => {
        state.vendor_personal_detail_status = asyncStatus.SUCCEEDED;
        state.vendor_personal_detail_data = payload.users;
        state.account_id = payload?.account_id;
        success_toast_message(payload.message);
      }
    );

    builder.addCase(vendorPersonalDetailAsync.rejected, (state, action) => {
      state.vendor_personal_detail_status = asyncStatus.ERROR;
      state.vendor_personal_detail_error = action.error;
      error_toast_message(action.error.message);
    });

    // VENDOR PAYMENT SETUP STATUS  =========>>>>>>>>>>>
    builder.addCase(vendorPaymentSetupAsync.pending, (state, action) => {
      state.vendor_payment_setup_status = asyncStatus.LOADING;
    });

    builder.addCase(vendorPaymentSetupAsync.fulfilled, (state, { payload }) => {
      state.vendor_payment_setup_status = asyncStatus.SUCCEEDED;
      state.vendor_payment_setup_data = payload.users;
      success_toast_message(payload.message);
    });

    builder.addCase(vendorPaymentSetupAsync.rejected, (state, action) => {
      state.vendor_payment_setup_status = asyncStatus.ERROR;
      state.vendor_payment_setup_error = action.error;
      error_toast_message(action.error.message);
    });

    // GET VENDOR STATUS   =========>>>>>>>>>>>
    builder.addCase(getVendorStatusAsync.pending, (state, action) => {
      state.get_vendor_status_status = asyncStatus.LOADING;
    });

    builder.addCase(getVendorStatusAsync.fulfilled, (state, { payload }) => {
      state.get_vendor_status_status = asyncStatus.SUCCEEDED;
      state.get_vendor_status_data = payload;
      // success_toast_message(payload.message);
    });

    builder.addCase(getVendorStatusAsync.rejected, (state, action) => {
      state.get_vendor_status_status = asyncStatus.ERROR;
      state.get_vendor_status_error = action.error;
      error_toast_message(action.error.message);
    });
  },
});

export const {
  setSubscriptionSatus,
  setCancelSubscriptionSatus,
  setVendorPaymentSetupSatus,
  setVendorPersonalDetailSatus,
  setGetVendorStausSatus,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
