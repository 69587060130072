import React, { useEffect, useState } from "react";
import css from "./VendorCheckEmail.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "../../../../assets/logo.png";
import { themeOrange } from "../../../../utils/colorTheme";
import Input from "../../../../component/common/Input";
import ButtonComp from "../../../../component/common/ButtonComp";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { TbReload } from "react-icons/tb";
import { EMAIL_CONSTANTS } from "../../../../store/constants";
import {
  email_verify_by_OTP_async,
  resend_OTP_async,
} from "../../../../services/authentication";
import { setIdleStatusForgot } from "../../../../store/slices/user_auth_slice";
import { Formik, Form } from "formik";
import { getValidationSchema } from "../../ValidationsFormikYup/ValidationsSchema";
import OTPInput from "react-otp-input";
import useWindowDimensions from "../../../../utils/hooks/windowDimensions";

const VendorCheckEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { email_verify_by_otp_status, user, resend_otp_status } = useSelector(
    (state) => state.userAuth
  );

  const isLoading = email_verify_by_otp_status === asyncStatus.LOADING;
  const isResendOTPLoading = resend_otp_status === asyncStatus.LOADING;

  useEffect(() => {
    if (email_verify_by_otp_status === asyncStatus.SUCCEEDED) {
      if (user && user?.email_verified_at && user?.verify_email === 0) {
        navigate("/vendor-email-verified");
      } else if (user && user?.verify_email === 1) {
        user?.user_role === 2 ? navigate("/dashboard/") : navigate("/");
      }
      dispatch(setIdleStatusForgot());
    }
  }, [email_verify_by_otp_status]);

  const _handleSubmit = (values) => {
    const obj = {
      verification_code: values.email_code,
      mobile_code: values.mobile_code,
    };
    dispatch(email_verify_by_OTP_async(obj));
  };

  const _handleResendOTP = () => {
    dispatch(resend_OTP_async());
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Container maxWidth={"xl"}>
            <Stack mt={2}>
              <Stack
                justifyContent={"flex-start"}
                sx={{
                  width: "100%",
                  padding: { xl: 5, lg: 5, md: 5, sm: 5, xs: 0 },
                }}
                textAlign={"left"}
                color={"black"}
              >
                <Stack
                  spacing={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack gap={1}>
                    <Stack className="auth_Heading">
                      <Typography variant="caption" className={css.first_txt}>
                        Check Your{" "}
                        <Typography
                          variant="caption"
                          className={`grediant_txt ${css.lst_txt}`}
                        >
                          Email
                        </Typography>
                      </Typography>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <Typography variant="caption" className={css.description}>
                        We have sent an OTP to{" "}
                        <Typography
                          variant="caption"
                          className={css.description}
                          sx={{ fontWeight: "700 !important" }}
                        >
                          {user?.email || ""}{" "}
                        </Typography>
                        and{" "}
                        <Typography
                          variant="caption"
                          className={css.description}
                          sx={{ fontWeight: "700 !important" }}
                        >
                          {user?.phone || ""}
                        </Typography>
                        . Please enter the{" "}
                        <Typography
                          variant="caption"
                          className={css.description}
                          sx={{ fontWeight: "700 !important" }}
                        >
                          verification codes
                        </Typography>{" "}
                        below to continue with your account.
                      </Typography>
                    </Stack>
                  </Stack>

                  <Formik
                    initialValues={{ email_code: "", mobile_code: "" }}
                    validationSchema={getValidationSchema("vendor_check_email")}
                    onSubmit={_handleSubmit}
                  >
                    {({ errors, touched, setFieldValue, values }) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Stack gap={3}>
                              <Grid
                                container
                                alignItems={"center"}
                                rowSpacing={3}
                                columnSpacing={2}
                                justifyContent={{
                                  xl: "space-between",
                                  lg: "center",
                                  md: "center",
                                  sm: "center",
                                  xs: "center",
                                }}
                              >
                                <Grid
                                  item
                                  xl={5}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Stack alignItems={"center"} gap={1}>
                                    <Typography
                                      variant="caption"
                                      className={css.otp_label}
                                    >
                                      For Email Verification
                                    </Typography>
                                    <OTPInput
                                      value={values.email_code}
                                      onChange={(e) =>
                                        setFieldValue("email_code", e)
                                      }
                                      numInputs={4}
                                      inputType="number"
                                      renderInput={(props) => (
                                        <input {...props} />
                                      )}
                                      containerStyle={{ gap: "20px" }}
                                      inputStyle={{
                                        backgroundColor: "white",
                                        height: width > 600 ? "70px" : "60px",
                                        width: width > 600 ? "50px" : "40px",
                                        borderRadius: "9px",
                                        border: `1.053px solid ${
                                          touched.email_code &&
                                          errors.email_code
                                            ? "#d32f2f"
                                            : "#E4E4E4"
                                        }`,
                                        boxShadow:
                                          "0px 10.534px 31.601px 0px rgba(178, 178, 178, 0.20)",
                                        color: "black",
                                        fontSize: "25px",
                                      }}
                                    />
                                    {touched.email_code &&
                                      errors.email_code && (
                                        <Typography
                                          sx={{
                                            color: "#d32f2f",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {errors.email_code}
                                        </Typography>
                                      )}
                                  </Stack>
                                </Grid>
                                <Grid
                                  item
                                  xl={0.5}
                                  lg={0.5}
                                  md={0.5}
                                  sm={1}
                                  xs={1}
                                >
                                  <Box
                                    sx={{
                                      height: "2px",
                                      width: "100%",
                                      backgroundColor: themeOrange,
                                    }}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xl={5}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Stack alignItems={"center"} gap={1}>
                                    <Typography
                                      variant="caption"
                                      className={css.otp_label}
                                    >
                                      For Phone Number Verification
                                    </Typography>
                                    <OTPInput
                                      value={values.mobile_code}
                                      onChange={(e) =>
                                        setFieldValue("mobile_code", e)
                                      }
                                      numInputs={4}
                                      inputType="number"
                                      renderInput={(props) => (
                                        <input {...props} />
                                      )}
                                      containerStyle={{ gap: "20px" }}
                                      inputStyle={{
                                        backgroundColor: "white",
                                        height: width > 600 ? "70px" : "60px",
                                        width: width > 600 ? "50px" : "40px",
                                        borderRadius: "9px",
                                        border: `1.053px solid ${
                                          touched.mobile_code &&
                                          errors.mobile_code
                                            ? "#d32f2f"
                                            : "#E4E4E4"
                                        }`,
                                        boxShadow:
                                          "0px 10.534px 31.601px 0px rgba(178, 178, 178, 0.20)",
                                        color: "black",
                                        fontSize: "25px",
                                      }}
                                    />
                                    {touched.mobile_code &&
                                      errors.mobile_code && (
                                        <Typography
                                          sx={{
                                            color: "#d32f2f",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {errors.mobile_code}
                                        </Typography>
                                      )}
                                  </Stack>
                                </Grid>
                              </Grid>

                              <Typography
                                className={css.description}
                                sx={{ my: 2 }}
                              >
                                Didn't receive the email? Check your spam filter
                                or try to resend.
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>

                        <Stack spacing={2}>
                          <ButtonComp
                            type="submit"
                            label={
                              isLoading ? (
                                <CircularProgress
                                  size={22}
                                  sx={{ color: "white" }}
                                />
                              ) : (
                                "Continue"
                              )
                            }
                            style={{
                              borderRadius: "10px",
                              fontfamily: "Raleway !important",
                              background:
                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                            }}
                          />

                          <ButtonComp
                            onClick={_handleResendOTP}
                            label={
                              isResendOTPLoading ? (
                                <CircularProgress
                                  size={22}
                                  sx={{ color: themeOrange }}
                                />
                              ) : (
                                <>
                                  <TbReload
                                    style={{
                                      fontSize: "18px",
                                      color: themeOrange,
                                    }}
                                  />{" "}
                                  &nbsp; Resend
                                </>
                              )
                            }
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "white",
                              color: themeOrange,
                              fontfamily: "Raleway !important",
                            }}
                          />
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Grid>

        <Grid
          item
          md={6}
          lg={6}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            className="bgImageSignup"
            width={1}
          >
            <Stack width={"60%"} className="transparent-bg" p={4} gap={5}>
              <Stack>
                <img width={"100px"} src={Logo} />
              </Stack>
              <Stack>
                <Typography
                  textAlign={"start"}
                  className="subHeading"
                  color={"white"}
                >
                  Welcome Done Right Away
                </Typography>
                <Typography className="mainPara" color={"white"}>
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default VendorCheckEmail;
