import React, { useEffect, useRef, useState } from "react";
import { Avatar, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import css from "./DashboardProfileMenu.module.css"; // Import your custom CSS for styling if needed
import { useNavigate } from "react-router-dom";
import { success_toast_message } from "../../../utils/toast_message";
import { useSelector } from "react-redux";
import { imgUrl } from "../../../utils/imageUrl";
import CustomModal from "../CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import ButtonComp from "../ButtonComp";
import { TbNotification } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";

const DashboardProfileMenu = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const menuRef = useRef(null); // Reference to the menu

  const _handleToggle = () => {
    setOpen(!open);
  };
  const { user, get_user_profile_data } = useSelector(
    (state) => state.userAuth
  );

  const _handleClose = () => {
    setOpen(false);
    navigate("/");
    success_toast_message("Logout Successfully");
    window.location.reload();
    localStorage.removeItem("auth");
    localStorage.clear();
  };

  // Close menu when clicking outside of it
  useEffect(() => {
    const _handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", _handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
    };
  }, []);

  return (
    <div className={css.custom_menu} ref={menuRef}>
      {/* <Tooltip
        title="Account settings"
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      > */}
      <Stack
        sx={{ marginTop: "5px" }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={3}
      >
        {/* <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            width: 43,
            height: 43,
            borderRadius: "15px",
            border: "1px solid #F1F1F1",
          }}
        >
          <TbNotification
            style={{
              fontSize: "30px",
              color: "#646464",
              flexShrink: 0,
            }}
          />
        </Stack> */}

        <Stack
          onClick={_handleToggle}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            height: 43,
            borderRadius: "15px",
            border: "1px solid #F1F1F1",
            overflow: "hidden",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#f3f3f3",
            },
          }}
          gap={1}
        >
          <Avatar
            alt="Remy Sharp"
            src={`${imgUrl}${user?.avatar || ""}`}
            sx={{ width: "43px", height: "100%", borderRadius: "15px" }}
          />

          <Typography
            sx={{
              color: "#000",
              fontSize: "16px",
              fontWeight: "600",
            }}
            className={css.user_name}
          >
            {user?.first_name || ""}
          </Typography>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ width: "43px", height: "100%" }}
          >
            <IoIosArrowForward
              style={{ fontSize: "20px", color: "#000", flexShrink: 0 }}
            />
          </Stack>
        </Stack>
      </Stack>
      {/* </Tooltip> */}

      {open && (
        <div className={css.menu_items_wrapper}>
          <button
            className={css.menu_item}
            onClick={() => {
              navigate("/dashboard/settings");
              _handleToggle();
            }}
          >
            Settings
          </button>
          <button
            className={css.menu_item}
            onClick={() => setOpenModal(!openModal)}
          >
            Sign Out
          </button>
        </div>
      )}

      {/* >>>>>>>>> Questions Modal */}
      <CustomModal
        isOpen={openModal}
        setIsOpen={() => setOpenModal(!openModal)}
      >
        <Stack
          sx={{
            overflow: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
          alignItems={"center"}
          p={1}
        >
          <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
            <IconButton onClick={() => setOpenModal(!openModal)}>
              <RxCross2 size={20} sx={{ color: "black" }} />
            </IconButton>
          </Stack>

          <Stack gap={2} px={1} sx={{ width: "100%" }}>
            <Typography
              sx={{
                // textAlign: "",
                color: "black",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              Are you sure you want to logout?
            </Typography>

            <Stack
              gap={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <ButtonComp
                onClick={_handleClose}
                label={"Yes"}
                style={{ width: "auto", height: "auto", borderRadius: "15px" }}
              />
              <ButtonComp
                onClick={() => setOpenModal(!openModal)}
                label={"No"}
                style={{ width: "auto", height: "auto", borderRadius: "15px" }}
              />
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>
    </div>
  );
};

export default DashboardProfileMenu;
