import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandle } from "../config/apiHandle/apiHandle";
import { type_constant } from "../utils/asyncStatus";

export const login_service_auth = createAsyncThunk(
  type_constant.LOGIN,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/auth/login`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const signup_service_auth = createAsyncThunk(
  type_constant.SIGNUP,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/auth/register`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const check_auth = createAsyncThunk(
  type_constant.CHECK_AUTH,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/auth/check-token`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const forgot_password = createAsyncThunk(
  type_constant.FORGOT_PASSWORD,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/forgot-password`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const otp_verify = createAsyncThunk(
  type_constant.OTP_VERIFY,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/otp-verification`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const email_verify_by_OTP_async = createAsyncThunk(
  type_constant.EMAIL_VERIFY_BY_OTP,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/verify-email`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR EMAIL VERIFY BY OTP", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const resend_OTP_async = createAsyncThunk(
  type_constant.RESEND_OTP_ASYNC,
  async () => {
    try {
      const response = await apiHandle.get(`/resend-code`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR RESEND BY OTP", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const reset_password = createAsyncThunk(
  type_constant.RESET_PASSWORD,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/reset-password`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);


export const edit_profile = createAsyncThunk(
  type_constant.EDIT_PROFILE,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/profile-update`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const signup_vendor_auth = createAsyncThunk(
  type_constant.SIGNUP_VENDOR,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/vendor-signup`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const get_user_profile = createAsyncThunk(
  type_constant.USER_PROFILE,
  async () => {
    try {
      const response = await apiHandle.get(`/my-profile`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const get_signup_services = createAsyncThunk(
  type_constant.SIGNUP_SERVICES_GET,
  async (post_data) => {
    const { services, city, states } = post_data;
    try {
      const response = await apiHandle.get(
        `/get-general-data?services=${services}&cities=${city}&states=${states}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_vendor = createAsyncThunk(
  type_constant.GET_VENDOR,
  async (post_data) => {
    try {
      const response = await apiHandle.get(`/get-vendor-rating/${post_data}`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const contactUsAsync = createAsyncThunk(
  type_constant.CONTACT_US_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/contact-form`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR FROM CONTACT PAGE", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const vendorStripeStartedAsync = createAsyncThunk(
  type_constant.VENDOR_STRIPE_STARTED_ASYNC,
  async () => {
    try {
      const response = await apiHandle.get(`/create-connected-account`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR FROM VENDOR STRIPE STARTED ASYNC", error);
      if (error?.response?.data) {
        throw Error(error?.response?.data?.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const verificationSuccessAsync = createAsyncThunk(
  type_constant.VERIFICATION_SUCCESS_SSYNC,
  async () => {
    try {
      const response = await apiHandle.get(`/set-true-email`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR FROM VENDOR STRIPE STARTED ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
