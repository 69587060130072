import React, { useEffect } from "react";
import css from "./ComingSoonApp.module.css";
import { Stack } from "@mui/material";
import Footer from "../Footer/Footer";
import ButtonComp from "../../component/common/ButtonComp";
import { useLocation, useNavigate } from "react-router-dom";

const ComingSoonApp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const _handleGoBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(-1); // Go back to the previous page
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <div style={{backgroundColor:"white !important",width:"100%"}}>
        <Navbar />
      </div> */}
      <div className={css.img_wrapper}>
        {/* <Stack
          sx={{ backgroundColor: "#362d2db3", height: "100%", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            className={css.main_heading}
            sx={{
              fontSize: {
                xl: "72px",
                lg: "72px",
                md: "72px",
                sm: "42px",
                xs: "42px",
              },
              textAlign: "center",
            }}
          >
            Coming Soon
          </Typography>
        </Stack> */}
        <Stack mb={10} alignItems={"center"} justifyContent={"center"}>
          <ButtonComp
            label="Go Back"
            onClick={_handleGoBack}
            style={{ padding: "10px 50px", borderRadius: "10px" }}
          />
        </Stack>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ComingSoonApp;
