import css from "./MemberShip.module.css";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import logo_2 from "../../assets/logo_2.png";
import dollar from "../../assets/dollar.png";
import jack from "../../assets/jack.png";
import calender_board from "../../assets/calender_board.png";
import useWindowDimensions from "../../utils/hooks/windowDimensions";
import { useNavigate } from "react-router-dom";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { imgUrl } from "../../utils/imageUrl";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus } from "../../utils/asyncStatus";
import { get_services } from "../../services/services";
import { VscCalendar } from "react-icons/vsc";
import { themeOrange } from "../../utils/colorTheme";
import { FiTool } from "react-icons/fi";
import tools from "../../assets/tools.png";
import dollar_hand from "../../assets/dollar_hand.png";
import calendar from "../../assets/calendar.png";
import Footer from "../../screens/Footer/Footer";
import Navbar from "../../component/navbar/Navbar";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import powered_by_stripe from "../../assets/powered_by_stripe.png";
import { subscriptionAsync } from "../../services/SubscriptionService";
import {
  SERVICE_DETAIL_PAGE_CONSTANT,
  service_detail_page_constant,
} from "../../store/constants";
import { setSubscriptionSatus } from "../../store/slices/subscription_slice";

const PUBLISHABLE_KEY = loadStripe(
  `pk_test_51P6wjI07SeeusJqEPLXeW0I3dO9JtrB5Lf2T5CfWQAmwswz5XlvNX0JT80pV2xaAiqNYY4bNLfpRD1RvzVaG6wUL003g7Rt4No`
);

const MemberShip = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [fromWherePage, setFromWherePage] = useState({ from: "", id: "" });
  const stripe = useStripe();
  const elements = useElements();

  const [paymentDetail, setPaymentDetail] = useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
  });

  const formRef = useRef(null);
  const swiperRef = useRef(null);

  const { get_all_services_status, get_all_services_data, get_vendor_data } =
    useSelector((state) => state.serviceManage);
  const { subscription_status, cancel_subscription_status } = useSelector(
    (state) => state.subscriptionSlice
  );

  const subscriptionLoader = subscription_status === asyncStatus.LOADING;

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete); // Update state based on card completion
  };

  const _goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const _goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    dispatch(get_services());
  }, []);

  const _handleClick = (item) => {
    navigate(`/ServiceDetails/${item}`);
  };

  const _handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // CardElement se card details extract karna
    const cardElement = elements.getElement(CardElement);

    // Stripe ka token generate karna
    const { error, token } = await stripe.createToken(cardElement);

    console.log("data", {
      stripTokern: token.id,
    });

    if (error) {
      console.error("Error:", error);
    } else {
      const obj = { stripeToken: token.id };
      console.log("data", obj);
      dispatch(subscriptionAsync(obj));
    }
  };

  useEffect(() => {
    const serviceDetail = localStorage.getItem(
      SERVICE_DETAIL_PAGE_CONSTANT.from
    );
    const service_id = localStorage.getItem(
      SERVICE_DETAIL_PAGE_CONSTANT.service_id
    );
    setFromWherePage({ ...fromWherePage, id: service_id, from: serviceDetail });
  }, []);

  useEffect(() => {
    if (subscription_status === asyncStatus.SUCCEEDED) {
      if (fromWherePage.from === "FROM_SERVICE_DETAIL") {
        navigate(`/ServiceDetails/${fromWherePage.id}`);
        localStorage.removeItem(SERVICE_DETAIL_PAGE_CONSTANT.from);
        localStorage.removeItem(SERVICE_DETAIL_PAGE_CONSTANT.service_id);
      } else if (fromWherePage.from === "FROM_DASHBOARD") {
        navigate("/dashboard/");
      } else {
        navigate("/dashboard/subscriptions");
      }
      dispatch(setSubscriptionSatus());
    }
  }, [subscription_status]);

  const memberShipCardData = [
    {
      icon: dollar_hand,
      title: "Save $25 on Every Completed Job",
      description: `Enjoy exclusive savings as a member! Get $25 off every job we complete for you, whether it's a small repair or a full remodel. Start saving on each project and make your home improvements more affordable.`,
    },
    {
      icon: tools,
      title: "Extended Warranty from 30 to 60 Days",
      description: `As a member, enjoy peace of mind with an extended warranty on all work performed. We double our standard warranty from 30 to 60 days, ensuring that any issues are covered and giving you confidence in the quality of our services.`,
    },
    {
      icon: calendar,
      title: "Cancel Membership Anytime (No Fees or Penalties)",
      description: `Our membership is flexible and hassle-free. If you ever need to cancel, you can do so at any time with no cancellation fees or penalties. We’re committed to providing value on your terms.`,
    },
  ];

  const memberShipData = [
    {
      descripiton: (
        <>
          Save <span style={{ fontWeight: "900 !important" }}>$25</span> on
          every <span style={{ fontWeight: "900 !important" }}>Done</span> Job
        </>
      ),
    },
    {
      descripiton: (
        <>
          Warranty Extension from{" "}
          <span style={{ fontWeight: "900 !important" }}>30</span> to{" "}
          <span style={{ fontWeight: "900 !important" }}>60</span> days
        </>
      ),
    },
    {
      descripiton: <>Cancel membership anytime (no fee or panalty)</>,
    },
  ];

  return (
    <>
      <Navbar />
      <div className={css.img_wrapper}>
        <Stack
          sx={{ height: "100%", width: "100%", backgroundColor: "#362d2db3" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            className={css.main_heading}
            sx={{
              fontSize: {
                xl: "72px",
                lg: "72px",
                md: "72px",
                sm: "42px",
                xs: "42px",
              },
              textAlign: "center",
            }}
          >
            MemberShip
          </Typography>
        </Stack>
      </div>
      <Container maxWidth={"xl"} sx={{ backgroundColor: "white" }}>
        {/* WHY MEMBERSHIP -----!> */}
        <Stack mt={5}>
          <Typography className="mainHeading">Why Membership?</Typography>
        </Stack>

        <Grid container alignItems={"stretch"} spacing={3} mb={5} mt={1}>
          {memberShipCardData.map(({ title, icon, description }) => {
            return (
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Stack className={css.member_ship_card_wrapper}>
                  <img
                    src={icon}
                    className={css.member_ship_card_icon}
                    alt="member_ship_icon"
                  />
                  <Stack flex={1} gap={2} alignItems={"stretch"}>
                    <Typography className={css.member_ship_card_title}>
                      {title}
                    </Typography>
                    <Typography className={css.member_ship_card_description}>
                      {description}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            );
          })}
        </Grid>

        {/* MEMBERSHIP -----!> */}
        <Stack>
          <Typography className="mainHeading">Membership</Typography>
        </Stack>
        <Stack my={5} gap={3} sx={{ backgroundColor: "white" }}>
          <Grid container spacing={2} alignItems={"stretch"}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Stack
                justifyContent={"space-around"}
                gap={4}
                px={1}
                className={`globleGradientBlue ${css.member_ship}`}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-end"}
                  flexWrap={"nowrap"}
                >
                  <img
                    src={logo_2}
                    style={{
                      height:
                        width < 600 ? "40px" : width < 1120 ? "40px" : "62px",
                      width:
                        width < 600 ? "80px" : width < 1120 ? "80px" : "145px",
                      objectFit: "contain",
                    }}
                    alt="Logo"
                  />
                  <Typography className={css.member_txt}>Member</Typography>
                </Stack>

                <Typography
                  variant="caption"
                  className={css.member_ship_label_wh}
                >
                  Save ${" "}
                  <Typography
                    variant="caption"
                    className={css.member_ship_label_or}
                  >
                    25{" "}
                  </Typography>
                  <Typography
                    variant="caption"
                    className={css.member_ship_label_wh}
                  >
                    on every Done job For just $
                  </Typography>
                  <Typography
                    variant="caption"
                    className={css.member_ship_label_or}
                  >
                    10{" "}
                  </Typography>
                  / Month
                </Typography>

                <Typography variant="caption" className={css.ten_per_txt}>
                  $10/{" "}
                  <Typography variant="caption" className={css.mo_txt}>
                    mo
                  </Typography>
                </Typography>

                <Divider sx={{ bgcolor: "white" }} />

                <Stack gap={3}>
                  {memberShipData.map(({ descripiton }, i) => {
                    return (
                      <Stack
                        gap={1}
                        key={i}
                        direction={"row"}
                        alignItems={"center"}
                      >
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            border: "1px solid white",
                            padding: "3px",
                            borderRadius: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: themeOrange,
                              height: "5px",
                              width: "5px",
                              borderRadius: "100%",
                            }}
                          ></Box>
                        </Stack>
                        <Typography
                          variant="caption"
                          className={css.member_ship_descripiton}
                        >
                          {descripiton}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </Grid>

            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
              <Stack
                gap={4}
                px={2}
                className={css.member_ship_payment}
                justifyContent={"space-around"}
              >
                <Typography
                  variant="caption"
                  className={css.member_ship_payment_title}
                >
                  Payment Method
                </Typography>

                <Stack gap={2}>
                  <CardElement
                    onChange={handleCardChange}
                    className="stripeInput"
                    options={{
                      hidePostalCode: true,
                      // style: {
                      //   base: {
                      //     borderRadius: "10px",
                      //     padding: "8px 15px",
                      //     border: "0.5px solid #d4d4d4",
                      //     backgroundColor: "#f1f1f1",
                      //     color: "#bbb7b7",
                      //     fontFamily: '"Raleway", sans-serif',
                      //     fontSize: "16px",
                      //     fontWeight: "400",
                      //     lineHeight: "27px",
                      //     "::placeholder": {
                      //       color: "#bbb7b7",
                      //     },
                      //   },
                      //   invalid: {
                      //     color: "#fa755a",
                      //     iconColor: "#fa755a",
                      //   },
                      // },
                    }}
                  />
                  <Typography className={css.stripe_txt}>
                    We do not store your card details in our database. All
                    payments are securely processed through Stripe, a fully
                    secure and trusted platform.
                  </Typography>
                </Stack>

                <ButtonComp
                  disabled={!isCardComplete}
                  onClick={_handlePaymentSubmit}
                  label={
                    subscriptionLoader ? (
                      <CircularProgress size={22} sx={{ color: "white" }} />
                    ) : (
                      "Pay Now"
                    )
                  }
                  style={{
                    borderRadius: "10px",
                    background: !isCardComplete
                      ? "lightgrey"
                      : "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        {/* POPULAR SERVICES -----!> */}
        <Stack my={5}>
          <Stack
            alignItems={"center"}
            justifyContent={"space-between"}
            direction={"row"}
            mb={3}
          >
            <Typography className="mainHeading">All Services</Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={2}
            >
              {/* --------!> BACKWARD BUTTON */}
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 2px 0px grey",
                  cursor: "pointer",
                }}
                onClick={_goToPrevSlide}
              >
                <MdOutlineKeyboardArrowLeft
                  style={{ color: "black", fontSize: "25px" }}
                />
              </Stack>
              {/* --------!> FORWARD BUTTON */}
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 2px 0px grey",
                  cursor: "pointer",
                }}
                onClick={_goToNextSlide}
              >
                <MdOutlineKeyboardArrowRight
                  style={{ color: "black", fontSize: "25px" }}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "white",
              boxShadow: "0px 0px 2px 0px grey",
              px: 1,
              py: 1,
              overflow: "auto",
              mt: 1,
            }}
            gap={1}
            className="slider_wrapper"
          >
            {/* CAROUSEL SLIDER -----!> */}
            <Swiper
              ref={swiperRef}
              slidesPerView={
                width >= 1100
                  ? 5
                  : width < 1100 && width > 900
                  ? 3.8
                  : width < 900 && width > 530
                  ? 3
                  : width < 530 && width > 460
                  ? 2.5
                  : width < 460 && width > 350
                  ? 1.8
                  : width < 350
                  ? 1
                  : 5
              }
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              className="mySwiper"
            >
              {get_all_services_data?.services?.map(
                ({ mobile_image, name, id }) => {
                  return (
                    <SwiperSlide key={id}>
                      <Stack
                        onClick={() => _handleClick(id)}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                        sx={{
                          backgroundColor: "#F1F1F1",
                          borderRadius: "10px",
                          height: "130px",
                          width: "150px",
                          resize: "none",
                          flexShrink: "0",
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                        gap={1.5}
                        className="slider_item"
                      >
                        <Stack
                          sx={{
                            height: "70px",
                            width: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={`${imgUrl}${mobile_image || ""}`}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Stack>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "700",
                            color: "black",
                          }}
                        >
                          {name || ""}
                        </Typography>
                      </Stack>
                    </SwiperSlide>
                  );
                }
              )}
            </Swiper>
          </Stack>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

const MemberShipPage = () => (
  <Elements stripe={PUBLISHABLE_KEY}>
    <MemberShip />
  </Elements>
);

export default MemberShipPage;
