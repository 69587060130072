import React, { useEffect } from "react";
import {
  AppBar,
  Avatar,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import "./style.css";
import { themeOrange } from "../../utils/colorTheme";
import { CiSettings } from "react-icons/ci";
import { CiBellOn } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";
import { PiNotificationFill } from "react-icons/pi";
import { imgUrl } from "../../utils/imageUrl";
import { useNavigate } from "react-router-dom";
import { TbNotification } from "react-icons/tb";
import DashboardProfileMenu from "../common/DashboardProfileMenu/DashboardProfileMenu";

// import Avatar from '@mui/material/Avatar';

export const UserTopAppBar = ({ onClickHanlde }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { topbarHide } = useSelector((state) => state.layout_controler);
  const { user } = useSelector((state) => state.userAuth);

  return (
    <Stack zIndex={1001}>
      <AppBar
        elevation={0}
        className={`topBarStyle ${topbarHide && "hideTopBar"}`}
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: 80,
          boxShadow: "0px 3px 5px -5px rgba(0, 0, 0, 0.75)",
        }}
        position="static"
      >
        <Toolbar sx={{ px: 5 }}>
          <Stack
            className="topBarContent"
            alignItems="center"
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="space-between"
          >
            <IconButton
              onClick={onClickHanlde}
              size="large"
              edge="start"
              sx={{ color: "#0000008a" }}
              aria-label="menu"
            >
              <MenuIcon
                sx={{
                  display: {
                    xl: "none",
                    lg: "none",
                    md: "none",
                    sm: "flex",
                    xs: "flex",
                  },
                }}
              />
            </IconButton>

            <Container
              disableGutters
              maxWidth="xl"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <DashboardProfileMenu />
            </Container>
          </Stack>
        </Toolbar>
      </AppBar>
    </Stack>
  );
};
