import {
    applyMiddleware,
    combineReducers,
    compose,
    configureStore,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import user_auth_slice from "./slices/user_auth_slice";
import ui_control_slice from "./slices/ui_control_slice";
import layoutControler from "./slices/layoutControler";
import services_manage_slice from "./slices/services_slice";
import chat_manage_slice from "./slices/chat_slice";
import subscription_slice from "./slices/subscription_slice";



let reducers = combineReducers({
    userAuth: user_auth_slice,
    uiControle: ui_control_slice,
    layout_controler: layoutControler,
    serviceManage: services_manage_slice,
    chatManage: chat_manage_slice,
    subscriptionSlice: subscription_slice,
});
// const rootReducer = (state, action) => {
//   if (action.type === TYPE_LOGOUT_USER) {
//     state = undefined;
//   }
//   return reducers(state, action);
// };
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = configureStore(
    { reducer: reducers },
    composeEnhancers(applyMiddleware(thunk))
);
export default store;
