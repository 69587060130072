import React from "react";
import "./index.css";
import backgroundVideo from "../../assets/Videos/background.mp4";
import SearchBar from "../../component/common/icon-searchbar/IconSearchBar";
import Twitch from "../../assets/Logo/Twitch.png";
import Facebook from "../../assets/Logo/Facebook.png";
import Google from "../../assets/Logo/Google.png";
import YouTube from "../../assets/Logo/YouTube.png";
import Pinterest from "../../assets/Logo/Pinterest.png";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import CountUp from "react-countup";
import Marquee from "react-fast-marquee";
import { themeBlue } from "../../utils/colorTheme";
import { HiPlus } from "react-icons/hi";
import { FaPercent } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import ButtonComp from "../../component/common/ButtonComp";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  const countData = [
    {
      count: 350000,
      description: "Jobs Completed",
      endIcon: <HiPlus size={20} style={{ color: themeBlue }} />,
      animationStart: 100,
    },
    {
      count: 94,
      description: "Rated Their pro perfect",
      endIcon: <FaPercent size={20} style={{ color: themeBlue }} />,
      animationStart: 1,
    },
    {
      count: 4.8,
      description: "App store rating",
      endIcon: <FaStar size={20} style={{ color: themeBlue }} />,
      animationStart: 0,
    },
  ];
  return (
    <div>
      <div className="home-section">
        <video className="background-image" autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="content">
          <div className="hero-heading">
            <Typography
              fontWeight={"600"}
              sx={{
                fontSize: { xs: "28px", sm: "50px", md: "70px", lg: "100px" },
              }}
            >
              EFFORTLESS HOME CARE
            </Typography>
          </div>
          <div className="hero-sub-heading">
            <Typography
              sx={{
                fontSize: { xs: "18px", sm: "20px", md: "32px", lg: "32px" },
              }}
            >
              On-Demand Household Solutions
            </Typography>
          </div>
          <Box
            sx={{
              width: { xl: "40%", lg: "40%", md: "50%", sm: "70%", xs: "90%" },
              mt: 2,
            }}
          >
            <SearchBar />
          </Box>
        </div>
      </div>
      {/* bottom Section  */}
      {/* 
      <Container maxWidth="md" sx={{ pt: "70px" }}>
        <Grid container spacing={10}>
          {countData.map(
            ({ description, count, animationStart, endIcon }, i) => {
              const isRate = count === 4.8;
              return (
                <Grid key={i} item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <Stack alignItems={"center"} justifyContent={"center"}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {isRate ? (
                        <Typography className="botton-heading">
                          {count}
                        </Typography>
                      ) : (
                        <Typography className="botton-heading">
                          <CountUp
                            duration={4}
                            start={animationStart}
                            end={count}
                          />
                        </Typography>
                      )}
                      {endIcon}
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "17px",
                          xs: "17px",
                          sm: "17px",
                          md: "32px",
                          lg: "32px",
                        },
                        color: themeBlue,
                        fontWeight: "500",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      {description}
                    </Typography>
                  </Stack>
                </Grid>
              );
            }
          )}
        </Grid>

        <div className="bottom-discription-container">
          <div className="bottom-discription">
            Small Jobs around the house are good as done,with no shoppinng
            around.A Done Certified Pro who's already in your area will complete
            your job at transparent rates you can good about.
          </div>
        </div>

        <Stack my={5} alignItems={"center"} justifyContent={"center"}>
          <ButtonComp
            label="Book a Done"
            onClick={() => navigate("/services")}
            style={{
              padding: "10px 70px",
              borderRadius: "10px",
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
              background:
                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
            }}
          />
        </Stack>
      </Container> */}
    </div>
  );
};

export default HeroSection;
