import React, { useEffect } from "react";
import css from "./VendorVerified.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Divider, Stack, Typography } from "@mui/material";
import success_check from "../../../../assets/success_check.png";

const VendorVerified = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userAuth);
  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard/");
    }, 2000);
  }, []);
  return (
    <Container
      maxWidth={"100%"}
      sx={{ backgroundColor: "#FAFAFA", height: "100vh" }}
    >
      <Container maxWidth={"xl"} sx={{ backgroundColor: "#FAFAFA", py: 5 }}>
        <Typography variant="caption" className={css.become_pro}>
          Become a{" "}
          <Typography
            variant="caption"
            className={`grediant_txt ${css.done_pro}`}
          >
            Done Pro
          </Typography>
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "70vh", width: "70%", margin: "auto" }}
          gap={3}
        >
          <img src={success_check} className={css.check_icon} />
          <Typography className={css.check_txt_1}>
            Thank you for setting up your account! Your account is now under
            review and will be verified within the next 2 business days. Once
            the review is complete, you’ll receive full access to all features.
          </Typography>
          <Typography className={css.check_txt_2}>
            We appreciate your patience and look forward to serving you soon!
          </Typography>
        </Stack>
      </Container>
    </Container>
  );
};

export default VendorVerified;
