import React, { useEffect, useState } from "react";
import css from "./VendorEmailVerified.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "../../../../assets/logo.png";
import { themeOrange } from "../../../../utils/colorTheme";
import Input from "../../../../component/common/Input";
import { common_caption_strings } from "../../../../utils/language_controls/constant_strings";
import ButtonComp from "../../../../component/common/ButtonComp";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { TbReload } from "react-icons/tb";
import flower from "../../../../assets/flowers.png";
import { verificationSuccessAsync } from "../../../../services/authentication";

const VendorEmailVerified = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { verification_success_status, user } = useSelector(
    (state) => state.userAuth
  );

  const isLoading = verification_success_status === asyncStatus.LOADING;

  const _handleSubmit = () => {
    dispatch(verificationSuccessAsync());
  };

  useEffect(() => {
    if (verification_success_status === asyncStatus.SUCCEEDED) {
      if (+user.user_role === 3) {
        navigate("/");
      } else if (+user.user_role === 2) {
        navigate("/dashboard/");
      }
    }
  }, [verification_success_status]);

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Container maxWidth={"xl"}>
            <Stack mt={2}>
              <Stack
                justifyContent={"flex-start"}
                sx={{
                  width: "100%",
                  padding: 5,
                }}
                textAlign={"left"}
                color={"black"}
              >
                <Stack
                  spacing={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack spacing={1}>
                    <Stack className="auth_Heading">
                      <Typography variant="caption" className={css.first_txt}>
                        Email is{" "}
                        <Typography
                          variant="caption"
                          className={`grediant_txt ${css.lst_txt}`}
                        >
                          Verified
                        </Typography>
                      </Typography>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <Typography variant="caption" className={css.description}>
                        Your email has been successfully verified. You can now
                        proceed with the next steps.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {/* <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          my={3}
                        >
                          <img
                            src={flower}
                            style={{
                              objectFit: "contain",
                              height: "286px",
                              margin: "auto",
                            }}
                          />
                        </Stack> */}
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
                <Stack>
                  <ButtonComp
                    onClick={_handleSubmit}
                    label={
                      isLoading ? (
                        <CircularProgress size={22} sx={{ color: "white" }} />
                      ) : (
                        "Next"
                      )
                    }
                    style={{
                      borderRadius: "10px",
                      fontfamily: "Raleway !important",
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                    }}
                  />
                </Stack>
                {/* <Stack mt={1} direction={"row"} justifyContent={"center"}>
                  <Typography color={"#9F9F9F"}>
                    {common_caption_strings.dont_have_account}
                  </Typography>
                  <Typography
                    onClick={() => navigate("/Signup")}
                    sx={{ color: themeOrange, mx: "2px", cursor: "pointer" }}
                  >
                    {common_caption_strings.Create_one}
                  </Typography>
                </Stack> */}
              </Stack>
            </Stack>
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack alignItems={"center"} className="bgImageSignup" width={1}>
            <Stack
              mt={10}
              width={"70%"}
              className="transparent-bg"
              p={4}
              gap={5}
            >
              <Stack>
                <img width={"80px"} src={Logo} />
              </Stack>
              <Stack>
                <Typography
                  textAlign={"start"}
                  className="subHeading"
                  color={"white"}
                >
                  Welcome Done Right Away
                </Typography>
                <Typography className="mainPara" color={"white"}>
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
              {/* <Stack mt={20}>
            <Typography className="mainPara" color={"white"}>
              Already have an Account?
            </Typography>
            <Typography className="mainPara" color={themeOrange}>
              Submit
            </Typography>
          </Stack> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default VendorEmailVerified;
