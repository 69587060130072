import { Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import { themeBlue, themeOrange } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import authImage from "../../assets/auth_image.png";
import Logo from "../../assets/logo.png";
import "./auth.css";
import { common_caption_strings } from "../../utils/language_controls/constant_strings";
import { forgot_password } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { setIdleStatusForgot } from "../../store/slices/user_auth_slice";
import { getValidationSchema } from "./ValidationsFormikYup/ValidationsSchema";
import { Form, Formik } from "formik";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(true);

  const {
    user_login_status,
    forgot_password_status,
    forgot_password_data,
    forgot_password_error,
  } = useSelector((state) => state.userAuth);

  const isLoading = forgot_password_status === asyncStatus.LOADING;

  const _handleSubmit = (values) => {
    const { ...rest } = values;
    const payload = {
      ...rest,
    };
    localStorage.setItem("email", payload.email);
    dispatch(forgot_password(payload));
  };

  useEffect(() => {
    if (forgot_password_status === asyncStatus.SUCCEEDED) {
      navigate("/check-email");
      dispatch(setIdleStatusForgot());
    }
  }, [forgot_password_status]);

  const initialValues = {
    email: "",
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Container maxWidth={"xl"}>
            <Stack mt={2}>
              <Stack
                justifyContent={"flex-start"}
                sx={{
                  width: "100%",
                  padding: 5,
                }}
                textAlign={"left"}
                color={"black"}
              >
                <Stack
                  spacing={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack spacing={1}>
                    <Stack className="auth_Heading">
                      <span className="auth_Heading">
                        Forgot{" "}
                        <span style={{ color: themeOrange }}>Password?</span>
                      </span>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <span>
                        kindly provide the registered email to change the
                        password.
                      </span>
                    </Stack>
                  </Stack>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={getValidationSchema("forgot_password")}
                    onSubmit={_handleSubmit}
                  >
                    {({ errors, touched, setFieldValue, values }) => (
                      <Form>
                        <Stack gap={3} mb={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Input
                                id={"email"}
                                onChange={(e) =>
                                  setFieldValue("email", e.target.value.trim())
                                }
                                style={{ borderRadius: "6px" }}
                                placeholder={
                                  common_caption_strings.example_email
                                }
                                label="Enter Your email"
                                isError={touched.email && errors.email}
                              />
                              {touched.email && errors.email && (
                                <Typography
                                  sx={{ color: "#d32f2f", fontSize: "12px" }}
                                >
                                  {errors.email}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>

                          <ButtonComp
                            type="submit"
                            label={
                              isLoading ? (
                                <CircularProgress
                                  size={22}
                                  sx={{ color: "white" }}
                                />
                              ) : (
                                common_caption_strings.submit
                              )
                            }
                            style={{
                              borderRadius: "10px",
                              background:
                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                              boxShadow:
                                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
                            }}
                          />
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </Stack>
                <Stack></Stack>
                <Stack mt={1} direction={"row"} justifyContent={"center"}>
                  <Typography color={"#9F9F9F"}>
                    {common_caption_strings.dont_have_account}
                  </Typography>
                  <Typography
                    onClick={() => navigate("/Signup")}
                    sx={{ color: themeOrange, mx: "2px", cursor: "pointer" }}
                  >
                    {common_caption_strings.Create_one}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            className="bgImageSignup"
            width={1}
          >
            {/* <img src={authImage} style={{  width: '80%', }} /> */}
            <Stack width={"60%"} className="transparent-bg" p={4} gap={5}>
              <Stack>
                <img width={"100px"} src={Logo} />
              </Stack>
              <Stack>
                <Typography
                  textAlign={"start"}
                  className="subHeading"
                  color={"white"}
                >
                  Welcome Done Right Away
                </Typography>
                <Typography className="mainPara" color={"white"}>
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
              {/* <Stack mt={20}>
                <Typography className="mainPara" color={"white"}>
                  Already have an Account?
                </Typography>
                <Typography className="mainPara" color={themeOrange}>
                  Submit
                </Typography>
              </Stack> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
export default ForgotPassword;
