import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderData from "../../component/common/Slider/Slider";
import "./index.css";
import { Container, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const FourthSection = () => {
  const { get_all_services_data } = useSelector((state) => state.serviceManage);
  return (
    get_all_services_data?.reviews?.length > 0 && (
      <Container maxWidth="xl" sx={{ overflow: "hidden" }}>
        <Stack mt={6}>
          <div style={{ width: "88vw", margin: "auto" }}>
            <Typography
              className="forth-heading"
              sx={{
                fontSize: {
                  xl: "36px",
                  lg: "36px",
                  md: "36px",
                  sm: "25px",
                  xs: "25px",
                },
              }}
            >
              What our <span style={{ color: "#F15A24" }}>clients say</span>
            </Typography>
            <div className="forth-text-btn-container">
              <div className="forth-sub-heading">
                Read genuine reviews from our customers to make informed
                decisions. Our platform features honest feedback and ratings to
                help you choose the best services and vendors that meet your
                expectations.
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <SliderData />
          </div>
        </Stack>
      </Container>
    )
  );
};

export default FourthSection;
